import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import {Table, Button, Row, Col, Form, InputGroup, FormGroup, FormControl, Dropdown, Alert} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch, faTag, faTrashAlt, faTruck} from '@fortawesome/free-solid-svg-icons';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import InputMask from 'react-input-mask';
import swal from 'sweetalert';
import Empty from '../../assets/Images/empty-cart-checkout.svg';
import './index.scss';
import Selo from '../../components/Selo';
import Combo from '../../components/SeloCombo';

interface StateProps {
  user: User;
  param: Param;
  cart: Cart;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  update(cart: ProductType): void;
  add(cart: ProductType): void;
  setFrete(param: string): void;
  setCupom(param: string): void;
}

interface OwnProps {
  item: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class Checkout extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      email: '',
      senha: '',
      hasError: false,
      hasErrorTitle: '',
      hasErrorMsg: '',
      modalDrop: false,
      modalDropItem: null,

      freteOpcoes: [],
      simularFrete: false,
      freteIsLoading: false,
      freteCep: this.props.user && this.props.user.cep ? this.props.user.cep : this.props.param && this.props.param.cep ? this.props.param.cep : '',

      entregaCodigo: null,
      entregaPreco: null,
      entregaPrazo: null,
      entregaNome: null,

      cupomDesc: false,
      codigoDesc: this.props.cart.cupomSelected ? this.props.cart.cupomSelected : null,
      valorDesc: 0,
      descIsLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.cart.freteSelected && this.state.freteCep) {
      this.getShippingModes();
    }

    // if ( this.props.cart.cupomSelected ) {
    //   this.handleCupomDesc();
    // }

    // const cep = Diversos.getnums(((this.props.user) && (this.props.user.cep)) ? this.props.user.cep : (this.props.param && this.props.param.cep) ? this.props.param.cep : null);

    // if (Diversos.freteGratis(cep)) {
    //   swal("FRETE GRATIS", "O frete para região de Curitiba é gratuíto, aproveite :)", "success");
    // }
  }

  private getCartTotal() {
    const self = this;
    let total = 0.0;
    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private getCartAmount() {
    const self = this;
    let amount = 0;

    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      amount += 1 * self.props.cart.produtos[i].qty;
    }

    return amount;
  }

  private handleChange(event, product) {
    const diferenca = Number(event.target.value) - Number(product.qty);

    if (Number(event.target.value) > Number(product.qty)) {
      let qtdJaAdd = 0;

      if (this.props.cart && this.props.cart.produtos) {
        for (let i = 0; i < this.props.cart.produtos.length; i++) {
          if (this.props.cart.produtos[i].codigo === product.codigo) {
            qtdJaAdd += Number(this.props.cart.produtos[i].qty);
          }
        }
      }

      if (qtdJaAdd + diferenca > product.estoque) {
        // swal('Atenção', `Quantidade máxima em estoque ${this.state.produto.ESTOQUE} unidade(s)`, 'error');
        return false;
      }
    }

    this.props.update({
      ...this.props.item,
      qty: event.target.value,
    });

    this.props.update({
      ...product,
      qty: event.target.value,
    });

    this.getShippingModes();
  }

  private handleDropProduto(item: any) {
    const self = this;

    if (!item) return;

    self.setState({
      modalDropItem: item,
      modalDrop: true,
    });
  }

  private handleModalDropClose() {
    const self = this;
    self.setState({
      modalDrop: false,
      modalDropItem: null,
    });
  }

  private async getShippingModes() {
    const self = this;

    self.setState({
      freteIsLoading: true,
      entregaCodigo: null,
      entregaPreco: null,
      entregaPrazo: null,
      entregaNome: null,
    });

    let peso = 0.0;
    let produtosCarrinho: any = [];
    let produtos: any = [];

    for (let i = 0; i < self.props.cart.produtos.length; i++) {
      peso += self.props.cart.produtos[i].peso * self.props.cart.produtos[i].qty;

      produtos.push(self.props.cart.produtos[i].codigo);

      produtosCarrinho.push({
        codigo: self.props.cart.produtos[i].codigo,
        qtd: self.props.cart.produtos[i].qty,
      });
    }

    try {
      let param = {
        cep: self.state.freteCep,
        peso,
        valorTotal: self.getCartTotal(),
        produtosCarrinho,
        produtos,
      };

      const {data} = await self.api.post(`/shipping/modes`, param);

      if (data.status === false) {
        throw new Error('Não foi possível buscar opções de entrega.');
      } else {
        for (let i = 0; i < data.msg.length; i++) {
          if (data.msg[i].nome !== 'RETIRA EM LOJA') {
            if (data.msg[i].preco === 0.0) {
              // swal('FRETE GRÁTIS', 'O frete para região de Curitiba é gratuíto, aproveite :)', 'success');
              break;
            }
          }
        }

        self.setState({freteOpcoes: data.msg}, () => {
          if (self.props.cart.freteSelected) {
            self.handleChangeFreteModo(self.props.cart.freteSelected);
          }
        });
      }
    } catch (e) {
      console.error(e);

      self.setState({
        freteOpcoes: null,
        fretehasError: true,
        freteHasErrorMsg: e.message,
      });
    } finally {
      self.setState({freteIsLoading: false});
    }
  }

  private async handleSimularFrete() {
    const self = this;
    self.setState({
      simularFrete: true,
      freteIsLoading: true,
    });
    self.getShippingModes();
  }

  private handleChangeFreteModo(nome) {
    const self = this;

    if (nome) {
      for (var i = 0; i < self.state.freteOpcoes.length; i++) {
        if (self.state.freteOpcoes[i].nome === nome) {
          let prazo = self.state.freteOpcoes[i].prazoMax ? self.state.freteOpcoes[i].prazoMax : self.state.freteOpcoes[i].prazo;

          self.setState(
            {
              entregaCodigo: self.state.freteOpcoes[i].codigo,
              entregaNome: self.state.freteOpcoes[i].nome,
              entregaPreco: self.state.freteOpcoes[i].preco,
              entregaPrazo: prazo,
            },
            () => {
              self.props.setFrete(self.state.freteOpcoes[i].nome);
            },
          );

          break;
        }
      }
    }
  }

  async handleCupomDesc() {
    const self = this;

    return;

    let produtos: any = [];

    for (let i = 0; i < self.props.cart.produtos.length; i++) {
      produtos.push({
        codigo: self.props.cart.produtos[i].codigo,
        qtd: self.props.cart.produtos[i].qty,
      });
    }

    let param = {
      id: self.state.codigoDesc,
      cpf: self.props.user && self.props.user.status && self.props.user.cpf ? self.props.user.cpf : '',
      produtos,
    };

    self.setState({isLoadingCupom: true});

    try {
      const {data} = await self.api.post(`/cupom/${self.state.codigoDesc}/validate`, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        let desconto = 0.0;

        if (data.msg.PERCENTUAL > 0) desconto = (data.msg.PERCENTUAL / 100) * self.getCartTotal();
        else if (data.msg.VALOR > 0) desconto = data.msg.VALOR;

        desconto = Math.trunc(desconto * 100) / 100;

        self.setState(
          {
            cupomDesc: true,
            valorDesc: desconto,
          },
          () => {
            self.props.setCupom(self.state.codigoDesc);
          },
        );
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      if (e.message.indexOf('E_MISSING_DATABASE_ROW') > -1) {
        swal('Atenção', 'Cupom não é válido', 'error');
      } else {
        swal('Atenção', e.message, 'error');
      }
    } finally {
      self.setState({isLoadingCupom: false});
    }
  }

  render() {
    return (
      <>
        <Header />

        <section className="content-fluid checkout-container">
          <section className="checkout-display">
            <span className="checkout-title">
              <h1> Checkout </h1>
              <h2>
                {' '}
                <FontAwesomeIcon icon={faShoppingCart} /> Itens do carrinho ( {this.getCartAmount()} )
              </h2>
            </span>
            {this.props.cart.produtos.length <= 0 ? (
              <div className="empty-cart-checkout">
                <img src={Empty} alt="Carrinho Vazio" />
                <h4> Seu carrinho está vazio. </h4>
                <p> Navegue por nossa loja e encontre seus produtos favoritos! </p>
                <a href="/">
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-link" />
                  Continuar comprando
                </a>
              </div>
            ) : (
              <>
                {this.props.cart.produtos.map((row, index) => {
                  let tipoPromo = 0;
                  let preco1 = 0;
                  let preco2 = 0;
                  let preco3 = 0;
                  let preco4 = 0;
                  let preco5 = 0;
                  let precoFinal = 0;

                  if (this.props.item && this.props.item.promo) {
                    if (
                      this.props.item.promo.CDPRODU1 &&
                      !this.props.item.promo.CDPRODU2 &&
                      !this.props.item.promo.CDPRODU3 &&
                      !this.props.item.promo.CDPRODU4 &&
                      !this.props.item.promo.CDPRODU5
                    ) {
                      tipoPromo = 1;
                      preco1 =
                        this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
                      precoFinal = preco1 * this.props.item.promo.CDQTD1;
                    } else if (
                      this.props.item.promo.CDPRODU1 &&
                      this.props.item.promo.CDPRODU2 &&
                      !this.props.item.promo.CDPRODU3 &&
                      !this.props.item.promo.CDPRODU4 &&
                      !this.props.item.promo.CDPRODU5
                    ) {
                      tipoPromo = 2;
                      preco1 =
                        this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
                      precoFinal = preco1 * this.props.item.promo.CDQTD1;
                    } else if (
                      this.props.item.promo.CDPRODU1 &&
                      this.props.item.promo.CDPRODU2 &&
                      this.props.item.promo.CDPRODU3 &&
                      !this.props.item.promo.CDPRODU4 &&
                      !this.props.item.promo.CDPRODU5
                    ) {
                      tipoPromo = 3;
                      preco1 =
                        this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
                      precoFinal = preco1 * this.props.item.promo.CDQTD1;
                    } else if (
                      this.props.item.promo.CDPRODU1 &&
                      this.props.item.promo.CDPRODU2 &&
                      this.props.item.promo.CDPRODU3 &&
                      this.props.item.promo.CDPRODU4 &&
                      !this.props.item.promo.CDPRODU5
                    ) {
                      tipoPromo = 4;
                      preco1 =
                        this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
                      precoFinal = preco1 * this.props.item.promo.CDQTD1;
                    } else if (
                      this.props.item.promo.CDPRODU1 &&
                      this.props.item.promo.CDPRODU2 &&
                      this.props.item.promo.CDPRODU3 &&
                      this.props.item.promo.CDPRODU4 &&
                      this.props.item.promo.CDPRODU5
                    ) {
                      tipoPromo = 5;
                      preco1 =
                        this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
                      precoFinal = preco1 * this.props.item.promo.CDQTD1;
                    }
                  }

                  return (
                    <article className="compra-container" key={index}>
                      <div className="compra-img-container">
                        <Image src={row.foto} className="compra-img" />
                      </div>
                      <div className="compra-detalhes-container">
                        <span className="compra-detalhes">
                          <h5>
                            {' '}
                            <a href={`/${Diversos.toSeoUrl(row.nome)}`}> {row.nome} </a>{' '}
                            {row.promo && row.promo.CDPROMOCAO ? (
                              <>
                                <br />
                                <span className={'badge badge-secondary mt-2'}>Kit promocional</span>
                              </>
                            ) : (
                              <></>
                            )}
                          </h5>
                          <p> R$ {Diversos.number_format(row.price, 2, ',', '')} </p>
                        </span>
                        <span className="compra-qtde">
                          <input
                            type="number"
                            className="form-control text-center checkout-amount"
                            name="quantidade"
                            defaultValue={row.qty || ''}
                            value={row.qty || ''}
                            onChange={(event) => this.handleChange(event, row)}
                            min={row.promo && row.promo.CDPROMOCAO && row.qtdPromo && row.qtdPromo > 1 ? row.qtdPromo : 1}
                            max={row.estoque}
                            step={row.promo && row.promo.CDPROMOCAO && row.qtdPromo && row.qtdPromo > 1 ? row.qtdPromo : 1}
                            // onKeyDown={(event) => {
                            //   event.preventDefault();
                            //   event.stopPropagation();
                            // }}
                          />
                        </span>
                      </div>
                      <div className="compra-actions">
                        <span>
                          <h5> SUBTOTAL </h5>
                          <p> {Diversos.number_format(row.price * row.qty, 2, ',', '')} </p>
                        </span>
                        <Button variant="link">
                          <FontAwesomeIcon icon={faTrashAlt} className="cart-item-action color-modifier" title="Excluir item" onClick={this.handleDropProduto.bind(this, row)} />
                        </Button>
                      </div>
                    </article>
                  );
                })}
              </>
            )}
          </section>

          {!(this.props.cart.produtos.length <= 0) && (
            <div className="checkout-continue-container">
              <div className="checkout-continue-wrapper">
                <FormGroup className="checkout-inputs">
                  <Form.Label>
                    <FontAwesomeIcon icon={faTruck} style={{marginRight: '.5rem'}} />
                    Simule frete e prazo
                  </Form.Label>
                  <InputGroup>
                    <InputMask mask="99.999-999" value={this.state.freteCep} onChange={(event) => this.setState({freteCep: Diversos.getnums(event.target.value)})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="CEP de entrega" />}
                    </InputMask>
                    <Button
                      disabled={!this.state.freteCep || this.state.freteCep.length < 8}
                      onClick={this.handleSimularFrete.bind(this)}
                      style={{fontSize: 12.5, textTransform: 'uppercase', padding: '.25rem'}}
                    >
                      Calcular
                    </Button>
                  </InputGroup>
                  <a href="https://buscacepinter.correios.com.br/app/endereco/index.php?t" target="_blank" rel="noopener noreferrer">
                    {' '}
                    Não sei meu CEP{' '}
                  </a>
                </FormGroup>

                {this.state.freteIsLoading ? (
                  <span className="w-100 d-block text-center">
                    <FontAwesomeIcon icon={faCircleNotch} spin className="mx-auto my-3" />
                  </span>
                ) : this.state.freteOpcoes !== null && this.state.freteOpcoes.length > 0 ? (
                  <Dropdown className="checkout-shipping">
                    <Dropdown.Toggle id="shipping-dropdown">
                      {!this.state.entregaNome || !this.state.entregaPrazo ? (
                        <span>
                          <p> Selecione uma forma de entrega </p>
                        </span>
                      ) : (
                        <span>
                          <strong> {this.state.entregaNome} </strong>
                          <p>
                            {' '}
                            Prazo: {this.state.entregaPrazo || '00/00'} • Preço: R$ {Diversos.number_format(this.state.entregaPreco, 2, ',', '') || '00,00'}{' '}
                          </p>
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {this.state.freteOpcoes.map((row, index) => (
                        <Dropdown.Item as={Button} key={index} onSelect={this.handleChangeFreteModo.bind(this, row.nome)}>
                          <span>
                            <strong> {row.nome} </strong>
                            <p>
                              {' '}
                              Prazo: {row.prazo} {row.prazoMax ? ` até ${row.prazoMax}` : ''} • Preço: R$ {Diversos.number_format(row.preco, 2, ',', '')}{' '}
                            </p>
                          </span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  this.state.simularFrete && <p> Nenhuma opção encontrada </p>
                )}

                {/*<FormGroup className="checkout-inputs">*/}
                {/*  <Form.Label>*/}
                {/*    <FontAwesomeIcon icon={faTag} style={{marginRight: '.5rem'}}/>              */}
                {/*    Cupom de desconto*/}
                {/*  </Form.Label>*/}
                {/*  <InputGroup className="calc-frete">*/}
                {/*    <FormControl */}
                {/*      placeholder="Código de Cupom"*/}
                {/*      value={this.state.codigoDesc || ''}*/}
                {/*      onChange={(event) => this.setState({codigoDesc: event.target.value})}*/}
                {/*    />*/}
                {/*    <Button */}
                {/*      onClick={this.handleCupomDesc.bind(this)}*/}
                {/*      style={{fontSize: 12.5, textTransform: 'uppercase', padding: '.25rem'}}*/}
                {/*      disabled={this.state.descIsLoading}*/}
                {/*    >*/}
                {/*      {*/}
                {/*        this.state.descIsLoading ?*/}
                {/*          <i className={"fas fa-spin fa-spinner"}></i>*/}
                {/*        :*/}
                {/*          `Inserir`*/}
                {/*      }*/}
                {/*    </Button>*/}
                {/*  </InputGroup>*/}
                {/*</FormGroup>*/}

                {this.state.cupomDesc ? (
                  this.state.descIsLoading ? (
                    <span className="w-100 d-block text-center">
                      <FontAwesomeIcon icon={faCircleNotch} spin className="mx-auto my-3" />
                    </span>
                  ) : (
                    <span className="discount-badge">
                      <small> {this.state.codigoDesc} </small>
                      {/*<h4> { this.state.valorDesc } </h4>*/}
                    </span>
                  )
                ) : null}

                <span className="checkout-partials">
                  <div className={'w-100 alert alert-info'} style={{transform: 'scale(0.9)'}}>
                    <i className={'fas fa-exclamation-circle mr-2'}></i>
                    Caso tenha cupom de desconto, insira na próxima tela.
                  </div>
                </span>

                <span className="checkout-partials checkout-subtotal">
                  <h2> SUBTOTAL </h2>
                  <p> R$ {Diversos.number_format(this.getCartTotal(), 2, ',', '')} </p>
                </span>
                {this.state.simularFrete && this.state.freteOpcoes && (
                  <span className="checkout-partials">
                    <h2> FRETE </h2>
                    <p> R$ {Diversos.number_format(this.state.entregaPreco, 2, ',', '')} </p>
                  </span>
                )}
                {this.state.cupomDesc && (
                  <span className="checkout-partials">
                    <h2> DESCONTO </h2>
                    {this.state.valorDesc && this.state.valorDesc > 0 ? <p> - {`R$ ${Diversos.number_format(this.state.valorDesc, 2, ',', '')}`} </p> : <p>0.00</p>}
                  </span>
                )}
                <span className="checkout-partials checkout-total">
                  <h2> TOTAL </h2>
                  <p> R$ {Diversos.number_format(this.getCartTotal() + this.state.entregaPreco - this.state.valorDesc, 2, ',', '')} </p>
                </span>

                <a href={this.props.user && this.props.user.status === true ? `/checkout/entrega` : `/login?checkout=true`} className="btn-primary-action btn-checkout">
                  <FontAwesomeIcon icon={faShoppingCart} />
                  Finalizar compra
                </a>
                <a href="/" target="_self" className="btn-link-action d-block text-center mt-4">
                  <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-link" />
                  Continuar comprando
                </a>
              </div>
            </div>
          )}
        </section>

        <Footer />

        <Modal show={this.state.modalDrop} onHide={this.handleModalDropClose.bind(this)} dialogClassName="checkout-delete" className="px-0" centered={true}>
          {this.state.modalDrop && (
            <>
              <Modal.Header closeButton style={{borderBottom: 'none'}}>
                <Modal.Title> Deseja apagar o produto? </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="alert alert-warning text-left">Deseja remover o produto {this.state.modalDropItem.nome} do carrinho?</div>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" className="btn-link-action mr-auto" onClick={this.handleModalDropClose.bind(this)}>
                  {' '}
                  Cancelar{' '}
                </Button>
                <Button
                  variant="danger"
                  className="btn-primary-action"
                  onClick={() => {
                    this.props.drop(this.state.modalDropItem.rowid);
                    this.setState({modalDrop: false}, () => this.getShippingModes());
                  }}
                >
                  Remover
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
