/**
 * ACTIONS TYPES
 */
export enum ParamTypes {
  SET_PARAM = '@param/SET_PARAM',
}

/**
 * Data types
 */
export interface Param {
  mode: string;
  sidebarOpened: boolean;
  cartOpened: boolean;
  menuOpened: boolean;
  acceptCookies: boolean;
  favorites: Array<number>;
  newUser: boolean;
  faqTab: string;
  cep: string;
  firstPurchaseOpen: boolean;
}

/**
 * State types
 */
export interface ParamState {
  readonly data: Param
}