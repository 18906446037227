import React from 'react'
import './index.scss';

import Api from '../../services/api';
import {Param} from '../../store/ducks/param/types';
import {Diversos} from '../../services/diversos';

import { Form } from 'react-bootstrap';

import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import primeiraCompra from '../../assets/Images/primeira-compra.jpeg';


interface StateProps { 
  param?: Param;
}

type Props = StateProps;

class ModalPrimeiraCompra extends React.Component<Props> {
  
  state: any = null;
  api: any = null;
  
  constructor(props) {
    super(props);

		this.api = new Api();

    this.state = {
      nome: '',
      email: '',
      isLoading: false,

      hasError: false,
      hasErrorTitle: '',
      hasErrorMsg: '',
      hasSuccess: false,
      hasSuccessTitle: '',
      hasSuccessMsg: '',
    }
  }


  private async setMsg ( type: string, title: string, msg: string) {
		const self = this;
		let timeout = 5000;

		if ( type === "error" ) {
			
			self.setState({
				hasError: true,
				hasErrorTitle: title,
				hasErrorMsg: msg,
			}, () => {
				setTimeout(() => self.setState({ hasError: false }), timeout)
			});

		} else {

			self.setState({
				hasSuccess: true,
				hasSuccessTitle: title,
				hasSuccessMsg: msg,
			}, () => {
				setTimeout(() => self.setState({ hasSuccess: false }), timeout)
			});

		}
	}

  private async handleSubmit(event) {
    const self = this
    let param: any =  {};

    event.preventDefault();

    if ( ( !self.state.email ) || ( !self.state.nome ) ) {
      self.setMsg('error', 'Formulário Incompleto', 'Preencha todos os dados do formulário antes de enviar');
      return;
    }

    if ( !Diversos.validateEmail(self.state.email) ) {
      self.setMsg('error', 'E-mail Inválido', 'Por favor, digite um E-mail válido para continuar');
      return;
    }

    param.email = self.state.email;
    param.nome = self.state.nome;

    self.setState({ isLoading: true }, () => {
      setTimeout(() => self.setState({ isLoading: false }), 3000)
    });

    // try {
    //   const {data} = await self.api.post("/contact", param);

    //   if (!data.status) {
    //     throw new Error(data.msg);
    //   } else {
    //     self.setMsg( 'success', "Sucesso", `Mensagem enviada com sucesso.`);
    //   }

    // } catch (e) {
    //   console.error(e);
    //   self.setMsg( 'error', "Atenção", `Não foi possível enviar sua mensagem. ${e.message}`);
    // } finally {
    //   self.setState({ isLoading: false });
    // }
  }

  render () {
    return (
      <>

        {
					this.state.hasError &&
					<div className="alert-box error-box">
						<p className="login-error-title"> {` - ${this.state.hasErrorTitle} - `} </p>
						<p className="alert-msg"> {this.state.hasErrorMsg} </p>
					</div>
				}

				{
					this.state.hasSuccess &&
					<div className="alert-box success-box">
						<p className="login-success-title"> {` - ${this.state.hasSuccessTitle} - `} </p>
						<p className="alert-msg"> {this.state.hasSuccessMsg} </p>
					</div>
				}

        <Form method="post" action="#" onSubmit={this.handleSubmit.bind(this)} className="first-purchase-form">
          <p> Primeira Compra? </p>
          <p> Cadastre-se e ganhe 10% OFF! </p>
          <Form.Group>
            <Form.Label> Seu Nome </Form.Label>
            <Form.Control type="text" placeholder="Nome" onChange={(event) => this.setState({ nome: event.target.value })}/>
          </Form.Group>
          <Form.Group>
            <Form.Label> Seu Email </Form.Label>
            <Form.Control type="email" placeholder="E-mail" onChange={(event) => this.setState({ email: event.target.value })}/>
          </Form.Group>

          <button type="submit" className="btn-primary-action" style={{minWidth: 160}}>
            { this.state.isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "RECEBER CUPOM" }
          </button>
        </Form>
      </>
    );
  }
}

export default ModalPrimeiraCompra;

