import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PasswordReset from '../../components/PasswordReset';
import EmailEdit from '../../components/EmailEdit';
import InfoEdit from '../../components/InfoEdit';
import ShippingEdit from '../../components/ShippingEdit';

import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from "react-input-mask";
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {Spinner} from 'react-bootstrap'

import logoPreta from '../../assets/Images/domakoski-preta.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faArrowLeft, faArrowRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

// declare var window;


interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Cadastro extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      pswHidden: true,
      confHidden: true,

      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoadingNovo: false,
      novoNome: null,
      novoCpf: null,
      novoTelefone: null,
      novoEmail: null,
      novoSenha: null,
      novoConfSenha: null,
      novoCep: null,
      novoRua: null,
      novoNumero: null,
      novoBairro: null,
      novoCidade: null,
      novoEstado: null,
      novoComplemento: null,
      novoHasError: false,
      novoHasErrorTitle: null,
      novoHasErrorMsg: null,
      currentStep: 1,
      email:  '',
      username: '',
      password: '',
    }

    this.toggleShow = this.toggleShow.bind(this);

  }

  toggleShow(input: number) {
    if (input === 1) {
      this.setState({ pswHidden: !this.state.pswHidden });
      return;
    }

    this.setState({ confHidden: !this.state.confHidden });
  }

  stepStyling (step) {
    if (step === this.state.currentStep) {
      return 'step-active';
    }
    else if (step < this.state.currentStep) {
      return 'step-passed';
    }
    return 'step-inactive';
  }

  private async getAddressByCep () {
    const self = this;

    if ( !self.state.novoCep )
      return false;

    self.setState({isLoadingCep: true});

    let param = {cep : self.state.novoCep}

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState({
          novoRua: data.msg.logradouro,
          novoBairro: data.msg.bairro,
          novoCidade: data.msg.localidade,
          novoEstado: data.msg.uf
        });
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  private async handleSubmitNovo (pressedButton) {
    const self = this;

    if (pressedButton === 1) {
      this.setState({currentStep: this.state.currentStep - 1});
      return;
    }

    if (this.state.currentStep === 1) {

      if (( !self.state.novoEmail ) || ( !self.state.novoSenha ) || ( !self.state.novoConfSenha )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      if ( self.state.novoSenha !== self.state.novoConfSenha ) {
        self.setMsg( 'error', "Confirmação da senha", `A confirmação da senha não confere com a senha informada.`, 2);
        return;
      }

      this.setState({novoHasError: false, currentStep: 2});
      return;
    }

    if (this.state.currentStep === 2) {

      if (( !self.state.novoNome ) || ( !self.state.novoCpf) || ( !self.state.novoCelular )) {
        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      if ( !Diversos.validateCPF(self.state.novoCpf) ) {
        self.setMsg( 'error', "CPF inválido", `Necessário informar um CPF válido para continuar.`, 2);
      }

      this.setState({novoHasError: false, currentStep: 3});
      return;
    }

    if (this.state.currentStep === 3) {

      if (( !self.state.novoCep ) || ( !self.state.novoRua ) ||
        ( !self.state.novoNumero ) || ( !self.state.novoBairro ) ||
        ( !self.state.novoCidade ) || ( !self.state.novoEstado ) ) {

        self.setMsg( 'error', "Formulário incompleto", `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }
    }

    self.setState({ novoHasError: false, isLoadingNovo: true });

    let param = {
      email: self.state.novoEmail,
      senha: self.state.novoSenha,
      nome: self.state.novoNome,
      cpf: self.state.novoCpf,
      nascimento: self.state.novoNascimento,
      telefone: self.state.novoTelefone,
      celular: self.state.novoCelular,
      cep: self.state.novoCep,
      rua: self.state.novoRua,
      numero: self.state.novoNumero,
      bairro: self.state.novoBairro,
      cidade: self.state.novoCidade,
      estado: self.state.novoEstado,
      complemento: self.state.novoComplemento
    }

    try {
      const {data} = await self.api.post('/customer', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        setTimeout(() => {
          self.setState({
            redirect: '/',
          });
        }, 400)
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível criar conta. ${e.message}`, 2);
    } finally {
      self.setState({ isLoadingNovo: false });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type
   * @param title
   * @param msg
   * @param form
   */

  private async setMsg ( type: string, title: string, msg: string, form: number ) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      if ( form === 1 ) {
        self.setState({
          customerHasError: true,
          customerHasErrorTitle: title,
          customerHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasError: false }), timeout)
        });
      } else {
        self.setState({
          novoHasError: true,
          novoHasErrorTitle: title,
          novoHasErrorMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasError: false }), timeout)
        });
      }
    } else {
      if ( form === 1 ) {
        self.setState({
          customerHasSuccess: true,
          customerHasSuccessTitle: title,
          customerHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
        });
      } else {
        self.setState({
          novoHasSuccess: true,
          novoHasSuccessTitle: title,
          novoHasSuccessMsg: msg,
        }, () => {
          setTimeout(() => self.setState({ novoHasSuccess: false }), timeout)
        });
      }
    }
  }

  render () {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const smDevice = window.innerWidth <= 600;

    return (
        <>
        <Header tipo={2}/>

        <section className="edit-credentials" style={{minHeight: window.innerHeight - 60}}>
          <div className="edit-credentials" style={{paddingBottom: window.location.pathname === '/editar/endereco' ? '4rem' : ''}}>

            <article className="data-container">
              <h1>
              {
                this.props.param.newUser ?
                  `Conclua seu cadastro digitando seu CPF.`
                :
                  `Alterar
                  ${(window.location.pathname === '/reset-senha') ? " Senha" :
                  (window.location.pathname === '/editar/perfil') ? " E-mail" : (window.location.pathname === "/editar/pessoal") ? " dados pessoais"
                  : (window.location.pathname === "/editar/endereco") ? " dados de entrega" : null}`
              }
              </h1>

              <p> Todos os campos com * são obrigatórios </p>

              {
                (window.location.pathname === '/reset-senha') ?
                  <PasswordReset/>
                : (window.location.pathname === '/editar/perfil') ?
                  <EmailEdit/>
                : (window.location.pathname === "/editar/pessoal") ?
                  <InfoEdit/>
                : (window.location.pathname === "/editar/endereco") ?
                  <ShippingEdit/>
                : null
              }
            </article>

          </div>
        </section>

        <Footer/>
        </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cadastro);
