import React from 'react';
import './index.scss';

import DivaFlower from '../../assets/Images/divaflower.png';

const CantLoadProducts = (props) => (
  <section className="failed-loading-products">
    {/* <img src={DivaFlower} alt="Diva"/> */}
    <p> Ops! Não conseguimos encontrar nenhum produto :( </p>
    <p> Atualize a página e tente novamente. Se isso não resolver, volte mais tarde e estaremos te esperando com ofertas fresquinhas :) </p>
  </section>
);

export default CantLoadProducts;
