import React from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart} from '../../store/ducks/cart/types';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import queryString from 'query-string';
import {Diversos} from '../../services/diversos';
import Api from '../../services/api';

import Form from 'react-bootstrap/Form';

import FilterBanner from '../../assets/Images/filter-banner.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

declare var window;

const PRICE_RANGE = [
  {value: '0-49', label: 'Até R$49,00', index: 1},
  {value: '49-99', label: 'De R$49,00 a R$99,00', index: 2},
  {value: '99-149', label: 'De R$99,00 a R$149,00', index: 3},
  {value: '149-199', label: 'De R$149,00 a R$199,00', index: 4},
  {value: '199-249', label: 'R$ 199,00 a R$ 249,00', index: 5},
  {value: '250-' + Number.MAX_SAFE_INTEGER, label: 'Acima de R$ 249,00', index: 6},
];

interface StateProps {
  cart?: Cart;
  param: Param;
}

interface DispatchProps {
  setParam(data: Param): void;
}

interface OwnProps {
  item?: any;

  title: any;
  marca: any;
  marcas: any;
  ehPesquisa?: boolean;
  ehMarca?: boolean;
}

interface RouteParams {
  menu1?: string;
  menu2?: string;
  menu3?: string;
  menu3Selected?: any;
}

type Props = StateProps & DispatchProps & OwnProps & RouteParams;

class FilterSidebar extends React.Component<Props> {
  api: any = null;
  state: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    const {pag, marcas, preco} = queryString.parse(window.location.search);
    console.log(props.menu3);
    this.state = {
      marcas: props.marcas,
      showBrands: false,
      showPrices: false,
      showCategories: false,

      brandValues: !marcas ? [] : Array.isArray(marcas) ? marcas : [marcas],

      menu1: props.menu1,
      menu2: props.menu2,
      menu3: props.menu3,
      menu3Selected: props.menu3Selected,
      isLoadingMenu: false,
      isLoadingProdutos: true,
      produtos: [],
      page: pag ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: false,
      // produtosPerPage: 20,
      produtosTotal: 1,
      showModal: false,
      filtroMarcas: !marcas ? [] : Array.isArray(marcas) ? marcas : [marcas],
      filtroPrecos: !preco ? [] : preco.toString().split('-'),
      priceValue: preco,
    };
  }

  async componentDidMount() {
    const self = this;
  }

  private handleShowMore(item: string) {
    if (item === 'brands') {
      this.setState({showBrands: !this.state.showBrands});
      return;
    } else if (item === 'prices') {
      this.setState({showPrices: !this.state.showPrices});
      return;
    }

    this.setState({showCategories: !this.state.showCategories});
  }

  private handleFilterCleanse() {
    if (this.state.brandValues.length <= 0 && !this.state.priceValue) {
      return null;
    } else {
      this.setState(
        {
          brandValues: [],
          priceValue: null,
        },
        this._handleClickFilter.bind(this, null, null, null),
      );
    }
  }

  private async _handleClickFilter(menu1: any, menu2: any = null, menu3: any = null) {
    const self = this;

    let menu1Desc = menu1 && menu1.DESCRICAO ? menu1.DESCRICAO : '';
    let menu2Desc = menu2 && menu2.DESCRICAO ? menu2.DESCRICAO : '';
    let menu3Desc = menu3 && menu3.DESCRICAO ? menu3.DESCRICAO : '';

    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    const price = self.state.priceValue;
    const pag = self.state.pag;

    let newsParam = param;

    // if ( brands )
    newsParam = {...newsParam, marcas: brands};

    // if ( price )
    newsParam = {...newsParam, preco: price};

    if (pag <= 1 || !pag) newsParam = {...newsParam, pag: '1'};
    else if (pag > self.state.produtosLastPage) newsParam = {...newsParam, pag: self.state.produtosLastPage};
    else newsParam = {...newsParam, pag: pag.toString()};

    if (menu1Desc && menu2Desc && menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}/${Diversos.toSeoUrl(menu2Desc)}/${Diversos.toSeoUrl(menu3Desc)}`;
    else if (menu1Desc && menu2Desc && !menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}/${Diversos.toSeoUrl(menu2Desc)}`;
    else if (menu1Desc && !menu2Desc && !menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}`;
    // else if (self.props.ehPesquisa === true)
    // link = `/busca/${Diversos.toSeoUrl(menu1Desc)}`;

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  render() {
    const numberOfBrands = this.state.showBrands ? this.state.marcas.lenght : 5;
    const numberOfCategories = this.state.showCategories ? 10 : 5;

    return (
      <>
        <article className="filter-wrapper">
          <small> Resultados para </small>
          <h1> {this.props.title} </h1>
        </article>

        {!this.props.ehMarca && !this.props.ehPesquisa && (
          <article className="filter-wrapper">
            <h3>
              {' '}
              <FontAwesomeIcon icon={faArrowDown} />
              {this.props.ehPesquisa
                ? 'Departamentos'
                : this.props.title
                ? this.props.title
                : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3Selected
                ? this.state.menu2.DESCRICAO || ''
                : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3.length > 0
                ? this.state.menu2.DESCRICAO || ''
                : this.state.menu1.DESCRICAO || ''}
            </h3>
            <Form className="filter-check">
              {this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3Selected
                ? this.state.menu3
                    .slice(0, numberOfBrands)
                    .map((row, index) => (
                      <Form.Check
                        custom
                        className="checkbox-filter"
                        id={`filter-checkbox-menu-${index}`}
                        label={row.DESCRICAO}
                        checked={row.CDMENU === this.state.menu3Selected.CDMENU}
                        onClick={this._handleClickFilter.bind(this, this.state.menu1, this.state.menu2, row)}
                      />
                    ))
                : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3.length > 0
                ? this.state.menu3
                    .slice(0, numberOfCategories)
                    .map((row, index) => (
                      <Form.Check
                        custom
                        className="checkbox-filter"
                        id={`filter-checkbox-menu-${index}`}
                        label={row.DESCRICAO}
                        onClick={this._handleClickFilter.bind(this, this.state.menu1, this.state.menu2, row)}
                      />
                    ))
                : this.state.menu2 && this.state.menu2.length > 0
                ? this.state.menu2
                    .slice(0, numberOfCategories)
                    .map((row, index) => (
                      <Form.Check
                        custom
                        className="checkbox-filter"
                        id={`filter-checkbox-menu-${index}`}
                        label={row.DESCRICAO}
                        onClick={this._handleClickFilter.bind(this, this.state.menu1, row)}
                      />
                    ))
                : this.props.ehPesquisa
                ? this.state.menu1
                    .slice(0, numberOfCategories)
                    .map((row, index) => (
                      <Form.Check
                        custom
                        className="checkbox-filter"
                        id={`filter-checkbox-menu-${index}`}
                        label={row.DESCRICAO}
                        onClick={this._handleClickFilter.bind(this, row, null, null)}
                      />
                    ))
                : null}
            </Form>
            <button className="mt-3 btn-link-action" onClick={this.handleShowMore.bind(this, 'categories')}>
              {this.state.showCategories ? 'Ver menos...' : 'Ver mais...'}
            </button>
          </article>
        )}

        {/* {
          this.state.marcas.length > 0 &&
          <article className="filter-wrapper">
            <h3> <FontAwesomeIcon icon={faArrowDown}/> Marcas </h3>
              <Form className="filter-check">
              {
                this.state.marcas.slice(0, numberOfBrands).map((row, index) => (
                  <Form.Check 
                    custom 
                    className="checkbox-filter" 
                    id={`filter-checkbox-marca-${index}`} 
                    value={row.MARCA} 
                    label={row.MARCA} 
                    onChange={() => this.setState({brandValues: [...this.state.brandValues, row.MARCA]}, this._handleClickFilter.bind(this, null, null, null))}
                    checked={this.state.brandValues.includes(row.MARCA) || this.state.brandValues === row.MARCA}
                  />
                ))
              }
              </Form>
              {
                this.state.marcas.length > 5 &&    
                <button className="mt-3 btn-link-action" onClick={this.handleShowMore.bind(this, 'brands')}>
                { this.state.showBrands ? 'Ver menos...' :  'Ver mais...' }
                </button>
              }
          </article>
        } */}

        <article className="filter-wrapper">
          <h3>
            {' '}
            <FontAwesomeIcon icon={faArrowDown} /> Preço{' '}
          </h3>
          <Form className="filter-check">
            {PRICE_RANGE.map((row, index) => (
              <Form.Check
                custom
                className="checkbox-filter"
                id={`filter-checkbox-preco-${index}`}
                label={row.label}
                checked={this.state.priceValue === row.value}
                onClick={() => this.setState({priceValue: row.value}, this._handleClickFilter.bind(this, null, null, null))}
              />
            ))}
          </Form>
          <button className="mt-3 btn-link-action" onClick={this.handleShowMore.bind(this, 'prices')}>
            {this.state.showPrices ? 'Ver menos...' : 'Ver mais...'}
          </button>
        </article>

        <button
          style={{width: '100%', textAlign: 'left', marginRight: 'auto', border: 'none', backgroundColor: 'transparent', color: '#d9534f', fontSize: 14, fontWeight: 'bold'}}
          title="Resetar filtros"
          onClick={this.handleFilterCleanse.bind(this)}
        >
          <FontAwesomeIcon icon={faTimesCircle} style={{marginRight: '.5rem'}} />
          Limpar Filtros
        </button>

        {/* <div className="filter-img-container">
          <a href="/departamento" title="Ir para a página" >
            <img src={FilterBanner} alt="Banner promocional"/>
          </a>
        </div> */}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterSidebar);
