import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import {Cart, CartTypes, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import {Redirect, Link} from 'react-router-dom';
import queryString from 'query-string';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import UserSidebar from '../../components/UserSidebar';

import moment from 'moment';
import 'moment/locale/pt-br';

import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask';

import Row from 'react-bootstrap/Row';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {Spinner} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faShoppingCart, faEdit, faTimes, faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {findDOMNode} from 'react-dom';
import noFavs from '../../assets/Images/no-favs.png';

import produto from '../../assets/Images/product.png';

interface StateProps {
  user: User;
  cart?: Cart;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;

  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {
  item?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

// function Favorito (props) {

//   return (
//     <div className="dados-container fav-container">
//       <button title="Remover dos favoritos"> <FontAwesomeIcon icon={faTimes}/> </button>
//       <span className="fav-product">
//         <img src={props.src}/>
//         <span>
//           <p> { props.produto } </p>
//           <p> { props.valor } </p>
//         </span>
//       </span>
//       <Button title="Adicionar ao carrinho" className="btn-primary-action btn-buy-fav"> Comprar </Button>
//     </div>
//   );
// }

class MeuCadastro extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,
      pswHidden: true,
      confHidden: true,
      currentStep: 1,
      pagamento: true,

      produtos: [],

      // VARIAVEIS DE CONTROLE PARA FORM DE CADASTRO JA EXISTENTE
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,

      isLoadingCep: false,
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount() {
    const self = this;

    if (self.props.user && self.props.user.status !== false) {
      self.getCustomer();
    }

    self.getFavorites();
  }

  toggleShow(input: number) {
    if (input === 1) {
      this.setState({pswHidden: !this.state.pswHidden});
      return;
    }

    this.setState({confHidden: !this.state.confHidden});
  }

  private async getFavorites() {
    const self = this;
    const {marcas, preco} = queryString.parse(window.location.search);
    let filtros: any = {};

    if (marcas) filtros = {...filtros, marcas};

    if (preco) filtros = {...filtros, preco};

    filtros = {...filtros, per_page: self.state.produtosPerPage};

    self.setState({isLoadingProdutos: true});

    let param: any = {
      codigos: self.props.param.favorites,
    };

    try {
      const {data} = await self.api.post(`/product/favoritos/${self.state.page}?${queryString.stringify(filtros)}`, param);

      console.log(data);

      if (!data.status) {
        throw new Error(`Falha ao buscar produtos`);
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
      });
    } catch (e) {
      console.error(e);
      self.setState({produtos: []});
    } finally {
      self.setState({isLoadingProdutos: false});
    }
  }

  private async getCustomer() {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if (data.status == false) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }
    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message,
      });
    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleAddCart(index) {
    const self = this;
    const produto = this.state.produtos[index];
    let pic = '';

    let preco = produto.PRECO;
    let precoOriginal = produto.PRECO;

    if (produto.PREPRO > 0 && produto.PREPRO < produto.PRECO && moment(produto.INIPRO) <= moment() && moment(produto.FIMPRO) >= moment()) {
      preco = this.props.item.PREPRO;
    }

    if (produto.FOTOS && produto.FOTOS.length > 0) pic = `${process.env.REACT_APP_BASE_URL_PICTURE}/${produto.FOTOS[0].link}`;
    pic = `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`;

    self.props.add({
      codigo: self.state.produtos[index].CODIGO,
      nome: self.state.produtos[index].NOME,
      priceOrigin: precoOriginal,
      price: preco,
      qty: 1,
      foto: pic,
      estoque: produto.ESTOQUE,
      peso: produto.PESO,
    });
    self.props.setParam({...self.props.param, cartOpened: true});
  }

  clearFavs() {
    this.setState({produtos: []});
  }

  removeFav(index) {
    const self = this;
    var temp = self.state.produtos;

    if (index !== -1) {
      temp.splice(index, 1);
      this.setState({produtos: temp});
    }

    return this.state.produtos;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <Header />

        <section className="meu-cadastro-section">
          <Row className="mx-0 user-row">
            <Col lg={4} xl={3} className="user-meu-cadastro">
              <UserSidebar />
            </Col>

            <Col lg={8} xl={9} className="meu-cadastro-form">
              <span id="meu-perfil" />
              <h2> Meu Perfil </h2>
              {this.state.isLoadingCustomer === true ? (
                <>
                  <span className="py-3 w-100 h-100 m-auto text-center d-flex justify-content-center">
                    <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-center m-auto spinner" style={{fontSize: 24}} />
                  </span>
                </>
              ) : this.state.customerHasError ? (
                <>
                  <p> {this.state.customerHasErrorTitle} </p>
                  <p> {this.state.customerHasErrorMsg} </p>
                </>
              ) : (
                <>
                  <span className="title-action">
                    <h3> Dados da conta </h3>
                    <a title="Clique para editar" href="/editar/perfil">
                      {' '}
                      <FontAwesomeIcon icon={faEdit} />{' '}
                    </a>
                  </span>

                  <article className="dados-da-conta">
                    <div className="dados-container">
                      <p> E-mail: </p>
                      <p> {this.state.customer.email} </p>
                    </div>
                    <div className="dados-container">
                      <p> Senha: </p>
                      <p> ****** </p>
                    </div>
                  </article>

                  <span className="title-action">
                    <h3> Dados pessoais </h3>
                    <a title="Clique para editar" href="/editar/pessoal">
                      {' '}
                      <FontAwesomeIcon icon={faEdit} />{' '}
                    </a>
                  </span>

                  <article className="dados-da-conta">
                    <div className="dados-container">
                      <p> Nome: </p>
                      <p> {this.state.customer.nome.split(' ')[0]} </p>
                    </div>
                    <div className="dados-container">
                      <p> Sobrenome: </p>
                      <p> {this.state.customer.nome.split(' ')[1] || ''} </p>
                    </div>
                    <div className="dados-container">
                      <p> CPF: </p>
                      <p> {this.state.customer.cpf} </p>
                    </div>
                    <div className="dados-container">
                      <p> Data de Nascimento: </p>
                      <p> {this.state.customer.nascimento} </p>
                    </div>
                    {/* <div className="dados-container">
                    <p> Sexo: </p>
                    <p> { this.state.customer.sexo } </p>
                  </div> */}
                    <div className="dados-container">
                      <p> Celular: </p>
                      <p> {this.state.customer.celular} </p>
                    </div>
                    <div className="dados-container">
                      <p> Telefone: </p>
                      <p> {this.state.customer.telefone || '-'} </p>
                    </div>
                  </article>

                  <span id="reset-senha" />
                  <h3> Reset de Senha </h3>
                  <a className="btn-link-action" href="/reset-senha">
                    {' '}
                    Solicitar uma mudança de senha{' '}
                  </a>

                  {/* <h3> Formas de Pagamento </h3>
                {
                  (this.state.pagamento) ?
                  "A"
                  :
                  <span className="register-payment">
                    <h4 className="sub-heading">
                      Você ainda não cadastrou nenhuma forma de pagamento.
                    </h4>
                    <a> Cadastrar agora </a> 
                  </span>
                } */}

                  <span id="entrega" />
                  <span className="title-action">
                    <h3> Endereço de Entrega </h3>
                    <a title="Clique para editar" href="/editar/endereco">
                      {' '}
                      <FontAwesomeIcon icon={faEdit} />{' '}
                    </a>
                  </span>
                  {this.state.pagamento ? (
                    <article className="dados-da-conta">
                      <div className="dados-container">
                        <p> CEP: </p>
                        <p> {this.state.customer.cep} </p>
                      </div>
                      <div className="dados-container">
                        <p> Rua: </p>
                        <p> {this.state.customer.rua} </p>
                      </div>
                      <div className="dados-container">
                        <p> Número: </p>
                        <p> {this.state.customer.numero} </p>
                      </div>
                      <div className="dados-container">
                        <p> Bairro: </p>
                        <p> {this.state.customer.bairro} </p>
                      </div>
                      <div className="dados-container">
                        <p> Cidade: </p>
                        <p> {this.state.customer.cidade} </p>
                      </div>
                      <div className="dados-container">
                        <p> Estado: </p>
                        <p> {this.state.customer.estado} </p>
                      </div>
                      <div className="dados-container">
                        <p> Complemento: </p>
                        <p> {this.state.customer.complemento || '-'} </p>
                      </div>
                    </article>
                  ) : (
                    <span className="register-payment">
                      <h4 className="sub-heading">Você ainda não cadastrou nenhum endereço de entrega.</h4>
                      <a href="/editar/endereco"> Cadastrar agora </a>
                    </span>
                  )}

                  {/* <span id="meus-favoritos"/>
                <h3> Favoritos </h3>
                <button className="btn-rmv-fav" onClick={this.clearFavs.bind(this)}> Remover Todos </button>
                <article className="favoritos">
                  {
                    // this.state.produtos ?
                    this.state.produtos.map((row, index) => (
                      <div className="dados-container fav-container">
                        <button title="Remover dos favoritos" onClick={this.removeFav.bind(this, index)}> <FontAwesomeIcon icon={faTimes}/> </button>
                        <span className="fav-product">
                          <img src={
                            ((row.FOTOS) && (row.FOTOS.length > 0)) ? 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}` 
                            : 
                            `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          }/>
                          <span>
                            <p> { row.NOME } </p>
                            <p> { row.PRECO } </p>
                          </span>
                        </span>
                        <Button title="Adicionar ao carrinho" className="btn-primary-action btn-buy-fav" onClick={this.handleAddCart.bind(this, index)}> Comprar </Button>
                      </div>
                    ))
                    // :
                    // <img src={noFavs}/>
                  }
                </article> */}
                </>
              )}
            </Col>
          </Row>
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions, ...CartActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MeuCadastro);
