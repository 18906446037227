import React from 'react';
import SocialLogin from 'react-social-login'
import Button from 'react-bootstrap/Button';

interface OwnProps {
  triggerLogin: any
}

type Props = OwnProps;

class SocialButton extends React.Component<Props> {
  
  render () {
    return (
      <Button onClick={this.props.triggerLogin} {...this.props}>
        { this.props.children }
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);