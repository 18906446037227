import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import moment from 'moment';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import {Table, Row, Col, Card} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Lottie from 'react-lottie';
import './index.scss';

import successGif from '../../assets/animations/success.json';

interface StateProps {
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  dropOrder(): void;
  clean(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class CheckoutFim extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: !this.props.order ? '/checkout' : null,
      isLoading: false,
      hasError: false,
      hasErrorTitle: '',
      hasErrorMsg: '',
      pedido: this.props.order,
    };
  }

  componentDidMount() {
    const self = this;
    // self.props.dropOrder();
    // self.props.clean(); // limpa carrinho

    // SE FORMA DE PAGAMENTO FOR BOLETO TRATA SHOPLINE
    if (self.state.pedido && self.state.pedido.formapg == 2) {
      self._handleBoleto();
    }

    console.log(this.state.pedido);
  }

  private async _handleBoleto() {
    const self = this;
    self.setState({isLoading: true});

    let param = {
      pedido: self.state.pedido.pedido,
      cnpj: '23294828000103',
      vencimento: moment().utcOffset('-03:00').format('DD/MM/YYYY'),
      total: Diversos.number_format(self.getSubTotal() + self.state.pedido.frete - self.state.pedido.desconto, 2, ',', ''),
      cliente: self.state.pedido.cliente.codigo,
      cliente_nome: self.state.pedido.cliente.nome,
      cliente_rua: self.state.pedido.cliente.rua,
      cliente_numero: self.state.pedido.cliente.numero,
      cliente_bairro: self.state.pedido.cliente.bairro,
      cliente_cidade: self.state.pedido.cliente.cidade,
      cliente_estado: self.state.pedido.cliente.estado,
      cliente_cep: self.state.pedido.cliente.cep,
      cliente_cpf: self.state.pedido.cliente.cpf,
    };

    try {
      const {data} = await self.api.post(`/order/boleto/santander`, param);

      if (!data.status) {
        throw new Error(data.msg);
      }

      const boletoRetorno = data.msg.split(';');

      self.setState({
        pedido: {
          ...self.state.pedido,
          boleto_url: boletoRetorno && boletoRetorno[0] ? boletoRetorno[0] : null,
        },
      });
    } catch (e) {
      console.log(e);
      self.setState({hasErrorBoleto: true, hasErrorBoletoMsg: e.message});
    } finally {
      self.setState({isLoading: false});
    }
  }

  private getSubTotal() {
    const self = this;
    let total = 0.0;
    for (var i = 0; i < self.state.pedido.itens.length; i++) {
      total += self.state.pedido.itens[i].qtd * self.state.pedido.itens[i].valor;
    }
    return total;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <Header />

        <section className="checkout-fim-container">
          <Row>
            <Col xl={7} style={{height: 'fit-content', marginBottom: window.innerWidth <= 1200 ? '2rem' : ''}}>
              <Card className="cart-card-wrapper">
                <Card.Header>
                  {' '}
                  <FontAwesomeIcon icon="check-circle" className="mr-2" /> Pedido Confirmado{' '}
                </Card.Header>
                <Card.Body className="text-center">
                {this.state.pedido && this.state.pedido.formapg === 4 && this.state.pedido.pix && (
                    <>
                      <h4 style={{textAlign: 'center', margin: '1rem auto', fontSize: '1rem', fontWeight: 'normal', color: '#8B8B8B'}}> Seu PIX foi gerado com sucesso! </h4>
                      <div className="row">
                        <div className="col d-flex flex-column align-items-centes justify-content-center text-center">
                          <p style={{fontSize: 20, fontWeight: 700}}>
                            <strong>Valor: </strong>
                            {Diversos.maskPreco(this.state.pedido.pix.valor.toString())}
                          </p>
                          <img src={this.state.pedido.pix.qrcode_image} alt="QRCode Pix para pagamento" style={{width: 250, height: 'auto'}} className="my-2 mx-auto" />
                          <p style={{fontSize: 14, fontWeight: 'normal'}}>
                            <strong>Pix copia e cola: </strong>
                            {this.state.pedido.pix.qrcode}
                          </p>
                        </div>
                      </div>
                      <button
                        className="btn btn-md btn-primary"
                        disabled={this.state.isLoading}
                        style={{padding: '1rem'}}
                        onClick={async () => {
                          navigator.clipboard.writeText(this.state.pedido.pix.qrcode);
                          await new Promise((resolve) => setTimeout(() => resolve(true), 850));
                          this.setState({alreadyCopy: true});
                        }}
                      >
                        {this.state.alreadyCopy ? (
                          <>
                            <FontAwesomeIcon icon="check" className="mr-2" />
                            Copiado
                          </>
                        ) : (
                          <>
                            <FontAwesomeIcon icon="copy" className="mr-2" />
                            Copiar Pix
                          </>
                        )}
                      </button>
                      <hr />
                    </>
                  )}
                  <div className="card-1-title color-primaryHover mb-2">
                    {' '}
                    <h1> Seu pedido foi realizado com sucesso! </h1>{' '}
                  </div>
                  <br />
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: successGif,
                    }}
                    height={150}
                    width={150}
                    isStopped={false}
                    isPaused={false}
                  />
                  <br />
                  <div className="card-1-text text-dark"> O número do seu pedido é: </div>
                  <br />
                  <div className="card-1-pedido color-secondary">{this.state.pedido.pedido}</div>
                  <br />
                  <div className="card-1-msg text-dark mt-1">
                    Você receberá uma notificação no e-mail{' '}
                    <span className="color-secondary">
                      <b> {this.state.pedido.cliente.email} </b>
                    </span>{' '}
                    com todos os detalhes do pedido.
                    <br />
                    Aguardamos a confirmação do pagamento.
                  </div>
                  <hr />
                  {this.state.pedido && this.state.pedido.formapg === 2 && (
                    <>
                      <h4 style={{textAlign: 'center', margin: '1rem auto', fontSize: '1rem', fontWeight: 'normal', color: '#8B8B8B'}}> Seu boleto foi gerado com sucesso! </h4>
                      {/* <form method="post" target="_blank" action="https://shopline.itau.com.br/shopline/impressao.aspx">
                        <input type="hidden" name="DC" id="DC" value={this.state.boletoDc} />
                        <button className="btn btn-lg btn-primary" disabled={this.state.isLoading} style={{padding: '1rem'}}>
                          {this.state.isLoading ? (
                            <>
                              <FontAwesomeIcon icon="spinner" spin className="mr-2" />
                              Gerando boleto, por favor aguarde...
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon="print" className="mr-2" />
                              Imprimir boleto
                            </>
                          )}
                        </button>
                      </form> */}
                      <a href={this.state.pedido.boleto_url} target="_blank" rel="noopener noreferrer" style={{padding: '1rem'}} className="btn btn-lg btn-primary">
                        <FontAwesomeIcon icon="print" className="mr-2" />
                        Imprimir boleto
                      </a>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col xl={5}>
              <Card className="cart-card-wrapper">
                <Card.Header>
                  {' '}
                  <FontAwesomeIcon icon="clipboard-list" className="mr-2" /> Detalhes do pedido
                </Card.Header>
                <Card.Body className="text-left">
                  <Row className="mb-4">
                    <Col>
                      <span className="card-2-title" style={{fontSize: '1rem', color: '#888888'}}>
                        Compra realizada em {this.state.pedido.data} às {this.state.pedido.hora}
                      </span>
                      <br />
                      <span className="card-2-msg">Comprado por {this.state.pedido.cliente.nome}</span>
                    </Col>
                  </Row>

                  <Row className="mx-0 purchase-info">
                    <h4> Produtos: </h4>
                    {this.state.pedido.itens.map((row, index) => (
                      <>
                        <Row className="mx-0" style={{borderTop: '1px solid #D9D9D9', marginTop: '1rem'}} key={index}>
                          <p className="w-100 d-block"> Produto </p>
                          <p> {row.nome} </p>
                        </Row>
                        <Row className="mx-0 w-100">
                          <Col style={{paddingLeft: 0}}>
                            <p> Qtde. </p>
                            <p> {row.qtd} </p>
                          </Col>
                          <Col>
                            <p> Unid. </p>
                            <p style={{textTransform: 'uppercase'}}> R$ {Diversos.number_format(row.valor, 2, ',', '.')} </p>
                          </Col>
                          <Col style={{paddingRight: 0}}>
                            <p> Total </p>
                            <p style={{textTransform: 'uppercase'}}> R$ {Diversos.number_format(row.valor * row.qtd, 2, ',', '.')} </p>
                          </Col>
                        </Row>
                      </>
                      //   <tr key={index}>
                      //     <td>({row.produto}) {row.nome}</td>
                      //     <td>{row.qtd}</td>
                      //     <td>R$ {Diversos.number_format(row.valor,2,",",".")}</td>
                      //     {/* <td>0,00</td> */}
                      //     <td>R$ {Diversos.number_format((row.valor * row.qtd),2,",",".")}</td>
                      //   </tr>
                    ))}
                    {/* <Row>
                      <p> Produto </p>
                      <p>  </p>
                    </Row>
                    <Row>
                      <Col>
                        <p> Produto </p>
                        <p>  </p>
                      </Col>
                      <Col>
                        <p> Produto </p>
                        <p>  </p>
                      </Col>
                      <Col>
                        <p> Produto </p>
                        <p>  </p>
                      </Col>
                    </Row>
                      <Table responsive="md">
                        <thead className="table-head">
                          <tr>
                            <th className="table-heading">Produto</th>
                            <th>Quantidade</th>
                            <th className="th-min-width-1">Preço unitário</th>
                            <th className="th-min-width-2">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.pedido.itens.map((row, index) => (
                              <tr key={index}>
                                <td>({row.produto}) {row.nome}</td>
                                <td>{row.qtd}</td>
                                <td>R$ {Diversos.number_format(row.valor,2,",",".")}</td>
                                <td>R$ {Diversos.number_format((row.valor * row.qtd),2,",",".")}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table> */}
                  </Row>

                  <Row className="mx-0 checkout-detalhes-container">
                    <Col xs={12} md={6} style={{paddingLeft: 0}}>
                      <span className="checkout-detalhes-wrapper">
                        <h4>Forma de pagamento: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td>
                                {this.state.pedido && this.state.pedido.formapg === 1 ? (
                                  <span>
                                    Cartão de crédito <br />
                                    {this.state.pedido.cartao.bandeira} (final ...{this.state.pedido.cartao.numero}) <br />
                                    {this.state.pedido.cartao.parcelas && this.state.pedido.cartao.parcelas > 0 ? `Parcelado em ${this.state.pedido.cartao.parcelas}x` : `A vista`}
                                  </span>
                                ) : this.state.pedido && this.state.pedido.formapg === 4 ? (
                                  <span style={{marginTop: '1rem'}}>
                                    Pix <br />
                                  </span>
                                ) : (
                                  <span style={{marginTop: '1rem'}}>
                                    Boleto bancário <br />
                                    <a href={this.state.pedido.boleto_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-primary">
                                      <FontAwesomeIcon icon="print" className="mr-2" />
                                      Imprimir boleto
                                    </a>
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                      <span className="checkout-detalhes-wrapper">
                        <h4>Forma de entrega: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <th>Forma:</th>
                              <td>{this.state.pedido.entrega}</td>
                            </tr>
                            <tr>
                              <th>Prazo:</th>
                              <td>{this.state.pedido.dtentrega}</td>
                            </tr>
                            <tr>
                              <th>Preço:</th>
                              <td>R$ {Diversos.number_format(this.state.pedido.frete, 2, ',', '')}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </Col>

                    <Col xs={12} md={6} style={{paddingRight: 0, paddingLeft: window.innerWidth <= 768 ? 0 : ''}}>
                      <span className="checkout-detalhes-wrapper">
                        <h4>Endereço de entrega: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <td>
                                {this.state.pedido.cliente.rua}, {this.state.pedido.cliente.numero}, <br />
                                {this.state.pedido.cliente.complemento} <br />
                                {this.state.pedido.cliente.bairro} <br />
                                {this.state.pedido.cliente.cidade} - {this.state.pedido.cliente.estado} <br />
                                {this.state.pedido.cliente.cep}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                      <span className="checkout-detalhes-wrapper">
                        <h4> Resumo: </h4>
                        <table className="table table-sm">
                          <tbody>
                            <tr>
                              <th> Subtotal </th>
                              <td> R$ {Diversos.number_format(this.getSubTotal(), 2, ',', '')} </td>
                            </tr>
                            <tr>
                              <th>Frete</th>
                              <td>R$ {Diversos.number_format(this.state.pedido.frete, 2, ',', '')}</td>
                            </tr>
                            <tr>
                              <th>Desconto</th>
                              <td>R$ {Diversos.number_format(this.state.pedido.desconto, 2, ',', '')}</td>
                            </tr>
                            <tr>
                              <th>TOTAL</th>
                              <td>R$ {Diversos.number_format(this.getSubTotal() + this.state.pedido.frete - this.state.pedido.desconto, 2, ',', '')}</td>
                            </tr>
                          </tbody>
                        </table>
                      </span>
                    </Col>
                  </Row>
                </Card.Body>

                <Card.Footer className="text-center">
                  <a href="/" target="_self" className="btn checkout-done btn-lg">
                    {' '}
                    <FontAwesomeIcon icon="arrow-left" /> Voltar para loja{' '}
                  </a>
                </Card.Footer>
              </Card>
            </Col>
          </Row>

          {/* {
            this.state.pedido.formapg === 2 &&
            <Row className="mt-5">
              <Col>
                <Card className="cart-card-wrapper">
                  <Card.Header> <FontAwesomeIcon icon="barcode" className="mr-2" /> Boleto bancário</Card.Header>
                  <Card.Body className="text-left">

                    <Row>
                      <Col>
                        <h4>Seu boleto foi gerado com sucesso!</h4>
                        <br/>
                        <table className="table table-sm">
                          <tr>
                            <td colSpan={2} className="text-center py-3">
                              <a href={this.state.pedido.boleto_url} target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-primary">
                                <FontAwesomeIcon icon="print" className="mr-2"/>
                                Clique aqui para imprimir seu boleto
                              </a>
                            </td>
                          </tr>
                        </table>
                      </Col>
                    </Row>

                  </Card.Body>
                </Card>
              </Col>
            </Row>
          } */}
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
  order: state.order.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions, ...CartActions, ...OrderActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutFim);
