import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import * as UserActions from '../../store/ducks/user/actions';

import {Redirect} from 'react-router-dom';
import Api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import {Container, InputGroup, FormControl, Button, Image, Form} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faReceipt, faUnlock, faEnvelope, faSyncAlt, faGlassCheers, faSignOutAlt, faHeart, faCreditCard} from '@fortawesome/free-solid-svg-icons';
import {faUser, faArrowRight, faMapMarkerAlt, faShoppingCart, faChevronDown, faBars, faSearch, faDrumstickBite} from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogout(): void;
  setParam(data: Param): void;
}

type Props = StateProps & DispatchProps;

class UserSidebar extends Component<Props> {
  state: any = {};
  api: any = null;

  constructor(props: any) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,
      isLoadingCustomer: true,
      customer: null,
      hasError: false,
      hasSuccess: false,
      newsletter: '',
    };
  }

  componentDidMount() {
    const self = this;

    if (self.props.user && self.props.user.status !== false) {
      self.getCustomer();
    }

    if (!self.props.user || self.props.user.status !== true) {
      self.setState({
        redirect: '/login',
      });
    }
  }

  private async getCustomer() {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if (data.status == false) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }
    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message,
      });
    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleLogout() {
    const self = this;
    self.props.doLogout();
    self.setState({redirect: '/'});
  }

  private async handleNewsletter() {
    const self = this;
    let param: any = {};

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    param = {
      nome: self.state.customer.nome,
      email: self.state.customer.email,
      recebeEmail: 'S',
    };

    try {
      const {data} = await self.api.post('/customer/newsletter', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg('success', 'Sucesso', 'Inscrição feita com sucesso!');
      }
    } catch (e) {
      console.error(e);
      if (e.message === 'E-mail já cadastrado para receber newsletter') self.setMsg('success', 'Atenção', `${e.message}`);
      else self.setMsg('error', 'Atenção', `${e.message}`);
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async handleNewsletterEmail(event) {
    event.preventDefault();

    const self = this;
    let param: any = {};

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    if (!self.state.newsletter) {
      self.setMsg('error', 'E-mail inválido', 'Insira um endereço de e-mail válido');
      return;
    } else {
      param = {
        nome: self.state.customer.nome,
        email: self.state.newsletter,
        recebeEmail: 'S',
      };
    }

    try {
      const {data} = await self.api.post('/customer/newsletter', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg('success', 'Sucesso', 'Inscrição feita com sucesso!');
        self.setState({newsletter: ''});
      }
    } catch (e) {
      console.error(e);
      if (e.message === 'E-mail já cadastrado para receber newsletter') self.setMsg('success', 'Atenção', `${e.message}`);
      else self.setMsg('error', 'Atenção', `${e.message}`);
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async setMsg(type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      self.setState(
        {
          hasError: true,
          hasErrorTitle: title,
          hasErrorMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasError: false}), timeout);
        },
      );
    } else {
      self.setState(
        {
          hasSuccess: true,
          hasSuccessTitle: title,
          hasSuccessMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasSuccess: false}), timeout);
        },
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <article className="user-sidebar">
          <h4>{this.state.customer && `Olá ${this.state.customer.nome}!`}</h4>
          <a href="/meu-cadastro#meu-perfil">
            {' '}
            <FontAwesomeIcon icon={faUser} className="sidebar-icon" /> Meu Perfil{' '}
          </a>
          <a href="/meus-pedidos">
            {' '}
            <FontAwesomeIcon icon={faReceipt} className="sidebar-icon" /> Meus Pedidos{' '}
          </a>
          <a href="/meu-cadastro#reset-senha">
            {' '}
            <FontAwesomeIcon icon={faUnlock} className="sidebar-icon" /> Reset de Senha{' '}
          </a>
          <a href="/meu-cadastro#entrega">
            {' '}
            <FontAwesomeIcon icon={faMapMarkerAlt} className="sidebar-icon" /> Endereço de Entrega{' '}
          </a>
          {/* <a href="/meu-cadastro#cartoes"> <FontAwesomeIcon icon={faCreditCard} className="sidebar-icon"/> Cartão Cadastrado </a> */}
          {/* <a href="/meu-cadastro#meus-favoritos"> <FontAwesomeIcon icon={faHeart} className="sidebar-icon"/> Favoritos </a> */}
          <hr />
          <span className="central-newsletter">
            <h5> Gostaria de receber atualizações, promoções, dicas e muito mais? </h5>
            <p> Inscreva-se na nossa Newsletter! </p>
            <button className="btn-link-action" onClick={this.handleNewsletter.bind(this)}>
              {' '}
              Usar meu e-mail para inscrição <FontAwesomeIcon icon={faArrowRight} />{' '}
            </button>
            <small> Ou utilize outro e-mail </small>
            <Form method="post" action="#" onSubmit={this.handleNewsletterEmail.bind(this)}>
              <InputGroup role="form" className="central-newsletter-container">
                <FontAwesomeIcon icon={faEnvelope} />
                <FormControl
                  className="search-box-input"
                  type="email"
                  name={'email'}
                  placeholder="Insira seu e-mail"
                  aria-label="Newsletter"
                  value={this.state.newsletter || ''}
                  aria-describedby="Insira seu e-mail para se cadastrar em nossa newsletter"
                  onChange={(event) => this.setState({newsletter: event.target.value})}
                />
                <InputGroup.Prepend>
                  <Button className="btn-primary-action" type="submit">
                    {' '}
                    Enviar{' '}
                  </Button>
                </InputGroup.Prepend>
              </InputGroup>
            </Form>
            {this.state.hasError && <p className="error-warning"> {this.state.hasErrorMsg} </p>}
            {this.state.hasSuccess && <p className="success-warning"> {this.state.hasSuccessMsg} </p>}
            <small>
              Ao se cadastrar você concorda em receber comunicação publicitária, com ofertas e novidades, conforme a nossa{' '}
              <a href="/politica-de-privacidade" id="privacy-policy">
                {' '}
                política de privacidade{' '}
              </a>
            </small>
          </span>
          <hr />
          <a href="/institucional/atendimento"> Fale Conosco </a>
          <a href="/central-de-relacionamento" onClick={() => this.props.setParam({...this.props.param, faqTab: 'trocas'})}>
            {' '}
            Política de Trocas e Devoluções{' '}
          </a>
          <button title="Fazer Logout" className="sidebar-logout" onClick={this.handleLogout.bind(this)}>
            {' '}
            <FontAwesomeIcon icon={faSignOutAlt} className="sidebar-icon" /> Sair{' '}
          </button>
        </article>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);
