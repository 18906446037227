import {Reducer} from 'redux';
import {v4 as uuidv4} from 'uuid';
import {CartState, CartTypes, ProductType} from './types';

const INITIAL_STATE: CartState = {
  data: {
    produtos: [],
    freteSelected: '',
    cupomSelected: '',
  },
};

const reducer: Reducer<any> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // FUNCAO PARA ADICIONAR PRODUTO NO CARRINHO
    case CartTypes.ADD:
      let tmp = state.data.produtos;
      let achou = false;

      for (var i = 0; i < tmp.length; i++) {
        if (tmp[i].codigo === action.payload.data.codigo) {
          if (tmp[i].promo && tmp[i].promo.CDPROMOCAO === action.payload.data.codPromo) {
            achou = true;
            tmp[i].qty += parseInt(action.payload.data.qty);
          }
        }
      }

      if (achou === false) tmp.push({...action.payload.data, rowid: uuidv4()});

      return {
        ...state,
        data: {produtos: tmp, freteSelected: state.data.freteSelected, cupomSelected: state.data.cupomSelected},
      };

    // FUNCAO PARA APAGAR PRODUTO NO CARRINHO
    case CartTypes.DROP:
      let tmpProduto: any = null;
      let produtos = state.data.produtos;
      let tmpDrop: Array<ProductType> = [];

      for (var i = 0; i < produtos.length && !tmpProduto; i++) {
        if (produtos[i].rowid === action.payload.rowid) {
          tmpProduto = produtos[i];
        }
      }

      for (var i = 0; i < produtos.length; i++) {
        if (produtos[i].rowid !== action.payload.rowid) {
          if (produtos[i].promo && produtos[i].promo.CDPROMOCAO) {
            if (tmpProduto && tmpProduto.promo && tmpProduto.promo.CDPROMOCAO) {
              if (tmpProduto.promo.CDPROMOCAO === produtos[i].promo.CDPROMOCAO) {
                continue;
              } else {
                tmpDrop.push(produtos[i]);
              }
            } else {
              tmpDrop.push(produtos[i]);
            }
          } else {
            tmpDrop.push(produtos[i]);
          }
        }
      }

      return {
        ...state,
        data: {produtos: tmpDrop},
      };

    // FUNCAO PARA APAGAR PRODUTO NO CARRINHO
    case CartTypes.UPDATE:
      let produtosEdit = state.data.produtos;
      for (var i = 0; i < produtosEdit.length; i++) {
        if (produtosEdit[i].rowid === action.payload.data.rowid) {

          if (action.payload.data.promo && action.payload.data.promo.CDPROMOCAO) {
            for (var j = 0; j < produtosEdit.length; j++) {
              if (produtosEdit[j].promo &&  produtosEdit[j].promo.CDPROMOCAO) {
                if (action.payload.data.promo.CDPROMOCAO === produtosEdit[j].promo.CDPROMOCAO && produtosEdit[j].rowid !== action.payload.data.rowid) {
                  if (action.payload.data.qty > produtosEdit[i].qty)
                    produtosEdit[j].qty = parseInt(produtosEdit[j].qty) + parseInt(produtosEdit[j].qtdPromo);
                  else
                    produtosEdit[j].qty = parseInt(produtosEdit[j].qty) - parseInt(produtosEdit[j].qtdPromo);
                }
              }
            }
          }

          produtosEdit[i] = {...produtosEdit[i], ...action.payload.data};
        }
      }
      return {
        ...state,
        data: {produtos: produtosEdit, freteSelected: state.data.freteSelected, cupomSelected: state.data.cupomSelected},
      };

    case CartTypes.CLEAN:
      return {
        ...state,
        data: {produtos: [], freteSelected: '', cupomSelected: ''},
      };

    case CartTypes.SET_FRETE:
      return {
        ...state,
        data: {...state.data, freteSelected: action.payload.freteSelected, cupomSelected: state.data.cupomSelected},
      };

    case CartTypes.SET_CUPOM:
      return {
        ...state,
        data: {...state.data, freteSelected: state.data.freteSelected, cupomSelected: action.payload.cupom},
      };

    default:
      return state;
  }
};

export default reducer;
