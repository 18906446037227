import React from 'react';
import './index.scss';

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faPhone, faMapMarkerAlt, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Lojas from '../../assets/Images/conheca-lojas.png';
import Saloes from '../../assets/Images/conheca-saloes.png';
import Missao from '../../assets/Images/missao.png';
import Visao from '../../assets/Images/visao.png';
import Valores from '../../assets/Images/nossos-valores.png';
import sobreDiva from '../../assets/Images/sobre-diva.png';

class Sobre extends React.Component<{}> {
  state: any = null;

  constructor(props) {
    super(props);
  }

  render() {
    const mdDevice = window.innerWidth <= 1200;

    return (
      <>
        <h1> Sobre a Depimiel </h1>
        <h3>Quem somos:</h3>
        <aside className="category-img-container">{/* <img src={sobreDiva} alt="Banner Nossas Lojas" /> */}</aside>
        <p className="my-4">
          {' '}
          Somos uma empresa especializada no desenvolvimento e produção de depilatórios. Com mais de 30 anos de experiência, nos consolidamos como uma empresa de sucesso e
          referência em produtos depilatórios presente em toda a América Latina.
        </p>
        <p className="my-4">
          {' '}
          Atualmente temos em nosso portfólio uma linha moderna e diversificada de produtos, que atende todas as etapas da depilação, oferecendo opções para que nossos clientes
          escolham o método e os produtos mais apropriados à sua necessidade.
        </p>
        <p className="my-4">
          {' '}
          As ceras depilatórias Depimiel possuem blends exclusivos de matérias primas, selecionadas e desenvolvidas em nossos laboratórios, que deixam suas fórmulas mais Fortes,
          Resistentes e Flexíveis.
        </p>
        <p className="my-4">
          {' '}
          Em Misiones, na Argentina, a Depimiel possui fazenda própria onde cultiva uma espécie específica de pinus, de onde é extraída a goma resina, mediante um processo
          artesanal e 100% natural, preservando a fauna e flora local. A partir da goma resina são produzidas algumas das principais matérias primas utilizadas na produção das
          ceras depilatórias, como a resina de colofônia, ésteres e outras resinas especiais, o que permite à Depimiel desenvolver produtos exclusivos e de alta performance.
        </p>
      </>
    );
  }
}

export default Sobre;
