import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import queryString from 'query-string';
import Pagination from "react-js-pagination";
// import Shimmer from 'react-js-loading-shimmer';
import Shimmer from '@bit/aju.main.shimmer';
import {Helmet} from "react-helmet";
import {ApplicationState} from '../../store';
import * as UserActions from '../../store/ducks/user/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import './index.scss';

import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FilterModal from '../../components/FilterModal';
import Product from '../../components/Product';
import { Diversos } from '../../services/diversos';

interface RouteParams {
  menu1: string
  menu2: string
  menu3: string
}

class OfertasDoMes extends Component<{}> {

  api: any = null;
  state: any = {};

  constructor (props) {
    super(props);
    this.api = new Api();
    const {pag, marcas, preco} = queryString.parse(props.location.search);
    const {menu1, menu2, menu3} = props.match.params;
    this.state = {
      paramMenu1: menu1,
      paramMenu2: menu2,
      paramMenu3: menu3,
      menu1: {},
      menu2: {},
      menu3: {},
      isLoadingMenu: false,
      isLoadingProdutos: true,
      produtos: [],
      page: (pag) ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: 20,
      produtosTotal: 1,
      showModal: false,
      filtroMarcas: (!marcas) ? [] : Array.isArray(marcas) ? marcas : [marcas],
      filtroPrecos: (!preco) ? [] : preco.toString().split("-"),
    }
  }

  async componentDidMount () {
    const self = this;
    self.getProdutos();
  }

  /**
   * FUNCAO PARA BUSCAR LISTA DE PRODUTOS NA API
   */
  private async getProdutos () {
    const self = this;
    const {marcas, preco, pag} = queryString.parse(window.location.search);
    let filtros: any = {};

    if ( marcas )
      filtros = {...filtros, marcas};

    if ( preco )
      filtros = {...filtros, preco};

    filtros = {...filtros, per_page: self.state.produtosPerPage};

    self.setState({isLoadingProdutos: true});

    let param = {
    }

    try {
      const {data} = await self.api.post(`/product/promocao/${self.state.page}?${queryString.stringify(filtros)}`, param);

      if ( !data.status ) {
        throw new Error(`Falha ao buscar produtos`);
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
      });

    } catch (e) {
      console.error(e);
      self.setState({ produtos: [] });
    } finally {
      self.setState({isLoadingProdutos: false});
    }
  }

  /**
   * FAZ TRATAMENTO DO LINK PARA REDIRECIONAMENTO DE PAGINACAO
   */
  private handlePagination ( pag: any ) {
    const self = this;
    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    const price = self.state.priceValue;

    let newsParam = param;

    if ( brands )
      newsParam = {...newsParam, marcas: brands};

    if ( price )
      newsParam = {...newsParam, preco: price};

    if (( pag <= 1 ) || ( !pag ))
      newsParam = {...newsParam, pag: '1'};
    else if ( pag > self.state.produtosLastPage )
      newsParam = {...newsParam, pag: self.state.produtosLastPage};
    else
      newsParam = {...newsParam, pag: pag.toString()};

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  private _henderSEOTags () {
    if ( this.state.isLoading ) {
      return (<></>);
    } else {
      const title = `Produtos em promoção - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/promocoes`;
      const description = `Principais produtos em promoção - ${process.env.REACT_APP_TITLE}`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index"/>
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="270229570743012" />
          <meta property="og:site_name" content={title}/>
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website"/>
          <meta property="og:url" content={url}/>
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description}/>
        </Helmet>
      );
    }
  }

  render () {
    return (
      <>
        {this._henderSEOTags()}
        <Header/>
        <section className="products-display">


          <Row className="section-title">
            {
              (this.state.isLoadingMenu) ?
                <p className="loading-content"> <i className="fas fa-spin fa-spinner"></i> </p>
              :
                <>
                  {/* TITULO DA PAGINA */}
                  <Col lg={7} xl={8}>
                    <h1 className="mt-5 mb-5"> <span className="heading-border"> Ofertas do Mês </span> </h1>
                  </Col>
                  {
                    (this.state.produtos.length <= 0) ?
                      null
                    :
                      <Col lg={5} xl={4}>
                      <Row className="filters-container">
                        <Col xs={12} sm={2} md={2} lg={3} className="m-auto">
                        {/* <p className="filters"> Filtros </p> */}
                          <Button variant="outline-primary" className="filters" onClick={() => this.setState({showModal: true})}> Filtros </Button>
                        </Col>
                        {
                          (this.state.produtos.length < 20) ?
                            null
                          :
                            <Col xs={12} sm={10} md={10} lg={9} className="m-auto d-flex align-items-center">
                              <Form className="ipp-form">
                                <Form.Group  className="ipp-form-group" controlId="items-per-page">
                                  <Form.Control as="select" onChange={(event) => this.setState({produtosPerPage: event.target.value}, this.getProdutos.bind(this)) }>
                                    <option value={20}> mostrar 20 itens por página </option>
                                    <option value={30}> mostrar 30 itens por página </option>
                                    { ( this.state.produtos.length < 30 ? null : <option value={40}> mostrar 40 itens por página </option> ) }
                                  </Form.Control>
                                </Form.Group>
                              </Form>
                            </Col>
                        }
                      </Row>
                    </Col>
                  }
                </>
            }
          </Row>

          {
            (((this.state.filtroMarcas) && (this.state.filtroMarcas.length > 0)) || ((this.state.filtroPrecos) && (this.state.filtroPrecos.length > 0))) &&
            <Row>
              <Col>
                <div className="line-filters">
                  <span>Filtros:</span>
                  {
                    ((this.state.filtroMarcas) && (this.state.filtroMarcas.length > 0)) &&
                    <span className="badge badge-primary mx-1">Marca: {this.state.filtroMarcas.join(",")}</span>
                  }
                  {
                    ((this.state.filtroPrecos) && (this.state.filtroPrecos.length > 0)) &&
                    <>
                      <span className="badge badge-primary mx-1">Preço mínimo: {`R$ ${Diversos.number_format(this.state.filtroPrecos[0],2,",","")}`}</span>
                      <span className="badge badge-primary mx-1">Preço máximo: {`R$ ${(this.state.filtroPrecos[1] == Number.MAX_SAFE_INTEGER) ? '-' : Diversos.number_format(this.state.filtroPrecos[1],2,",","")}`}</span>
                    </>
                  }
                </div>
              </Col>
            </Row>
          }

          {/* VITRINE DE PRODUTOS */}
          {
            (this.state.isLoadingProdutos) ?
            <Row>
              {
                [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map((row, index) => (
                  <Col xs={12} sm={6} md={4} lg={3} key={index} className="d-flex justify-content-center my-3" id="product-col" style={{height: 250}}>
                    <div className="product-container">
                      <Row className="w-100 img-wrapper">
                        <div className="mx-auto" style={{width: '90%'}}>
                          <Shimmer width="100%" height="100px"/>
                        </div>
                      </Row>
                      <Row className="w-100 product-info">
                        <Row className="product-name">
                          <Shimmer width="100%" height="100%" />
                        </Row>
                      </Row>
                    </div>
                  </Col>
                ))
              }
            </Row>
            :
            (this.state.produtos.length <= 0) ?
            <span className="products-not-found">
              <p> Nenhum produto encontrado. </p>
            </span>
            :
            <Row>
              {
                this.state.produtos.map((row, index) => (
                  <Col xs={6} sm={6} md={4} lg={3} key={index} className="d-flex justify-content-center" id="product-col">
                    <Product
                      imageNormal={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ?
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                        :
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      imageOver={
                        ((row.FOTOS) && (row.FOTOS.length > 0)) ?
                          (row.FOTOS.length > 1) ?
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                          :
                          `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                        :
                        `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                      }
                      item={row}
                    />
                  </Col>
                ))
              }
            </Row>
          }

          {/* PAGINACAO */}
          {
            (this.state.produtos.length <= 0) ?
              null
            :
              <Row>
                <Col className="mt-4 mb-5 d-flex justify-content-center">
                  <Pagination
                    activePage={this.state.page}
                    itemsCountPerPage={this.state.produtosPerPage}
                    totalItemsCount={this.state.produtosTotal}
                    pageRangeDisplayed={5}
                    onChange={this.handlePagination.bind(this)}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </Col>
              </Row>
          }

        </section>
        <Footer/>

        {/* Cria modal dos filtros */}
        <Modal
        show={this.state.showModal}
        onHide={() => this.setState({showModal: false})}
        dialogClassName="modal-100w"
        aria-labelledby="filter-modal"
        className="filter-modal">
          <FilterModal
          // handleFilter={this.handlePagination.bind(this)}
          title={
            this.state.menu3Selected && this.state.menu3Selected.DESCRICAO ?
              `${this.state.menu1.DESCRICAO} / ${this.state.menu2.DESCRICAO}`
            :
            this.state.menu2.DESCRICAO ?
              `${this.state.menu1.DESCRICAO} / ${this.state.menu2.DESCRICAO}`
            :
              this.state.menu1.DESCRICAO
          }
          marca={this.state.filtroMarcas}
          marcas={this.state.marcas}
          menu1={this.state.menu1}
          menu2={this.state.menu2}
          menu3={this.state.menu3}
          menu3Selected={this.state.menu3Selected}
          />
        </Modal>
      </>
    );
  }

}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OfertasDoMes);
