import React, {Component} from 'react';
import queryString from 'query-string';
import Api from '../../services/api';

import * as ParamActions from '../../store/ducks/param/actions';
import {Param} from '../../store/ducks/param/types';
import {Cart} from '../../store/ducks/cart/types';
import {Diversos} from '../../services/diversos';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import './index.scss';

// import de Componentes do Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faChevronDown, faChevronUp, faFilter, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import FilterBanner from '../../assets/Images/filter-banner.png';

const PRICES = [
  {value: '0-49', label: 'Até R$49,00'},
  {value: '49-99', label: 'De R$49,00 a R$99,00'},
  {value: '99-149', label: 'De R$99,00 a R$149,00'},
  {value: '149-199', label: 'De R$149,00 a R$199,00'},
  {value: '199-249', label: 'R$ 199,00 a R$ 249,00'},
  {value: '249.01-' + Number.MAX_SAFE_INTEGER, label: 'Acima de R$ 249,00'},
];

interface OwnProps {
  title: any;
  marca: any;
  marcas: any;
  ehPesquisa?: boolean;
  ehMarca?: boolean;
}

interface StateProps {
  param: Param;
}

interface DispatchProps {
  setParam(data: Param): void;
}

interface RouteParams {
  menu1?: string;
  menu2?: string;
  menu3?: string;
  menu3Selected?: any;
}

type Props = StateProps & DispatchProps & OwnProps & RouteParams;

class FilterModal extends Component<Props> {
  state: any;
  api: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();

    const {pag, marcas, preco} = queryString.parse(window.location.search);

    this.state = {
      marcas: props.marcas,

      menu1: props.menu1,
      menu2: props.menu2,
      menu3: props.menu3,
      menu3Selected: props.menu3Selected,
      isLoadingMenu: false,

      showMore: false,
      brands: [],
      testBrands: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      brandValues: !marcas ? [] : marcas,
      priceValue: !preco ? null : preco,
      priceMin: !preco ? null : preco.toString().split('-')[0],
      priceMax: !preco ? null : preco.toString().split('-')[1],
      isLoadingMarcas: false,
    };
  }

  componentDidMount() {
    this.getMarcas();
  }

  //FUNCAO PARA BUSCAR MARCAS DE PRODUTOS NA API
  private async getMarcas() {
    const self = this;

    self.setState({isLoadingMarcas: true});

    let param = {};

    try {
      const {data} = await self.api.post(`/product/marcas`, param);

      if (!data.status) {
        throw new Error(`Falha ao buscar marcas de produtos`);
      }

      self.setState({
        brands: data.msg,
      });
    } catch (e) {
      console.error(e);
      self.setState({brands: []});
    } finally {
      self.setState({isLoadingMarcas: false});
    }
  }

  private handleFilterCleanse() {
    if (this.state.brandValues.length <= 0 && !this.state.priceValue) {
      return null;
    } else {
      this.setState(
        {
          brandValues: [],
          priceValue: null,
        },
        this._handleClickFilter.bind(this, null, null, null),
      );
    }
  }

  private handleShowMore(item: string) {
    if (item === 'brands') {
      this.setState({showBrands: !this.state.showBrands});
      return;
    } else if (item === 'prices') {
      this.setState({showPrices: !this.state.showPrices});
      return;
    }

    this.setState({showCategories: !this.state.showCategories});
  }

  private async _handleClickFilter(menu1: any, menu2: any = null, menu3: any = null) {
    const self = this;

    let menu1Desc = menu1 && menu1.DESCRICAO ? menu1.DESCRICAO : '';
    let menu2Desc = menu2 && menu2.DESCRICAO ? menu2.DESCRICAO : '';
    let menu3Desc = menu3 && menu3.DESCRICAO ? menu3.DESCRICAO : '';

    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    const price = self.state.priceValue;
    const pag = self.state.pag;

    let newsParam = param;

    if (brands) newsParam = {...newsParam, marcas: brands};

    if (price) newsParam = {...newsParam, preco: price};

    if (pag <= 1 || !pag) newsParam = {...newsParam, pag: '1'};
    else if (pag > self.state.produtosLastPage) newsParam = {...newsParam, pag: self.state.produtosLastPage};
    else newsParam = {...newsParam, pag: pag.toString()};

    if (menu1Desc && menu2Desc && menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}/${Diversos.toSeoUrl(menu2Desc)}/${Diversos.toSeoUrl(menu3Desc)}`;
    else if (menu1Desc && menu2Desc && !menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}/${Diversos.toSeoUrl(menu2Desc)}`;
    else if (menu1Desc && !menu2Desc && !menu3Desc) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}`;
    else if (self.props.ehPesquisa === true) link = `/departamento/${Diversos.toSeoUrl(menu1Desc)}`;

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  render() {
    const numberOfBrands = this.state.showBrands ? this.state.marcas.lenght : 5;
    const numberOfCategories = this.state.showCategories ? 10 : 5;

    return (
      <>
        <Modal.Header closeButton className="sm-filter-header">
          <Modal.Title>Filtros</Modal.Title>
        </Modal.Header>

        <Modal.Body className="sm-filter-body">
          <div className="sm-filter-wrapper">
            <small> Ordenar por: </small>

            <Accordion>
              <Card style={{borderRadius: 0}}>
                <Accordion.Toggle as={Card.Header} eventKey="0" className="sm-filter-by">
                  <h4>
                    <FontAwesomeIcon icon={faArrowDown} />
                    {this.props.ehPesquisa
                      ? 'Departamentos'
                      : this.props.title
                      ? this.props.title
                      : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3Selected
                      ? this.state.menu2.DESCRICAO || ''
                      : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3.length > 0
                      ? this.state.menu2.DESCRICAO || ''
                      : this.state.menu1.DESCRICAO || ''}
                  </h4>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                  <Card.Body className="sm-filter-content">
                    <ul className="sm-filter-list">
                      {this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3Selected
                        ? this.state.menu3.slice(0, numberOfBrands).map((row, index) => (
                            <li id={`filter-opt-${index}`} onClick={this._handleClickFilter.bind(this, this.state.menu1, this.state.menu2, row)}>
                              {row.DESCRICAO}
                            </li>
                          ))
                        : this.state.menu1.DESCRICAO && this.state.menu2.DESCRICAO && this.state.menu3.length > 0
                        ? this.state.menu3.slice(0, numberOfCategories).map((row, index) => (
                            <li id={`filter-opt-${index}`} onClick={this._handleClickFilter.bind(this, this.state.menu1, this.state.menu2, row)}>
                              {row.DESCRICAO}
                            </li>
                          ))
                        : this.state.menu2 && this.state.menu2.length > 0
                        ? this.state.menu2.slice(0, numberOfCategories).map((row, index) => (
                            <li id={`filter-opt-${index}`} onClick={this._handleClickFilter.bind(this, this.state.menu1, row)}>
                              {row.DESCRICAO}
                            </li>
                          ))
                        : this.props.ehPesquisa === true
                        ? this.state.menu1.slice(0, numberOfCategories).map((row, index) => (
                            <li id={`filter-opt-${index}`} onClick={this._handleClickFilter.bind(this, row, null, null)}>
                              {row.DESCRICAO}
                            </li>
                          ))
                        : null}
                    </ul>
                    <button className="mt-3 btn-link-action" onClick={this.handleShowMore.bind(this, 'categories')}>
                      {this.state.showCategories ? 'Ver menos...' : 'Ver mais...'}
                    </button>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {/* <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="sm-filter-by">
                  <h4> <FontAwesomeIcon icon={faArrowDown}/> Tipos de cabelos </h4>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="1">
                  <Card.Body className="sm-filter-content">
                    <ul className="sm-filter-list">
                      <li> Cabelos Danificados </li>
                      <li> Cabelos Cacheados ou Ondulados </li>
                      <li> Cabelos Quimicamente Tratados </li>
                      <li> Cabelos Loiros ou Descoloridos </li>
                      <li> Cabelos Coloridos ou com Mechas </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card> */}

              {/* {
                this.state.marcas.length > 0 &&
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2" className="sm-filter-by">
                    <h4> <FontAwesomeIcon icon={faArrowDown}/> Marcas </h4>
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="sm-filter-content">
                      <Form className="filter-check">
                      {
                        this.state.marcas.slice(0, numberOfBrands).map((row, index) => (
                          <Form.Check custom className="sm-checkbox-filter"id={`filter-checkbox-marca-${index}`}
                          label={row.MARCA} value={row.MARCA} checked={this.state.brandValues.includes(row.MARCA) || this.state.brandValues === row.MARCA}
                          onChange={() => this.setState({brandValues: [...this.state.brandValues, row.MARCA]}, this._handleClickFilter.bind(this, null, null, null))}/>
                        ))
                      }

                      </Form>
                      {
                        this.state.marcas.length > 5 &&    
                        <button className="mt-3 btn-link-action" onClick={this.handleShowMore.bind(this, 'brands')}>
                        { this.state.showBrands ? 'Ver menos...' :  'Ver mais...' }
                        </button>
                      }
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> 
              } */}

              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3" className="sm-filter-by">
                  <h4>
                    {' '}
                    <FontAwesomeIcon icon={faArrowDown} /> Preço{' '}
                  </h4>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="3">
                  <Card.Body className="sm-filter-content">
                    <Form className="filter-check">
                      {PRICES.map((row, index) => (
                        <>
                          <Form.Check
                            custom
                            className="sm-checkbox-filter"
                            id={`filter-checkbox-${row.value}`}
                            label={row.label}
                            checked={this.state.priceValue === row.value}
                            onClick={() => this.setState({priceValue: row.value}, this._handleClickFilter.bind(this, null, null, null))}
                          />
                        </>
                      ))}
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            {/* Snippet banner promocional */}
            {/* <div className="filter-img-container">
              <a href="/departamento" title="Ir para a página" >
                <img src={FilterBanner} alt="Banner promocional"/>
              </a>
            </div> */}
          </div>

          <div className="sm-filter-footer">
            <div>
              <button
                style={{border: 'none', backgroundColor: 'transparent', color: '#d9534f', fontSize: 14, fontWeight: 'bold'}}
                title="Resetar filtros"
                onClick={this.handleFilterCleanse.bind(this)}
              >
                <FontAwesomeIcon icon={faTimesCircle} style={{marginRight: '.5rem'}} />
                Limpar Filtros
              </button>
              {/* <button className="btn-primary-action btn-apply-filters" title="Aplicar filtros" onClick={this.handleFilterApply}>
                Aplicar
              </button> */}
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer className="sm-filter-footer">
          <div>
            <button className="btn-link-action" title="Resetar filtros">
              Limpar Filtros
            </button>
            <button className="btn-primary-action btn-apply-filters" title="Aplicar filtros">
              Aplicar
            </button>
          </div>
        </Modal.Footer> */}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FilterModal);
