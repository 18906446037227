import React from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Redirect} from 'react-router-dom';
import Api from '../../services/api';

import Footer from '../../components/Footer';

import divaBranco from '../../assets/Images/logo-branco.png';
import divaRoxo from '../../assets/Images/logo.png';

import Form from 'react-bootstrap/Form';
import {Diversos} from '../../services/diversos';

interface StateProps {
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps & any;

class ResetSenha extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      isLoading: false,
      email: this.props.location.state && this.props.location.state.email ? this.props.location.state.email : '',
      hasError: false,
      hasErrorTitle: '',
      hasErrorMsg: '',
      testehasError: true,
    };
  }

  private async handleSubmit() {
    const self = this;

    if (!self.state.email) {
      self.setMsg('error', 'Atenção', 'Email não informado para recuperação de senha');
      return false;
    }

    if (!Diversos.validateEmail(self.state.email)) {
      self.setMsg('error', 'Atenção', 'Informe um email para recuperação de senha');
      return false;
    }

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    let param = {email: self.state.email};

    try {
      const {data} = await self.api.post('/customer/reset-senha', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg('success', 'Sucesso', 'Enviamos uma senha provisória para seu e-mail. Você poderá utilizá-la para fazer Login');
      }
    } catch (e) {
      console.error(e);

      self.setMsg('error', 'Atenção', `Não foi possível recuperar senha. ${e.message}`);
    } finally {
      self.setState(
        {
          isLoading: false,
        },
        () => {
          setTimeout(() => (window.location.pathname = 'login'), 3000);
        },
      );
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADORS
   * @param type
   * @param title
   * @param msg
   * @param form
   */
  private async setMsg(type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      self.setState(
        {
          hasError: true,
          hasErrorTitle: title,
          hasErrorMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasError: false}), timeout);
        },
      );
    } else {
      self.setState(
        {
          hasSuccess: true,
          hasSuccessTitle: title,
          hasSuccessMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasSuccess: false}), timeout);
        },
      );
    }
  }

  render() {
    const smDevice = window.innerWidth <= 576;

    if (!this.props.user || this.props.user.status === false) {
      return (
        <>
          {this.state.hasError && (
            <div className="alert-box error-box">
              <p className="login-error-title"> {` - ${this.state.hasErrorTitle} - `} </p>
              <p className="alert-msg"> {this.state.hasErrorMsg} </p>
            </div>
          )}

          {this.state.hasSuccess && (
            <div className="alert-box success-box">
              <p className="login-success-title"> {` - ${this.state.hasSuccessTitle} - `} </p>
              <p className="alert-msg"> {this.state.hasSuccessMsg} </p>
            </div>
          )}

          <section className="user-pages recover-pswd">
            <div className="form-bg" style={{flexDirection: this.state.isLoading ? 'column' : 'row', alignItems: this.state.isLoading ? 'center' : ''}}>
              <a href="/">
                {' '}
                <img src={smDevice ? divaRoxo : divaBranco} alt="Logo Depimiel" />{' '}
              </a>
              {this.state.isLoading ? (
                <div className="d-flex w-100 mt-5" style={{alignItems: 'center', justifyContent: 'center'}}>
                  <i className="fas fa-spin fa-spinner fa-3x"></i>
                </div>
              ) : (
                <>
                  <article className="user-form-container reset-pswd">
                    <h1> Recuperação de Senha </h1>
                    <p> Insira seu endereço de e-mail e enviaremos a você uma senha temporária. </p>
                    <Form className="d-flex w-100 mt-5">
                      {/* <Form.Row> */}
                      <Form.Group className="w-100">
                        <Form.Label> Seu Email </Form.Label>
                        <Form.Control type="email" placeholder="Email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} />
                      </Form.Group>
                      {/* </Form.Row> */}
                    </Form>
                    <button className="btn-primary-action btn-user" title="Fazer Login" onClick={this.handleSubmit.bind(this)} disabled={this.state.isLoading}>
                      {this.state.isLoading ? 'Recuperando senha...' : 'Recuperar'}
                    </button>
                  </article>
                </>
              )}
            </div>
            <h3>
              {' '}
              Voltar para o <a href="/login"> Login </a>{' '}
            </h3>
          </section>

          <Footer />
        </>
      );
    } else {
      return <Redirect to="/meu-cadastro#trocar-senha" />;
    }
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ResetSenha);
