import React from 'react';
import './index.scss';

import Api from '../../services/api';
import { Diversos } from '../../services/diversos';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class NoMatchPage extends React.Component<{}> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    
    this.api = new Api();

    this.state = {
      isLoading: true,
      menu: null
    }
  }

  async componentDidMount() {
    const self = this;

    self.setState({ isLoading: true });

    try {
      const { data } = await self.api.get('/menu/nivel1');

      if ( data.status === false ) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];
        let aux2: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
            menu2: data.msg[i].menu2,
          });

          aux2.push({
            id: i,
            name: Diversos.capitalize(data.msg[i].DESCRICAO),
            to: "/departamento/" + Diversos.toSeoUrl(data.msg[i].DESCRICAO),
          });
        }

        self.setState({ menu: aux });
      }

    } catch (e) {
        console.error(e);
        self.setState({ menu: null });
      } finally {
        self.setState({ isLoading: false });
      }
  }

  render () {
    return (
      <>
        <Header/>
        <section className="no-match-page">
          <main className="no-match">
            <p> OOOPS! </p>
            <h1> A página que você está procurando não existe :( </h1>

            <aside className="no-match">
              <h4> Dicas: </h4>
              <ul>
                <li> Verifique se a palavra/termo buscado foi digitado corretamente </li>
                <li> Tente buscar por palavras-chave </li>
                <li> Tente buscar por sinônimos ou palavras similares </li>
              </ul>

              <h6> Navegue através dos departamentos </h6> 
              {
                (this.state.isLoading) ?
                  <ul className="no-match-menu">
                    <li> <FontAwesomeIcon icon="spinner" spin /> </li>
                  </ul>
                  :
                  ((this.state.menu) && (this.state.menu.length > 0)) ?
                    <ul className="no-match-menu">
                      {
                        this.state.menu.slice(0,6).map((row, index) => (
                          <li key={index}>
                            <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} target="_self">
                              {Diversos.capitalize(row.descricao)}
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                    :
                    null
              }
            </aside>
          </main>
        </section>

        <Footer/>
      </>
    );
  }
}

export default NoMatchPage;