import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {RadioGroup, RadioButton} from 'react-radio-buttons';
import InputMask from 'react-input-mask';
import ReactGA from 'react-ga';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {OrderType, ItemType} from '../../store/ducks/order/types';
import * as OrderActions from '../../store/ducks/order/actions';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import {Button, Row, Col, Card, Accordion, Form, Spinner, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faHandsHelping, faMapMarkerAlt, faTag, faTruck, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {faShoppingCart} from '@fortawesome/free-solid-svg-icons';
import CreditCard from '@bit/vitorbarbosa19.ziro.credit-card';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import Lottie from 'react-lottie';
import './index.scss';

import processingGif from '../../assets/animations/processing.json';
import noBrand from '../../assets/Images/card-brand.png';
import visa from '../../assets/Images/visa.png';
import master from '../../assets/Images/mastercard.png';
import amex from '../../assets/Images/amex.png';
import swal from 'sweetalert';

declare var window: any;
declare var document: any;

interface StateProps {
  user: User;
  param: Param;
  cart: Cart;
  order: OrderType;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
  drop(rowid: string): void;
  setOrder(data: OrderType): void;
  clean(): void;
  setCupom(param: string): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class CheckoutPagamento extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      isLoadingCustomer: true,
      hasErrorCustomer: false,
      hasErrorTitleCustomer: '',
      hasErrorMsgCustomer: '',
      customer: null,

      isLoadingFrete: false,
      hasErrorFrete: false,
      hasErrorTitleFrete: '',
      hasErrorMsgFrete: '',
      freteOpcoes: [],

      formFormaEntregaCodigo: null,
      formFormaEntregaPreco: null,
      formFormaEntregaPrazo: null,
      formFormaEntregaNome: null,

      formFormaPgtoCodigo: 0,

      formCartaoNumero: null,
      formCartaoNome: null,
      formCartaoValidadeMes: null,
      formCartaoValidadeAno: null,
      formCartaoCvv: null,
      formCartaoCvvSize: null,
      formCartaoBandeira: null,

      formIsLoading: false,
      formHasError: false,
      formHasErrorTitle: '',
      formHasErrorMsg: '',
      setErrorMsg: true,

      pagseguroSenderHash: null,
      cupomDesc: this.props.cart.cupomSelected ? true : false,
      codigoDesc: this.props.cart.cupomSelected ? this.props.cart.cupomSelected : null,
      changAddress: false,

      instalments: [],
      isLoadingInstalment: false,
      parcelas: 1,
    };
  }

  componentDidMount() {
    const self = this;
    if (!self.props.user || self.props.user.status === false) {
      self.setState({redirect: '/login'});
    }

    if (!self.props.cart.produtos || self.props.cart.produtos.length <= 0) {
      self.setState({redirect: '/checkout'});
    }

    if (this.props.cart.cupomSelected) {
      this.handleCupomDesc();
    }

    self.getCustomer();
  }

  private async getCustomer() {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if (data.status === false) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
        self.getShippingModes();
      }
    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        hasErrorCustomer: true,
        hasErrorTitleCustomer: 'Cadastro não localizado',
        hasErrorMsgCustomer: e.message,
      });
    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async getShippingModes() {
    const self = this;

    self.setState({isLoadingFrete: true});

    let peso = 0.0;
    let produtosCarrinho: any = [];
    let produtos: any = [];

    for (let i = 0; i < self.props.cart.produtos.length; i++) {
      peso += self.props.cart.produtos[i].peso * self.props.cart.produtos[i].qty;

      produtos.push(self.props.cart.produtos[i].codigo);

      produtosCarrinho.push({
        codigo: self.props.cart.produtos[i].codigo,
        qtd: self.props.cart.produtos[i].qty,
      });
    }

    try {
      let param = {
        cep: self.state.customer.cep,
        peso,
        valorTotal: self.getCartTotal(),
        produtosCarrinho,
        produtos,
      };

      const {data} = await self.api.post(`/shipping/modes`, param);

      if (data.status === false) {
        throw new Error('Não foi possível buscar opções de entrega.');
      } else {
        self.setState({freteOpcoes: data.msg}, () => {
          if (self.props.cart.freteSelected) {
            self.handleChangeFreteModo(self.props.cart.freteSelected);
          }
        });
      }
    } catch (e) {
      console.error(e);

      self.setState({
        freteOpcoes: null,
        hasErrorFrete: true,
        hasErrorTitleFrete: 'Cadastro não localizado',
        hasErrorMsgFrete: e.message,
      });
    } finally {
      self.setState({isLoadingFrete: false});
    }
  }

  private handleChangeFreteModo(nome) {
    const self = this;

    if (nome) {
      for (var i = 0; i < self.state.freteOpcoes.length; i++) {
        if (self.state.freteOpcoes[i].nome === nome) {
          let prazo = self.state.freteOpcoes[i].prazoMax ? self.state.freteOpcoes[i].prazoMax : self.state.freteOpcoes[i].prazo;

          self.setState(
            {
              formFormaEntregaCodigo: self.state.freteOpcoes[i].codigo,
              formFormaEntregaNome: self.state.freteOpcoes[i].nome,
              formFormaEntregaPreco: self.state.freteOpcoes[i].preco,
              formFormaEntregaPrazo: prazo,
            },
            self.getInstalments.bind(this),
          );

          break;
        }
      }
    }
  }

  private async getCardBrand(cardBin) {
    const self = this;

    let param = {bin: cardBin};

    try {
      const {data} = await self.api.post('/order/card/brand', param);

      if (data.status !== true) throw new Error('Não foi possível buscar bandeira do cartão');
      else
        this.setState({
          formCartaoBandeira: data.msg.brand.name,
          formCartaoCvvSize: parseInt(data.msg.cvvSize),
        });
    } catch (e) {
      console.error(`Falha na busca da bandeira do cartao:`, e);
    }
  }

  private getCardBrandImg() {
    const self = this;
    switch (self.state.formCartaoBandeira) {
      case 'visa':
        return visa;
      case 'mastercard':
        return master;
      case 'amex':
        return amex;
      default:
        return noBrand;
    }
  }

  private getCartTotal() {
    const self = this;
    let total = 0.0;
    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private async handleForm(event) {
    const self = this;

    event.preventDefault();

    if (self.getCartTotal() <= 0) {
      self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Valor total do carrinho inválido.'});
      return 0;
    }

    if (self.props.cart.produtos.length <= 0) {
      self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Seu carrinho está vazio.'});
      return 0;
    }

    if (!self.state.formFormaEntregaCodigo || !self.state.formFormaEntregaNome) {
      self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Opção de entrega não selecionada.'});
      return 0;
    }

    if (!self.state.customer.rua || !self.state.customer.cep || !self.state.customer.cidade) {
      self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Endereço de entrega não é válido.'});
      return 0;
    }

    if (self.state.formFormaPgtoCodigo < 1 || self.state.formFormaPgtoCodigo > 4) {
      self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Forma de pagamento selecionada não é válida.'});
      return 0;
    }

    if (self.state.formFormaPgtoCodigo === 1) {
      if (!self.state.formCartaoNumero || !self.state.formCartaoNome || !self.state.formCartaoValidadeAno || !self.state.formCartaoValidadeMes || !self.state.formCartaoCvv) {
        self.setState({formIsLoading: false, formHasError: true, formHasErrorMsg: 'Dados do cartão estão incompletos.'});
        return 0;
      }
    }

    self.setState({formIsLoading: true});

    let parcelas = !self.state.parcelas || self.state.parcelas <= 0 ? 1 : typeof self.state.parcelas === 'string' ? parseInt(self.state.parcelas) : self.state.parcelas;

    if (self.state.formFormaPgtoCodigo !== 1) {
      parcelas = 1;
    }

    let desconto = self.state.codigoDesc && self.state.valorDesc ? self.state.valorDesc : 0.0;

    let param = {
      cliente: self.state.customer.codigo,
      items: [],
      formapg: self.state.formFormaPgtoCodigo,
      desconto: desconto,
      cupom: this.state.codigoDesc ? this.state.codigoDesc : null,
      frete: {
        servico: self.state.formFormaEntregaNome,
        prazo: self.state.formFormaEntregaPrazo,
        valor: self.state.formFormaEntregaPreco,
      },
      total: self.getCartTotal() - desconto + self.state.formFormaEntregaPreco,
      cartao: [
        {
          numero: '',
          validade: '',
          cvv: '',
          salva: false,
          parcelas: parcelas,
          valor: self.getCartTotal() - desconto + self.state.formFormaEntregaPreco,
        },
      ],
      pagseguroSenderHash: self.state.pagseguroSenderHash,
      clearSaleSession: document.getElementById('SessaoUsuario').value,
    };

    if (self.state.formFormaPgtoCodigo === 1) {
      param.cartao[0].numero = Diversos.getnums(self.state.formCartaoNumero);
      param.cartao[0].validade = `${self.state.formCartaoValidadeMes}/${self.state.formCartaoValidadeAno}`;
      param.cartao[0].cvv = Diversos.getnums(self.state.formCartaoCvv);
    }

    let tmpProdutos: any = [];
    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      tmpProdutos.push({
        codigo: self.props.cart.produtos[i].codigo,
        quantidade: self.props.cart.produtos[i].qty,
        valor: self.props.cart.produtos[i].price,
      });
    }
    param.items = tmpProdutos;

    try {
      const {data} = await self.api.post('/order', param);

      if (data.status !== true) {
        throw new Error(data.msg);
      } else {
        // self.sendGoogleAnalyticsEcommerce( data.msg.PEDIDO );

        let tmpProdu: Array<ItemType> = [];
        for (var i = 0; i < data.msg.itens.length; i++) {
          tmpProdu.push({
            produto: data.msg.itens[i].produto,
            valor: data.msg.itens[i].valor,
            qtd: data.msg.itens[i].qtd,
            nome: data.msg.itens[i].nome,
          });
        }

        let tmpOrder = {
          pix: data.msg.pix ? data.msg.pix : null,
          cliente: data.msg.cliente,
          data: data.msg.data,
          status: data.msg.status,
          entrega: data.msg.entrega,
          dtentrega: data.msg.dtentrega,
          frete: data.msg.frete,
          formapg: data.msg.formapg,
          hora: data.msg.hora,
          pedido: data.msg.PEDIDO,
          desconto: data.msg.desconto,
          boleto_url: data.msg.BOLETO_URL,
          itens: tmpProdu,
          cartao: {
            numero: null,
            bandeira: null,
            parcelas: parcelas,
          },
        };

        if (self.state.formFormaPgtoCodigo === 1) {
          tmpOrder.cartao.numero = self.state.formCartaoNumero.substring(self.state.formCartaoNumero.length - 4);
          tmpOrder.cartao.bandeira = self.state.formCartaoBandeira;
        }

        await self.props.setOrder(tmpOrder);

        // await self.props.clean(); // limpa carrinho

        self.props.setCupom('');

        setTimeout(() => {
          window.location.href = '/checkout/fim';
        }, 200);
      }
    } catch (e) {
      console.error(e);
      self.setState({});
      self.setState({formHasError: true, formHasErrorMsg: e.message, formIsLoading: false});
    }
  }

  handleErrorComunication() {
    window.scrollTo(0, 0);
  }

  dismissAlert = (setErrorMsg) => {
    this.setState({formHasError: setErrorMsg});
  };

  async handleCupomDesc() {
    const self = this;

    let produtos: any = [];

    for (let i = 0; i < self.props.cart.produtos.length; i++) {
      produtos.push({
        codigo: self.props.cart.produtos[i].codigo,
        qtd: self.props.cart.produtos[i].qty,
      });
    }

    let param = {
      id: self.state.codigoDesc,
      cpf: self.props.user && self.props.user.status && self.props.user.cpf ? self.props.user.cpf : '',
      produtos,
    };

    self.setState({isLoadingCupom: true});

    try {
      const {data} = await self.api.post(`/cupom/${self.state.codigoDesc}/validate`, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        let desconto = 0.0;

        if (data.msg.FGFRETEGRATIS === 'S') desconto = self.state.formFormaEntregaPreco;
        else if (data.msg.PERCENTUAL > 0) desconto = (data.msg.PERCENTUAL / 100) * self.getCartTotal();
        else if (data.msg.VALOR > 0) desconto = data.msg.VALOR;

        desconto = Math.trunc(desconto * 100) / 100;

        self.setState(
          {
            cupomDesc: true,
            valorDesc: desconto,
          },
          () => {
            self.props.setCupom(self.state.codigoDesc);
            self.getInstalments();
          },
        );
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      if (e.message.indexOf('E_MISSING_DATABASE_ROW') > -1) {
        swal('Atenção', 'Cupom não é válido', 'error');
      } else {
        swal('Atenção', e.message, 'error');
      }
    } finally {
      self.setState({isLoadingCupom: false});
    }
  }

  private getTotal() {
    const self = this;

    let totalProdutos = self.getCartTotal();
    let totalFrete = !self.state.formFormaEntregaPreco ? 0.0 : self.state.formFormaEntregaPreco;
    let totalDesconto = !self.state.valorDesc ? 0.0 : self.state.valorDesc;

    return totalProdutos + totalFrete - totalDesconto;
  }

  private sendGoogleAnalyticsEcommerce(pedido: any) {
    const self = this;

    if (process.env.REACT_APP_ENVIROMENT === 'development') {
      return true;
    }
    /*
    ReactGA.initialize('UA-191012100-1');

    ReactGA.plugin.require('ecommerce');

    ReactGA.pageview( window.location.pathname, window.location.pathname );

    ReactGA.plugin.execute('ecommerce', 'addTransaction', {
      id: pedido,
      affiliation: 'Diva Cosmeticos',
      revenue: self.getTotal(),
      shipping: !self.state.formFormaEntregaPreco ? 0.00 : self.state.formFormaEntregaPreco,
      tax: 0.00
    });

    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      ReactGA.plugin.execute('ecommerce', 'addItem', {
        id: pedido,
        name: self.props.cart.produtos[i].nome,
        sku: self.props.cart.produtos[i].codigo,
        category: '',
        price: self.props.cart.produtos[i].price,
        quantity: self.props.cart.produtos[i].qty
      });
    }

    ReactGA.plugin.execute('ecommerce', 'send', {});
    ReactGA.plugin.execute('ecommerce', 'clear', {});
    */
  }

  private async getInstalments() {
    const self = this;

    self.setState({isLoadingInstalment: true});

    let param = {valor: self.getTotal()};

    try {
      const {data} = await self.api.post(`/order/instalments`, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setState({instalments: data.msg});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({instalments: []});
    } finally {
      self.setState({isLoadingInstalment: false});
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const mdDevice = window.innerWidth <= 992;

    return (
      <>
        <Header />

        <section className="checkout-pagamento-container mx-auto mb-5">
          <span className="checkout-title">
            <h1> Checkout </h1>
            <h2>
              {' '}
              <FontAwesomeIcon icon={faHandsHelping} /> Pagamento | Entrega{' '}
            </h2>
            <a href="/" target="_self" className="btn btn-link-action" style={{paddingLeft: 0}}>
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2 text-link" />
              Continuar comprando
            </a>
          </span>

          {/* {
            this.state.formHasError &&

            <Alert variant="danger" onClick={() => this.dismissAlert(false)} dismissible>
              <Alert.Heading>{this.state.formHasErrorTitle}</Alert.Heading>
              <p> {this.state.formHasErrorMsg} </p>
            </Alert>
          } */}

          {this.state.formHasError && (
            <Alert className="alert-box error-box" onClick={() => this.dismissAlert(false)} style={{borderRadius: 0}} dismissible>
              <p className="login-error-title"> {` - ${this.state.formHasErrorTitle || 'Erro'} - `} </p>
              <p className="alert-msg"> {this.state.formHasErrorMsg} </p>
            </Alert>
          )}

          {this.state.hasError ? (
            <Row>
              <Col>
                <div className="alert alert-danger">
                  <strong>{this.state.hasErrorTitle}</strong> <br />
                  <p>{this.state.hasErrorMsg}</p>
                </div>
              </Col>
            </Row>
          ) : this.state.isLoading ? (
            <p> Carregando ... </p>
          ) : (
            <>
              <Row>
                <Col xs={12} lg={4} style={{marginBottom: window.innerWidth <= 992 ? '2rem' : ''}}>
                  <Card className="cart-card-wrapper">
                    <Card.Header>
                      {' '}
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="cart-item-action mr-2" /> Endereço de entrega{' '}
                    </Card.Header>
                    <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                      {this.state.isLoadingCustomer ? (
                        <Card.Text className="text-center m-auto">
                          <Spinner animation="border" role="status" variant="secondary">
                            <span className="sr-only">Buscando endereço de entrega...</span>
                          </Spinner>
                        </Card.Text>
                      ) : !this.state.customer || !this.state.customer.cep || !this.state.customer.rua || !this.state.customer.cidade ? (
                        <>
                          <div className="alert alert-warning">
                            <FontAwesomeIcon icon="exclamation-circle" className="mr-2" size="sm" />
                            Endereço de entrega não localizado!
                          </div>
                          <a
                            href="/editar/endereco?back=checkout"
                            target="_self"
                            className="btn btn-secondary-action btn-checkout-actions"
                            style={{marginTop: 'auto', minHeight: 48}}
                          >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2 font-size-16" />
                            Cadastrar endereço
                          </a>
                        </>
                      ) : (
                        <>
                          <Card.Text style={{fontSize: 14}}>
                            {`${this.state.customer.rua}, ${this.state.customer.numero}`} <br />
                            {this.state.customer.complemento && `${this.state.customer.complemento}`} <br />
                            {`${this.state.customer.bairro}`} <br />
                            {`${this.state.customer.cidade} - ${this.state.customer.estado}`} <br />
                            {`${this.state.customer.cep}`}
                          </Card.Text>
                          <a href="/editar/endereco?back=checkout" className="btn btn-checkout-actions btn-outlined-action" style={{marginTop: 'auto', minHeight: 48}}>
                            {' '}
                            Alterar endereço{' '}
                          </a>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} lg={4} style={{marginBottom: window.innerWidth <= 992 ? '2rem' : ''}}>
                  <Card className="cart-card-wrapper">
                    <Card.Header>
                      <FontAwesomeIcon icon={faTruck} className="cart-item-action mr-2" /> Opções de entrega
                    </Card.Header>
                    <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                      {this.state.isLoadingFrete ? (
                        <Card.Text className="text-center m-auto">
                          <Spinner animation="border" role="status" variant="secondary">
                            <span className="sr-only"> Buscando endereço de entrega... </span>
                          </Spinner>
                        </Card.Text>
                      ) : this.state.freteOpcoes !== null && this.state.freteOpcoes.length > 0 ? (
                        <>
                          <RadioGroup onChange={this.handleChangeFreteModo.bind(this)} className="opcoes-de-entrega">
                            {this.state.freteOpcoes.map((row, index) => (
                              <RadioButton
                                value={row.nome || ''}
                                key={index}
                                padding={10}
                                className="opcao-de-entrega"
                                rootColor={'#AFAFAF'}
                                pointColor="#6950a2"
                                checked={this.state.formFormaEntregaNome ? row.nome === this.state.formFormaEntregaNome : row.nome === this.props.cart.freteSelected}
                              >
                                <strong>{row.nome}</strong> <br />
                                <small>
                                  Prazo: {row.prazo} {row.prazoMax ? ` até ${row.prazoMax}` : ''} | Preço: R$ {Diversos.number_format(row.preco, 2, ',', '')}
                                </small>
                              </RadioButton>
                            ))}
                          </RadioGroup>
                        </>
                      ) : (
                        <div className="alert alert-warning">
                          <FontAwesomeIcon icon="exclamation-circle" className="mr-2" size="sm" />
                          Nenhuma opção de entrega encontrada.
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} lg={4}>
                  <Card className="cart-card-wrapper">
                    <Card.Header>
                      {' '}
                      <FontAwesomeIcon icon={faShoppingCart} className="cart-item-action mr-2" /> Itens da compra{' '}
                    </Card.Header>
                    <Card.Body style={{display: 'flex', flexDirection: 'column'}}>
                      <Card.Title className="products-added">{this.props.cart.produtos.length} item(s) adicionado(s)</Card.Title>
                      <Card.Text style={{fontSize: 14}}>
                        <Row>
                          <Col className="text-left checkout-item-price">Subtotal:</Col>
                          <Col className="text-right checkout-item-price">R$ {Diversos.number_format(this.getCartTotal(), 2, ',', '')}</Col>
                        </Row>
                        <Row>
                          <Col className="text-left checkout-item-price">Frete:</Col>
                          <Col className="text-right checkout-item-price">
                            {this.state.formFormaEntregaPreco !== null ? `R$ ${Diversos.number_format(this.state.formFormaEntregaPreco, 2, ',', '')}` : `--`}
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-left checkout-item-price">Desconto:</Col>
                          <Col className="text-right checkout-item-price">
                            {this.state.cupomDesc && this.state.valorDesc && this.state.valorDesc > 0 ? (
                              <p> - {`R$ ${Diversos.number_format(this.state.valorDesc, 2, ',', '')}`} </p>
                            ) : (
                              <p>0.00</p>
                            )}
                          </Col>
                        </Row>

                        <Row style={{fontSize: 18, borderTopWidth: 1, borderTopColor: 'red'}} className="mt-4">
                          <Col className="text-left checkout-item-price">
                            <strong> TOTAL: </strong>
                          </Col>
                          <Col className="text-right checkout-item-price">
                            <strong>R$ {Diversos.number_format(this.getTotal(), 2, ',', '')}</strong>
                          </Col>
                        </Row>
                      </Card.Text>

                      <Form>
                        <Form.Group as={Col} className={`px-0 mt-3 mb-${mdDevice ? '5' : '3'}`}>
                          <Form.Label> Insira seu cupom </Form.Label>
                          <InputGroup>
                            {/*{*/}
                            {/*  this.state.isLoadingCupom ?  */}
                            {/*  <FontAwesomeIcon icon={faSpinner} spin={true} className="pswd-addon"/>*/}
                            {/*  :*/}
                            {/*  <FontAwesomeIcon icon={faTag} className="pswd-addon" onClick={this.handleCupomDesc.bind(this)}/>*/}
                            {/*}*/}
                            <Form.Control
                              type="text"
                              placeholder="Codigo do cupom"
                              value={this.state.codigoDesc || ''}
                              disabled={this.state.isLoadingCupom}
                              onChange={(event) => this.setState({codigoDesc: event.target.value})}
                              // onBlur={this.handleCupomDesc.bind(this)}
                              onKeyPress={(event) => {
                                if (event.charCode === 13) {
                                  event.preventDefault();
                                  this.handleCupomDesc();
                                }
                              }}
                            />
                            <InputGroup.Append>
                              {this.state.isLoadingCupom ? (
                                <Button variant={'success'} disabled={true}>
                                  <FontAwesomeIcon icon={faSpinner} spin={true} className="pswd-addon" />
                                </Button>
                              ) : this.state.cupomDesc && this.state.valorDesc && this.state.valorDesc > 0 ? (
                                <Button variant={'success'} disabled={true}>
                                  Aplicado!
                                </Button>
                              ) : (
                                <Button variant={'outline-primary'} onClick={this.handleCupomDesc.bind(this)}>
                                  Aplicar
                                </Button>
                              )}
                            </InputGroup.Append>
                          </InputGroup>
                        </Form.Group>
                      </Form>

                      <a href="/checkout" target="_self" className="btn btn-checkout-actions btn-outlined-action" style={{marginTop: 'auto', minHeight: 48}}>
                        {' '}
                        Visualizar itens{' '}
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col>
                  <Card>
                    <Card.Header className="title-forma-pg">
                      <FontAwesomeIcon icon="shopping-cart" className="mr-2" /> Selecione a forma de pagamento
                    </Card.Header>
                    <Accordion>
                      <Card className="border-0">
                        <Card.Header className="checkout-accordion-header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                            onClick={() => this.setState({formFormaPgtoCodigo: 1})}
                            className={this.state.formFormaPgtoCodigo === 1 ? 'selected-option label-forma-pg' : 'label-forma-pg'}
                          >
                            <FontAwesomeIcon icon="credit-card" className="mr-2" />
                            Cartão de crédito
                          </Accordion.Toggle>
                          {this.state.formFormaPgtoCodigo === 1 && <small className="selected-option">(selecionado)</small>}
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Row>
                              <Col xs={12} lg={6}>
                                <Form method="post" action="#" onSubmit={this.handleForm.bind(this)}>
                                  <Row>
                                    <Col>
                                      <h4 className="pull-right color-primaryHover">Total: R$ {Diversos.number_format(this.getTotal(), 2, ',', '')}</h4>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoNumero">
                                        <Form.Label>Número</Form.Label>
                                        <InputMask
                                          mask="9999 9999 9999 9999"
                                          maskPlaceholder="_"
                                          alwaysShowMask={false}
                                          name="cartao-numero"
                                          onChange={(event) => {
                                            this.setState({formCartaoNumero: event.target.value});
                                            if (Diversos.getnums(event.target.value).length === 6) {
                                              this.getCardBrand(event.target.value);
                                            } else if (Diversos.getnums(event.target.value).length < 6) {
                                              this.setState({formCartaoBandeira: null});
                                            }
                                          }}
                                        >
                                          {(inputProps) => <Form.Control {...inputProps} type="text" />}
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={12} sm={12}>
                                      <Form.Group controlId="formPagamentoCartaoNome">
                                        <Form.Label>Nome (como no cartão)</Form.Label>
                                        <Form.Control type="text" placeholder="" name="cartao-nome" onChange={(event) => this.setState({formCartaoNome: event.target.value})} />
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoMesValidade">
                                        <Form.Label>Mês validade</Form.Label>
                                        <InputMask
                                          mask="99"
                                          maskPlaceholder="_"
                                          alwaysShowMask={false}
                                          name="cartao-validade-mes"
                                          onChange={(event) => this.setState({formCartaoValidadeMes: event.target.value})}
                                        >
                                          {(inputProps) => <Form.Control {...inputProps} type="text" />}
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>

                                    <Col>
                                      <Form.Group controlId="formPagamentoCartaoAnoValidade">
                                        <Form.Label>Ano validade</Form.Label>
                                        <InputMask
                                          mask="2099"
                                          maskPlaceholder="_"
                                          alwaysShowMask={false}
                                          placeholder="AAAA"
                                          name="cartao-validade-ano"
                                          onChange={(event) => this.setState({formCartaoValidadeAno: event.target.value})}
                                        >
                                          {(inputProps) => <Form.Control {...inputProps} type="text" />}
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xs={5}>
                                      <Form.Group controlId="formPagamentoCartaoCvv">
                                        <Form.Label>Código de segurança</Form.Label>
                                        <InputMask
                                          mask={this.state.formCartaoCvvSize === 4 ? '9999' : '999'}
                                          maskPlaceholder="_"
                                          alwaysShowMask={false}
                                          placeholder=""
                                          name="cartao-cvv"
                                          onChange={(event) => this.setState({formCartaoCvv: event.target.value})}
                                        >
                                          {(inputProps) => <Form.Control {...inputProps} type="text" />}
                                        </InputMask>
                                        <Form.Text className="text-muted"></Form.Text>
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  {!this.state.isLoadingInstalment && this.state.instalments.length > 1 ? (
                                    <Row>
                                      <Col xs={5}>
                                        <Form.Group controlId="formPagamentoCartaoCvv">
                                          <Form.Label>Parcelas</Form.Label>
                                          <Form.Control as="select" name={'parcelas'} onChange={(event) => this.setState({parcelas: event.target.value})}>
                                            {this.state.instalments.map((row, index) => (
                                              <option value={row.value}>{row.label}</option>
                                            ))}
                                          </Form.Control>
                                          <Form.Text className="text-muted"></Form.Text>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  ) : (
                                    <input type={'hidden'} name={'parcelas'} value={1} />
                                  )}

                                  <Row>
                                    <Col>
                                      <button type="submit" className="btn-primary-action btn-block checkout-finalizar-compra" onClick={() => this.handleErrorComunication()}>
                                        Finalizar compra
                                      </button>
                                    </Col>
                                  </Row>
                                </Form>
                              </Col>
                              <Col xs={6} className="text-right microinteraction-card">
                                <CreditCard
                                  number={this.state.formCartaoNumero ? this.state.formCartaoNumero : ''}
                                  brand={this.state.formCartaoBandeira ? this.state.formCartaoBandeira : ''}
                                  cardholder={this.state.formCartaoNome ? this.state.formCartaoNome : ''}
                                  expiry={this.state.formCartaoValidadeMes ? `${this.state.formCartaoValidadeMes}/${this.state.formCartaoValidadeAno}` : ''}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="border-top border-bottom-0 border-right-0 border-left-0">
                        <Card.Header className="checkout-accordion-header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="3"
                            onClick={() => this.setState({formFormaPgtoCodigo: 4})}
                            className={this.state.formFormaPgtoCodigo === 4 ? 'selected-option label-forma-pg' : 'label-forma-pg'}
                          >
                            <FontAwesomeIcon icon="qrcode" className="mr-2" />
                            PIX
                          </Accordion.Toggle>
                          {this.state.formFormaPgtoCodigo === 3 && <small className="selected-option">(selecionado)</small>}
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                          <Card.Body>
                            <Form method="post" action="#" onSubmit={this.handleForm.bind(this)}>
                              <Row>
                                <Col>
                                  <h4 className="pull-right color-primaryHover">Total: R$ {Diversos.number_format(this.getTotal(), 2, ',', '')}</h4>
                                  <h5 className="important-info">Importante</h5>
                                  <ul className="topicos-boleto">
                                    <li>
                                      O QRCode expirará após 24 horas e seu pedido será cancelado automaticamente, sendo necessário refazer seu pedido - sujeito a
                                      alteração de valores.
                                    </li>
                                    <li>O QRCode e o Pix copia e cola será exibido logo após a sua confirmação de compra (na próxima tela).</li>
                                    <li>
                                      O pedido é aprovado instantaneamente após a realização do pagamento. O prazo para entrega do produto é contado a partir da aprovação.
                                    </li>
                                    <li>
                                      O pagamento do pix pode ser efetuado pela internet, utilizando o QRCode ou o pix copia e cola.
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <button type="submit" className="width-modifier btn btn-block checkout-finalizar-compra" onClick={() => this.handleErrorComunication()}>
                                    Finalizar compra e gerar Pix
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      <Card className="border-top border-bottom-0 border-right-0 border-left-0">
                        <Card.Header className="checkout-accordion-header">
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="1"
                            onClick={() => this.setState({formFormaPgtoCodigo: 2})}
                            className={this.state.formFormaPgtoCodigo === 2 ? 'selected-option label-forma-pg' : 'label-forma-pg'}
                          >
                            <FontAwesomeIcon icon="barcode" className="mr-2" />
                            Boleto bancário
                          </Accordion.Toggle>
                          {this.state.formFormaPgtoCodigo === 2 && <small className="selected-option">(selecionado)</small>}
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <Form method="post" action="#" onSubmit={this.handleForm.bind(this)}>
                              <Row>
                                <Col>
                                  <h4 className="pull-right color-primaryHover">Total: R$ {Diversos.number_format(this.getTotal(), 2, ',', '')}</h4>
                                  <h5 className="important-info">Importante</h5>
                                  <ul className="topicos-boleto">
                                    <li>
                                      O boleto expirará após data de vencimento e seu pedido será cancelado automaticamente, sendo necessário refazer seu pedido - sujeito a
                                      alteração de valores.
                                    </li>
                                    <li>O boleto será exibido logo após a sua confirmação de compra.</li>
                                    <li>
                                      O pedido é aprovado em até 2 dias úteis após a realização do pagamento. O prazo para entrega do produto é contado a partir da aprovação.
                                    </li>
                                    <li>
                                      O pagamento do boleto pode ser efetuado pela internet, utilizando o código de barras, ou diretamente em bancos, lotéricas e correios,
                                      apresentando o boleto impresso.
                                    </li>
                                  </ul>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <button type="submit" className="width-modifier btn btn-block checkout-finalizar-compra" onClick={() => this.handleErrorComunication()}>
                                    Finalizar compra
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </section>

        <Footer />

        <Modal show={this.state.formIsLoading} onHide={() => null} dialogClassName="payment-container">
          <div className="panel">
            <div className="panel-heading">Processando...</div>
            <div className="panel-body">
              <div className="mb-3">
                <Lottie
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: processingGif,
                  }}
                  // height={200}
                  width={300}
                  isStopped={false}
                  isPaused={false}
                />
              </div>
              <br />
              Estamos processando seu pagamento, por favor aguarde a mensagem de conclusão...
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
  order: state.order.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions, ...CartActions, ...OrderActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPagamento);
