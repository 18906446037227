import React from 'react';
import './index.scss';

import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Sobre from '../../components/Sobre';
import Lojas from '../../components/Lojas';
import Saloes from '../../components/Saloes';
import Televendas from '../../components/Televendas';
import Atendimento from '../../components/Atendimento';

import Loja1 from '../../assets/Images/loja-1.png';
import Loja2 from '../../assets/Images/loja-2.png';
import televendasImg from '../../assets/Images/televendas.png';
import DivaBanner from '../../assets/Images/DivaBanner.png';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faArrowDown, faArrowRight, faMapMarkerAlt, faPhone} from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Institucional extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const mdDevice = window.innerWidth <= 1200;
    const mainPage = window.location.pathname === '/institucional/sobre' || window.location.pathname === '/institucional';

    return (
      <>
        <Header />

        <section className="institucional">
          <Col lg={mdDevice ? 12 : 3} className="diva-content-wrap" style={{maxWidth: mdDevice ? '' : 320}}>
            <article className="filter-wrapper">
              <h3>
                {' '}
                <FontAwesomeIcon icon={faArrowDown} /> Institucional{' '}
              </h3>
              <ul className="filter-list">
                <li>
                  {' '}
                  <a href="sobre" className={mainPage ? 'link-active' : ''}>
                    {' '}
                    Sobre a Depimiel{' '}
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="http://blog.depimiel.com" target="_blank" rel="noopenar noreferrer">
                    {' '}
                    Blog{' '}
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="atendimento" className={window.location.pathname === '/institucional/atendimento' ? 'link-active' : ''}>
                    {' '}
                    Atendimento{' '}
                  </a>{' '}
                </li>
                {/* <li> <a href="/marcas"> Marcas </a> </li> */}
              </ul>
            </article>
            <aside className="canais-institucional">
              <a href="mailto:sac.br@depimiel.com.br" title="Entre em contato via E-mail" />
              {/* <a
                href="https://api.whatsapp.com/send?l=pt&phone=+55411111111&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%20depimiel.com.br"
                target="_blank"
                rel="noopener noreferrer"
                title="Entre em contato via WhatsApp"
              /> */}

              <h6> Canais de atendimento </h6>
              <div>
                <a className="canais-links" href="mailto:sac.br@depimiel.com.br" title="Entre em contato via E-mail">
                  <i className="far fa-envelope" />
                  <a> Via E-mail </a>
                </a>
                {/* <a
                  className="canais-links"
                  href="https://api.whatsapp.com/send?l=pt&phone=+55411111111&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%20depimiel.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Entre em contato via WhatsApp"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  <a
                    href="https://api.whatsapp.com/send?l=pt&phone=+55411111&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%depimiel.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Entre em contato via WhatsApp"
                  >
                    {' '}
                    Via WhatsApp{' '}
                  </a>
                </a> */}
              </div>
            </aside>
          </Col>
          <Col style={{maxWidth: 900, padding: mdDevice ? '0' : 'initial'}}>
            {mainPage ? (
              <Sobre />
            ) : window.location.pathname === '/institucional/lojas' ? (
              <Lojas />
            ) : window.location.pathname === '/institucional/saloes' ? (
              <Saloes />
            ) : window.location.pathname === '/institucional/televendas' ? (
              <Televendas />
            ) : window.location.pathname === '/institucional/atendimento' ? (
              <Atendimento />
            ) : null}
          </Col>
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Institucional);
