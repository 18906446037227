import React, {Component} from 'react';
import './index.scss';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

class Blog extends Component<{}> {
  state: any = null;

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <Header />
        <iframe src={'https://blog.depimiel.com/'} style={{borderWidth: 0, width: '100%', height: 'auto', minHeight: '100vh'}}></iframe>
        <Footer />
      </>
    );
  }
}

export default Blog;
