import React from 'react';
import './index.scss';

import Api from '../../services/api';
import {Param} from '../../store/ducks/param/types';
import {Diversos} from '../../services/diversos';

import atendimento from '../../assets/Images/atendimento.png';

import {Form} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp, faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons';

interface StateProps {
  param?: Param;
}

type Props = StateProps;

class Atendimento extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      email: null,
      nome: null,
      mensagem: null,

      hasError: false,
      hasErrorTitle: '',
      HasErrorMsg: '',

      hasSuccess: false,
      hasSuccessTitle: '',
      hasSuccessMsg: '',
    };
  }

  private async setMsg(type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      self.setState(
        {
          hasError: true,
          hasErrorTitle: title,
          hasErrorMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasError: false}), timeout);
        },
      );
    } else {
      self.setState(
        {
          hasSuccess: true,
          hasSuccessTitle: title,
          hasSuccessMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasSuccess: false}), timeout);
        },
      );
    }
  }

  private async handleContact(event) {
    const self = this;
    let param: any = {};

    event.preventDefault();

    if (!self.state.email || !self.state.nome || !self.state.mensagem) {
      self.setMsg('error', 'Formulário Incompleto', 'Preencha todos os dados do formulário antes de enviar');
      return;
    }

    if (!Diversos.validateEmail(self.state.email)) {
      self.setMsg('error', 'E-mail Inválido', 'Por favor, digite um E-mail válido para continuar');
      return;
    }

    param.email = self.state.email;
    param.nome = self.state.nome;
    param.mensagem = self.state.mensagem;

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.post('/contact', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg('success', 'Sucesso', `Mensagem enviada com sucesso.`);
      }
    } catch (e) {
      console.error(e);
      self.setMsg('error', 'Atenção', `Não foi possível enviar sua mensagem. ${e.message}`);
    } finally {
      self.setState({isLoading: false});
    }
  }

  render() {
    return (
      <>
        {this.state.hasError && (
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.hasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.hasErrorMsg} </p>
          </div>
        )}

        {this.state.hasSuccess && (
          <div className="alert-box success-box">
            <p className="login-success-title"> {` - ${this.state.hasSuccessTitle} - `} </p>
            <p className="alert-msg"> {this.state.hasSuccessMsg} </p>
          </div>
        )}

        <h1> Atendimento </h1>
        <p className="mb-4"> Preencha os campos abaixo e entre em contato conosco através do formulário. </p>
        <aside className="category-img-container">
          <img src={atendimento} alt="Banner Entre em contato" />
        </aside>
        <div className="contact-form">
          <Form method="post" action="#" onSubmit={this.handleContact.bind(this)}>
            <Form.Group>
              <Form.Label> Seu Nome </Form.Label>
              <Form.Control type="text" name={'nome'} placeholder="Nome" onChange={(event) => this.setState({nome: event.target.value})} />
            </Form.Group>
            <Form.Group>
              <Form.Label> Seu Email </Form.Label>
              <Form.Control type="email" name={'email'} placeholder="E-mail" onChange={(event) => this.setState({email: event.target.value})} />
            </Form.Group>
            <Form.Group>
              <Form.Label> Sua Mensagem </Form.Label>
              <Form.Control
                as="textarea"
                name={'mensagem'}
                type="text"
                maxLength={800}
                placeholder="Comece a digitar..."
                onChange={(event) => this.setState({mensagem: event.target.value})}
              />
            </Form.Group>
            <button type="submit" className="btn-primary-action" style={{minWidth: 160}}>
              {this.state.isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Enviar'}
            </button>
          </Form>
          <span className="contato-social">
            <p> Ou fale conosco através das nossas redes sociais! </p>
            <span>
              <a href="https://www.facebook.com/depimiel" target="_blank" rel="noopener noreferrer" className="contato-icon">
                {' '}
                <FontAwesomeIcon icon={faFacebookF} />{' '}
              </a>
              <a href="https://www.instagram.com/depimielbrasil/?hl=pt-br" target="_blank" rel="noopener noreferrer" className="contato-icon">
                {' '}
                <FontAwesomeIcon icon={faInstagram} />{' '}
              </a>
            </span>
          </span>
        </div>
      </>
    );
  }
}

export default Atendimento;
