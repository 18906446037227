import React from 'react';
import './index.scss';

import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import Televendas from '../../assets/Images/televendas.png';

import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class FAQ extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
    };
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <>
        <Header />

        <section className="central-de-relacionamento">
          <article className="faq-container">
            <h1> Central de Relacionamento </h1>
            <h3> Tópicos mais buscados </h3>
            <Tabs
              id="topicos-perguntas-frequentes"
              className="faq-tab-nav"
              defaultActiveKey="entregas"
              activeKey={`${this.props.param && this.props.param.faqTab}` || 'entregas'}
              onSelect={(key) => this.props.setParam({...this.props.param, faqTab: key})}
            >
              {/* CONSIDERACOES GERAIS */}
              <Tab eventKey="entregas" title="Condições gerais">
                <h4> CONDIÇÕES GERAIS </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> CONDIÇÕES GERAIS OBRIGATÓRIAS A SEREM OBSERVADAS EM QUALQUER CASO </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <ul>
                          <li>
                            <p>
                              O produto só será trocado ou cancelado se estiver em sua embalagem original, sem indícios de uso, sem violação do lacre original do fabricante e
                              acompanhado da Nota Fiscal. Em qualquer um dos casos somente haverá o aceite pela DEPIMIEL após análise destas condições. Essa análise será feita em
                              até 05 (cinco) dias úteis, contando a partir do recebimento do produto ou do pedido de desistência que poderá ocorrer até 7 dias após a compra. Você
                              pode trocar seu produto por um produto diferente ou receber reembolso pela mesma forma de pagamento que usou.
                            </p>
                          </li>
                          <li>
                            <p>
                              Produtos já utilizados: somente serão trocados se houver quantidade suficiente para a realização de análise técnica e a reclamação for procedente.
                              Observe, no entanto, as exceções da nossa política de troca e devolução: Itens com desconto são de venda final e não são passíveis de trocas ou
                              devoluções; Itens devolvidos devem ser devolvidos na embalagem original; Prazo para devolução por compra de produto incorreto é de até 30 dias
                              analisado caso a caso. Itens devolvidos não devem ter sinais visíveis de desgaste ou uso. Seus dados de envio devem estar corretos. Não nos
                              responsabilizamos pelo frete de reenvio no caso de: ausência no dia da entrega, restrição de local de entrega e, dados de entrega informados
                              incorretamente o cliente fica responsável pelo reenvio do pedido.
                            </p>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* ENTREGAS
              <Tab eventKey="entregas" title="Entregas">
                <h4> Entregas </h4>
                <Accordion defaultActiveKey="" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Qual é o prazo de entrega? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        O prazo de entrega varia de acordo com o CEP de sua residência e a opção de frete que você escolher. Quando realizar o seu pedido, ao selecionar “Finalizar
                        Compra”, você pode consultar os prazos e opções de fretes disponíveis para o seu endereço no carrinho. Você insere o CEP do seu endereço e serão exibidas
                        automaticamente as opções de frete disponíveis. O prazo de entrega começa a contar após a confirmação do pagamento do seu pedido e é calculado em dias úteis
                        .A sua entrega é calculada de acordo com o CEP fornecido e que encontra-se na finalização do seu pedido, logo abaixo do endereço de entrega.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      <h5> Quais são os tipos de entregas disponíveis? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="faq-content">
                        <b> Normal: </b> Modalidade para quem quer um preço mais em conta e não tem problema de esperar um pouquinho! Aqui o frete poderá ser grátis para compras
                        acima de um valor mínimo de compra e você poderá verificar na oferta do carrinho ao inserir seu CEP ou se você tiver algum cupom relacionado e aplicável
                        para o frete. Caso o seu pedido não atinja o valor mínimo para o frete grátis, o custo da entrega será calculado de acordo com o CEP do seu endereço de
                        entrega (taxas e serviços não estão inclusos nesse valor). <br />
                        <b> Expressa: </b> Ideal para quem quer receber logo as suas compras, esta opção de frete está disponível para algumas áreas do Brasil, por isso consulte as
                        o CEP do endereço desejado para a entrega na página "Sacola de Compras".
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="faq-content">
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      <h5> Qual é o valor do frete? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="faq-content">
                        O frete poderá ser grátis para compras acima de um valor mínimo de compra e você poderá verificar na oferta do carrinho ao inserir seu CEP. Se o seu pedido
                        não atingir o valor mínimo para o frete grátis, o custo da entrega será calculado de acordo com o CEP do seu endereço de entrega (taxas e serviços não estão
                        inclusos nesse valor). Assim, se você quiser que o seu pedido seja entregue ainda mais rápido do que nossa entrega normal, consulte as opções de frete
                        disponíveis parao seu CEP.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="faq-content">
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      <h5> Posso mudar o endereço de entrega? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body className="faq-content">
                        Se você já finalizou o pedido, o endereço não poderá ser alterado. Se ainda assim, precisar de ajuda, entre em contato com a gente e vamos te ajudar. Caso
                        queira mudar o endereço antes de fazer um pedido, basta fazer o login em sua conta e ir em “MEUS ENDEREÇOS” e adicionar ou excluir os endereços que desejar.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab> */}

              {/* TROCAS E DEVOLUCOES */}
              <Tab eventKey="trocas" title="Troca e Devolução">
                <h4> TROCA E DEVOLUÇÃO </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Política de trocas e devoluções </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        A Política de Troca e Devolução da Depimiel é baseada no Código de Defesa do Consumidor Todas as solicitações de troca e devolução deverão ser comunicadas
                        diretamente por e-mail para sac.br@depimiel.com
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* PEDIDOS */}
              <Tab eventKey="como_comprar" title="Como comprar">
                <h4> COMO COMPRAR </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Como realizar um pedido no site? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <ol>
                          <li>
                            Basta navegar entre as páginas de produtos (categorias, novidades, ofertas, destaques) e clicar sobre o ícone ‘Comprar’. Ou clique em Ver Detalhes para
                            maiores informações sobre o produto. Após isso o produto será inserido em seu carrinho de compras
                          </li>
                          <li>
                            Continue navegando em nosso site até escolher todos os produtos que você deseja adquirir. Agora basta clicar no link ‘Finalizar’ que você estará
                            concluindo sua compra.
                          </li>
                          <li>Informe seu e-mail e senha. Caso não seja cadastrado, cadastre-se rapidamente clicando no link de Cadastro.</li>
                          <li>Escolha a forma de envio do produto.</li>
                          <li>Escolha a forma de pagamento.</li>
                          <li>Demais instruções serão enviadas via e-mail para você após a finalização de sua venda.</li>
                        </ol>
                        <p>
                          <strong>IMPORTANTE</strong>
                          <br />
                          Na compra de mercadoria com o CNPJ (Cadastro Nacional da Pessoa Jurídica), de outra Unidade Federativa, a legislação determina ao Estado de destino a
                          permissão para promover a antecipação do recolhimento do Imposto sobre Circulação de mercadoria (ICMS), assim que a mercadoria chegar ao seu território
                          antes de ser encaminhada para seu destino final. A antecipação é uma particularidade do Estado do Destinatário, é devido de recolhimento pelo Destinatário
                          da mercadoria, independentemente de seu regime tributário
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* SEGURANCA */}
              <Tab eventKey="cadastro" title="Segurança">
                <h4> SEGURANÇA </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Segurança do site </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <p>
                          A DEPIMIEL não irá, em hipótese alguma, divulgar os seus dados pessoais de endereçamento, pagamento e conteúdo do pedido. Com relação à segurança no
                          tráfego de dados, toda transação que envolver pagamento, seja por cartão de crédito ou não, estará encriptada com a tecnologia SSL (Secure Socket Layer).
                          Isso significa que só a Depimiel terá acesso a estes dados.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* GARANTIA, TROCAS E DEVOLUÇÕES */}
              <Tab eventKey="servicos" title="Garantia, trocas e devoluções">
                <h4> GARANTIA, TROCAS E DEVOLUÇÕES </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> GARANTIA, TROCAS E DEVOLUÇÕES </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <p>
                          Todas as garantias são dadas pelos fabricantes, e variam de produto para produto. Elas serão informadas nas páginas de cada produto. Caso tenha alguma
                          dúvida, entre em contato conosco.
                        </p>
                        <p>Para devoluções, os produtos deverão ser remetidos em suas embalagens originais e caso for constatado mal-uso, o custo será por parte do comprador.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* FORMAS DE PAGAMENTO */}
              <Tab eventKey="prazo_formas" title="Prazo e formas de envio">
                <h4> PRAZOS E FORMAS DE ENVIO </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Prazos e formas de envio </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <p>O valor do frete é calculado na página de produtos e no carrinho de compras, pois esta informação depende do CEP de destino.</p>
                        <p>As entregas são realizadas pelos parceiros logísticos da Depimiel, sendo eles Correios e Transportadora Jadlog</p>
                        <p>A escolha da transportadora dependerá da região de entrega e do perfil da embalagem.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      <h5> Prazos de entrega </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="faq-content">
                        <p>
                          Para consultar o prazo de entrega basta você digitar seu CEP na página de produtos e no carrinho de compras. O prazo de entrega será contado após
                          confirmação dos seus dados cadastrais e confirmação do pagamento, sendo que a análise de dados cadastrais poderá levar até dois dias úteis.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      <h5> Ainda não recebi minha compra </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body className="faq-content">
                        <p>
                          Caso o prazo de entrega informado na compra esteja expirado, por favor, entre em contato com nosso SAC. Verificaremos junto ao nosso transportador por
                          qual motivo seu prazo foi comprometido, e retornaremos seu contato em até dois dias úteis.
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <p>
                    <strong>IMPORTANTE</strong>
                    É necessário que verifique de seus dados antes de finalizar o pedido Não nos responsabilizamos pelo frete de reenvio. Caso o pedido seja devolvido para nós,
                    devido à ausência no dia da entrega, restrição de local de entrega e, dados de entrega informados incorretamente o cliente fica responsável pelos custos de
                    reenvio do pedido.
                    <br />
                    Todos os produtos serão enviados de acordo com a forma escolhida pelo cliente, em até 3 dias úteis da confirmação do pagamento. O prazo para a entrega varia de
                    acordo com a forma de envio escolhida e não é de nossa responsabilidade, visto que esta fica a cargo dos Correios e de nossa transportadora parceira.
                  </p>
                </Accordion>
              </Tab>

              {/* FPEDIDOS */}
              <Tab eventKey="pedidos" title="Pedidos">
                <h4> PEDIDOS </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Como faço para acompanhar o meu pedido? </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <p>
                          Nas entregas por Correios você receberá um link no seu e-mail com o código de rastreio. Para envios via transportadora Jadlog enviaremos um e-mail, com o
                          link www.jadlog.com.br (através deste link, inserindo seus dados corretamente e o código de rastreio fornecido por nós poderá verificar todo o processo de
                          seu pedido. Restando alguma dúvida, você conta com nosso SAC para te auxiliar
                        </p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>

              {/* PAGAMENTO */}
              <Tab eventKey="pagamento" title="Pagamento">
                <h4> PAGAMENTO </h4>
                <Accordion defaultActiveKey="0" className="faq-accordion">
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      <h5> Pagamento </h5>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="faq-content">
                        <p>A forma de pagamento pode ser selecionada antes de finalizar a compra, podendo esta ser selecionada como cartão de crédito, boleto ou PIX.</p>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>
            </Tabs>
            <div className="faq-televendas">
              <img src={Televendas} alt="Televendas" />
              <span>
                <h3> Televendas </h3>
                <h6> 41 3666-6400 </h6>
                <p> Seg. à Sex. das 08h - 12h / 13h - 17h30min </p>
              </span>
            </div>
          </article>
          <aside className="canais-atendimento">
            <a href="mailto:sac.br@depimiel.com.br" title="Entre em contato via E-mail" />
            <h6> Canais de atendimento </h6>
            <div>
              <span>
                <i className="far fa-envelope" />
                <a> Via E-mail </a>
              </span>
              <span>
                <FontAwesomeIcon icon={faWhatsapp} />
                {/* <a
                  href="https://api.whatsapp.com/send?l=pt&phone=+554111111111&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%20shop.depimiel.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Entre em contato via WhatsApp (41) 11111111"
                >
                  {' '}
                  Via WhatsApp{' '}
                </a> */}
              </span>
            </div>
          </aside>
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
