import React, {Component} from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import {User} from '../../store/ducks/user/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Diversos} from '../../services/diversos';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import 'moment/locale/pt-br';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Selo from '../../components/Selo';
import Combo from '../../components/SeloCombo';
declare var window: any;

interface StateProps {
  cart?: Cart;
  param: Param;
  user?: User;
}

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {
  imageOver: any;
  imageNormal: any;
  item?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class ProductPromo extends Component<Props> {
  state: any;

  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.imageNormal,
      promocao: this.props.item && this.props.item.PROMOCAO && this.props.item.PROMOCAO[0] ? this.props.item.PROMOCAO[0] : null,
    };
  }

  componentDidMount() {}

  private async handleAddCartPromo(event) {
    const self = this;
    event.preventDefault();

    if (!self.props.item.PROMOCAO || self.props.item.PROMOCAO.length <= 0 || !self.props.item.PROMOCAO[0].CDPRODU1) {
      return window.alert('Nenhum promoção encontrada.');
    }

    let qtd = 0;
    let preco = 0;
    let precoOriginal = self.props.item.PRECO;
    let i = 1;

    while (self.state.promocao[`CDPRODU${i}`]) {
      qtd = self.state.promocao[`CDQTD${i}`];

      if (self.state.promocao[`CDDESC${i}`] > 0) {
        preco = precoOriginal - precoOriginal * (self.state.promocao[`CDDESC${i}`] / 100);
      } else if (self.state.promocao[`CDPRECO${i}`] > 0) {
        preco = self.state.promocao[`CDPRECO${i}`];
      }

      self.props.add({
        codigo: self.state.promocao[`CDPRODU${i}`],
        nome: self.state.promocao[`produto${i}`].NOME,
        priceOrigin: precoOriginal,
        price: preco > 0 ? preco : precoOriginal,
        qty: qtd,
        estoque: self.state.promocao[`produto${i}`].ESTOQUE,
        foto:
          !self.state.promocao[`produto${i}`].FOTOS || !self.state.promocao[`produto${i}`].FOTOS[0]
            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
            : `${process.env.REACT_APP_BASE_URL_PICTURE}/${self.state.promocao[`produto${i}`].FOTOS[0].link}`,
        peso: self.state.promocao[`produto${i}`].PESO,
        promo: self.state.promocao,
        qtdPromo: qtd,
      });

      i++;
    }

    self.props.setParam({...self.props.param, cartOpened: true});
  }

  render() {
    if (!this.props.item || !this.props.item.PROMOCAO || this.props.item.PROMOCAO.length <= 0) {
      return <></>;
    }

    let tipoPromo = 0;
    let preco1 = 0;
    let preco2 = 0;
    let preco3 = 0;
    let preco4 = 0;
    let preco5 = 0;
    let precoFinal = 0;

    if (this.state.promocao.CDPRODU1 && !this.state.promocao.CDPRODU2 && !this.state.promocao.CDPRODU3 && !this.state.promocao.CDPRODU4 && !this.state.promocao.CDPRODU5) {
      tipoPromo = 1;

      preco1 = this.state.promocao.produto1.PRECO;

      if (this.state.promocao.CDDESC1 > 0) preco1 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC1 / 100);
      else if (this.state.promocao.CDPRECO1 > 0) preco1 = this.state.promocao.CDPRECO1;
      else preco1 = this.state.promocao.produto1.PRECO;

      precoFinal = preco1 * this.state.promocao.CDQTD1;
    } else if (this.state.promocao.CDPRODU1 && this.state.promocao.CDPRODU2 && !this.state.promocao.CDPRODU3 && !this.state.promocao.CDPRODU4 && !this.state.promocao.CDPRODU5) {
      tipoPromo = 2;

      if (this.state.promocao.CDDESC1 > 0) preco1 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC1 / 100);
      else if (this.state.promocao.CDPRECO1 > 0) preco1 = this.state.promocao.CDPRECO1;
      else preco1 = this.state.promocao.produto1.PRECO;

      preco1 = preco1 * this.state.promocao.CDQTD1;

      if (this.state.promocao.CDDESC2 > 0) preco2 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC2 / 100);
      else if (this.state.promocao.CDPRECO2 > 0) preco2 = this.state.promocao.CDPRECO2;
      else preco2 = this.state.promocao.produto2.PRECO;

      preco2 = preco2 * this.state.promocao.CDQTD2;

      precoFinal = preco1 + preco2;
    } else if (this.state.promocao.CDPRODU1 && this.state.promocao.CDPRODU2 && this.state.promocao.CDPRODU3 && !this.state.promocao.CDPRODU4 && !this.state.promocao.CDPRODU5) {
      tipoPromo = 3;

      if (this.state.promocao.CDDESC1 > 0) preco1 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC1 / 100);
      else if (this.state.promocao.CDPRECO1 > 0) preco1 = this.state.promocao.CDPRECO1;
      else preco1 = this.state.promocao.produto1.PRECO;

      preco1 = preco1 * this.state.promocao.CDQTD1;

      if (this.state.promocao.CDDESC2 > 0) preco2 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC2 / 100);
      else if (this.state.promocao.CDPRECO2 > 0) preco2 = this.state.promocao.CDPRECO2;
      else preco2 = this.state.promocao.produto2.PRECO;

      preco2 = preco2 * this.state.promocao.CDQTD2;

      if (this.state.promocao.CDDESC3 > 0) preco3 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC3 / 100);
      else if (this.state.promocao.CDPRECO3 > 0) preco3 = this.state.promocao.CDPRECO3;
      else preco3 = this.state.promocao.produto3.PRECO;

      preco3 = preco3 * this.state.promocao.CDQTD3;

      precoFinal = preco1 + preco2 + preco3;
    } else if (this.state.promocao.CDPRODU1 && this.state.promocao.CDPRODU2 && this.state.promocao.CDPRODU3 && this.state.promocao.CDPRODU4 && !this.state.promocao.CDPRODU5) {
      tipoPromo = 4;

      if (this.state.promocao.CDDESC1 > 0) preco1 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC1 / 100);
      else if (this.state.promocao.CDPRECO1 > 0) preco1 = this.state.promocao.CDPRECO1;
      else preco1 = this.state.promocao.produto1.PRECO;

      preco1 = preco1 * this.state.promocao.CDQTD1;

      if (this.state.promocao.CDDESC2 > 0) preco2 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC2 / 100);
      else if (this.state.promocao.CDPRECO2 > 0) preco2 = this.state.promocao.CDPRECO2;
      else preco2 = this.state.promocao.produto2.PRECO;

      preco2 = preco2 * this.state.promocao.CDQTD2;

      if (this.state.promocao.CDDESC3 > 0) preco3 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC3 / 100);
      else if (this.state.promocao.CDPRECO3 > 0) preco3 = this.state.promocao.CDPRECO3;
      else preco3 = this.state.promocao.produto3.PRECO;

      preco3 = preco3 * this.state.promocao.CDQTD3;

      if (this.state.promocao.CDDESC4 > 0) preco4 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC4 / 100);
      else if (this.state.promocao.CDPRECO4 > 0) preco4 = this.state.promocao.CDPRECO4;
      else preco4 = this.state.promocao.produto4.PRECO;

      preco4 = preco4 * this.state.promocao.CDQTD4;

      precoFinal = preco1 + preco2 + preco3 + preco4;
    } else if (this.state.promocao.CDPRODU1 && this.state.promocao.CDPRODU2 && this.state.promocao.CDPRODU3 && this.state.promocao.CDPRODU4 && this.state.promocao.CDPRODU5) {
      tipoPromo = 5;

      if (this.state.promocao.CDDESC1 > 0) preco1 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC1 / 100);
      else if (this.state.promocao.CDPRECO1 > 0) preco1 = this.state.promocao.CDPRECO1;
      else preco1 = this.state.promocao.produto1.PRECO;

      preco1 = preco1 * this.state.promocao.CDQTD1;

      if (this.state.promocao.CDDESC2 > 0) preco2 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC2 / 100);
      else if (this.state.promocao.CDPRECO2 > 0) preco2 = this.state.promocao.CDPRECO2;
      else preco2 = this.state.promocao.produto2.PRECO;

      preco2 = preco2 * this.state.promocao.CDQTD2;

      if (this.state.promocao.CDDESC3 > 0) preco3 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC3 / 100);
      else if (this.state.promocao.CDPRECO3 > 0) preco3 = this.state.promocao.CDPRECO3;
      else preco3 = this.state.promocao.produto3.PRECO;

      preco3 = preco3 * this.state.promocao.CDQTD3;

      if (this.state.promocao.CDDESC4 > 0) preco4 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC4 / 100);
      else if (this.state.promocao.CDPRECO4 > 0) preco4 = this.state.promocao.CDPRECO4;
      else preco4 = this.state.promocao.produto4.PRECO;

      preco4 = preco4 * this.state.promocao.CDQTD4;

      if (this.state.promocao.CDDESC5 > 0) preco5 = this.props.item.PRECO - this.props.item.PRECO * (this.state.promocao.CDDESC5 / 100);
      else if (this.state.promocao.CDPRECO5 > 0) preco5 = this.state.promocao.CDPRECO5;
      else preco5 = this.state.promocao.produto5.PRECO;

      preco5 = preco5 * this.state.promocao.CDQTD5;

      precoFinal = preco1 + preco2 + preco3 + preco4 + preco5;
    }

    return (
      <div className="p-4 alert alert-promo">
        {tipoPromo === 1 ? (
          <>
            <h4 className="alert-heading">Compre {this.props.item.PROMOCAO[0].CDQTD1} por:</h4>
            <p className="text-center">
              {' '}
              R$ <span>{Diversos.number_format(precoFinal, 2, ',', '')}</span>{' '}
            </p>
            <hr />
            <button className="btn btn-block py-2" onClick={this.handleAddCartPromo.bind(this)}>
              Comprar kit
            </button>
          </>
        ) : (
          <>
            <h4 className="alert-heading">Leve mais por menos:</h4>
            <br />
            <Combo
              img1={
                this.state.promocao.produto1.FOTOS && this.state.promocao.produto1.FOTOS.length > 0
                  ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.promocao.produto1.FOTOS[0].link}`
                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
              }
              img2={
                this.state.promocao.produto2.FOTOS && this.state.promocao.produto2.FOTOS.length > 0
                  ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.promocao.produto2.FOTOS[0].link}`
                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
              }
            />
            <br />
            <ul>{[1, 2, 3, 4, 5].map((row, index) => this.state.promocao[`produto${index}`] && <li key={index}>{this.state.promocao[`produto${index}`].NOME}</li>)}</ul>
            <hr />
            <button className="btn btn-block py-2" onClick={this.handleAddCartPromo.bind(this)}>
              Comprar kit
            </button>
          </>
        )}
      </div>
      // <a href={`/${Diversos.toSeoUrl(this.props.item.NOME)}`} target="_self" className="mb-5 product-container-promo" title="Ver detalhes do produto">
      //   <div className="w-100 img-wrapper">
      //     {tipoPromo === 1 ? (
      //       <Selo
      //         valor={Diversos.number_format(precoFinal, 2, ',', '')}
      //         img={
      //           this.state.promocao.produto1.FOTOS && this.state.promocao.produto1.FOTOS.length > 0
      //             ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.promocao.produto1.FOTOS[0].link}`
      //             : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
      //         }
      //         qtd={this.props.item.PROMOCAO[0].CDQTD1}
      //       />
      //     ) : (
      //       <Combo
      //         img1={
      //           this.state.promocao.produto1.FOTOS && this.state.promocao.produto1.FOTOS.length > 0
      //             ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.promocao.produto1.FOTOS[0].link}`
      //             : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
      //         }
      //         img2={
      //           this.state.promocao.produto2.FOTOS && this.state.promocao.produto2.FOTOS.length > 0
      //             ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.promocao.produto2.FOTOS[0].link}`
      //             : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
      //         }
      //       />
      //     )}
      //   </div>
      //   <div className="product-info">
      //     {tipoPromo === 1 ? <p className="product-name"> {this.props.item.NOME} </p> : <p className="product-name"> {`${this.state.promocao.produto1.NOME} + ${this.state.promocao.produto2.NOME}`} </p>}
      //     <p className="product-price"> R$ {Diversos.number_format(precoFinal, 2, ',', '')} </p>
      //     <p className="product-price-split">ou 10x de R$ {Diversos.number_format(precoFinal / 10, 2, ',', '')}</p>
      //   </div>
      //   {!this.props.item.ESTOQUE || this.props.item.ESTOQUE <= 0 ? (
      //     <p className="sem-estoque"> Produto indisponível </p>
      //   ) : (
      //     <Button className="btn-primary-action btn-add-cart" onClick={this.handleAddCartPromo.bind(this)}>
      //       {' '}
      //       Comprar{' '}
      //     </Button>
      //   )}
      // </a>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProductPromo);
