import React from 'react';
import './index.scss';

import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import SocialLogin from 'react-social-login';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';

import Footer from '../../components/Footer';
import SocialButton from '../../components/SocialButton';

import divaBranco from '../../assets/Images/logo-branco.png';
import divaRoxo from '../../assets/Images/logo.png';
import warn from '../../assets/Images/warn.svg';

import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEyeSlash, faEye, faTimes, faUser} from '@fortawesome/free-solid-svg-icons';
import {faFacebookF, faGoogle} from '@fortawesome/free-brands-svg-icons';
import queryString from 'query-string';
import {Diversos} from '../../services/diversos';
declare var location: any;

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Login extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();

    const {checkout} = queryString.parse(props.location.search);

    this.state = {
      returnToCheckout: checkout ? true : null,
      redirect: null,
      isLoading: false,
      email: '',
      senha: '',
      hasError: false,
      hasErrorTitle: '',
      hasErrorMsg: '',
      isLoadingTeste: true,
      hidden: true,
      loginStep: 1,
      socialId: null,

      isLoadingFacebook: false,
      isLoadingGoogle: false,
    };
  }

  componentDidMount() {
    const self = this;

    if (self.props.user && self.props.user.status === true) {
      // SE USUARIO ESTIVER LOGADO REDIRECIONA PARA OUTRA PAGINA
      self.setState({redirect: '/'});
    }
  }

  private async setMsg(type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      self.setState(
        {
          hasError: true,
          hasErrorTitle: title,
          hasErrorMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasError: false}), timeout);
        },
      );
    } else {
      self.setState(
        {
          hasSuccess: true,
          hasSuccessTitle: title,
          hasSuccessMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({novoHasSuccess: false}), timeout);
        },
      );
    }
  }

  /**
   * FAZ TRATAMENTO DA SUBMISSAO FORM DE LOGIN
   */
  private async handleSubmit(event) {
    const self = this;

    event.preventDefault();

    self.setState({
      hasErrorTitle: false,
      hasErrorMsg: '',
    });

    if (self.state.loginStep === 1) {
      if (!self.state.email) {
        self.setMsg('error', 'Login inválido', 'O campo Email não pode ficar em branco');
        self.setState({isLoading: false});
        return;
      }

      if (!Diversos.validateEmail(self.state.email)) {
        self.setMsg('error', 'E-mail Inválido', 'Por favor, digite um E-mail válido para continuar');
        return;
      }

      self.setState({isLoading: true});

      let param = {login: self.state.email};

      try {
        const {data} = await self.api.post(`/customer/check-email`, param);

        if (!data.status) {
          throw new Error(data.msg);
        }

        self.setState({
          loginStep: 2,
        });
      } catch (e) {
        self.setState({
          redirect: '/cadastro',
        });
      } finally {
        self.setState({isLoading: false});
      }
    } else {
      if (!self.state.senha) {
        self.setState({isLoading: false, loginStep: 1});
        self.setMsg('error', 'Login inválido', 'O campo Senha não pode ficar em branco');
      }

      self.setState({isLoading: true});

      let param = {
        email: self.state.email,
        senha: self.state.senha,
      };

      try {
        const {data} = await self.api.post('/customer/login', param);

        if (!data.status) {
          throw new Error(data.msg);
        } else {
          self.props.doLogin({
            codigo: data.msg.codigo,
            nome: data.msg.nome,
            email: data.msg.email,
            cpf: data.msg.cpf,
            cep: data.msg.cep,
            status: true,
            avatar: '',
            token: null,
          });

          self.setState({
            redirect: self.state.returnToCheckout ? '/checkout/entrega' : '/',
            hasError: false,
            hasErrorTitle: '',
            hasErrorMsg: '',
          });
        }
      } catch (e) {
        self.setState({
          hasError: true,
          hasErrorTitle: 'Login inválido',
          hasErrorMsg: 'Não foi possível efetuar login, por favor tente novamente',
        });
      } finally {
        self.setState({isLoading: false});
      }
    }
  }

  toggleShow(input: number) {
    if (input === 1) {
      this.setState({hidden: !this.state.hidden});
      return;
    }

    this.setState({confHidden: !this.state.confHidden});
  }

  async handleSocialLogin(user) {
    const self = this;

    if (user._provider.toLowerCase() === 'facebook') self.setState({isLoadingFacebook: true});
    else if (user._provider.toLowerCase() === 'google') self.setState({isLoadingGoogle: true});

    let param = {
      provider: user._provider,
      id: user._profile.id,
      nome: user._profile.name,
      email: user._profile.email,
    };

    try {
      const {data} = await self.api.post(`/customer/social-login`, param);

      if (!data.status) {
        throw new Error(data.msg);
      }

      let newUser = data.msg.cpf ? false : true;

      self.props.setParam({
        ...self.props.param,
        newUser: newUser,
      });

      self.props.doLogin({
        codigo: data.msg.codigo,
        nome: data.msg.nome,
        email: data.msg.email,
        cpf: data.msg.cpf,
        cep: data.msg.cep,
        status: true,
        avatar: '',
        token: null,
      });

      if (newUser) {
        self.setState({
          redirect: '/editar/pessoal',
          hasError: false,
          hasErrorTitle: '',
          hasErrorMsg: '',
        });
      }

      self.setState({
        redirect: '/',
        hasError: false,
        hasErrorTitle: '',
        hasErrorMsg: '',
      });
    } catch (e) {
      console.error(e);

      self.setMsg('error', 'Login inválido', e.message);

      // self.setState({
      //   hasError: true,
      //   hasErrorTitle: 'Login inválido',
      //   hasErrorMsg: e.message
      // });
    } finally {
      if (user._provider.toLowerCase() === 'facebook') self.setState({isLoadingFacebook: false});
      else if (user._provider.toLowerCase() === 'google') self.setState({isLoadingGoogle: false});
    }
  }

  render() {
    if (this.state.redirect === '/recuperar-senha') {
      return <Redirect to={{pathname: this.state.redirect, state: {email: this.state.email}}} />;
    } else if (this.state.redirect === '/cadastro') {
      return <Redirect to={{pathname: this.state.redirect, state: {email: this.state.email}}} />;
    } else if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const smDevice = window.innerWidth <= 576;

    const socialBtn = {
      width: '100%',
      paddingTop: '.75rem',
      paddingBottom: '.75rem',
      marginTop: '1rem',
    };

    return (
      <>
        {this.state.hasError && (
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.hasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.hasErrorMsg} </p>
          </div>
        )}

        <section className="user-pages">
          <div className="form-bg" style={{flexDirection: this.state.isLoading ? 'column' : 'row', alignItems: this.state.isLoading ? 'center' : ''}}>
            <a href="/">
              {' '}
              <img src={smDevice ? divaRoxo : divaBranco} className="user-page-logo" alt="Logo Depimiel" />{' '}
            </a>
            {this.state.isLoading ? (
              <div className="d-flex w-100 mt-5" style={{alignItems: 'center', justifyContent: 'center'}}>
                <i className="fas fa-spin fa-spinner fa-3x"></i>
              </div>
            ) : (
              <>
                <article className="user-form-container">
                  {this.state.loginStep === 1 ? (
                    <h1> Olá! Digite seu e-mail para continuar </h1>
                  ) : (
                    <>
                      <h1> Bem vindo(a) de volta, </h1>
                      <div className="user-id-card">
                        <FontAwesomeIcon icon={faUser} />
                        <p> {this.state.email} </p>
                        <button onClick={() => this.setState({loginStep: 1})}>
                          {' '}
                          <FontAwesomeIcon icon={faTimes} />{' '}
                        </button>
                      </div>
                    </>
                  )}
                  <Form className="d-flex flex-column w-100 mt-5" method="post" action="#" onSubmit={this.handleSubmit.bind(this)}>
                    {this.state.loginStep === 1 ? (
                      <Form.Group className="mb-3 w-100">
                        <Form.Label> Seu Email </Form.Label>
                        <Form.Control name={'email'} type="email" placeholder="Email" style={{height: 60}} onChange={(event) => this.setState({email: event.target.value})} />
                      </Form.Group>
                    ) : (
                      <>
                        <Form.Group className="w-100" style={{position: 'relative'}}>
                          <Form.Label> Sua Senha </Form.Label>
                          <FontAwesomeIcon
                            icon={this.state.hidden ? faEyeSlash : faEye}
                            style={{bottom: '1.375rem'}}
                            className="pswd-addon"
                            onClick={this.toggleShow.bind(this, 1)}
                          />
                          <Form.Control
                            name={'senha'}
                            type={this.state.hidden ? 'password' : 'text'}
                            placeholder="Senha"
                            value={this.state.senha || ''}
                            style={{height: 60}}
                            onChange={(event) => this.setState({senha: event.target.value})}
                          />
                        </Form.Group>
                        <button onClick={() => this.setState({redirect: '/recuperar-senha'})} className="btn-link-action" style={{maxWidth: 'fit-content'}}>
                          {' '}
                          Esqueceu sua senha?{' '}
                        </button>
                      </>
                    )}
                    <button type="submit" className="btn-primary-action btn-user" title="Fazer Login">
                      {' '}
                      Entrar{' '}
                    </button>
                  </Form>

                  {/* <span style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                  <span style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', marginTop: '1rem'}}> <hr className="mr-2"/> ou <hr className="ml-2"/> </span>

                  <SocialButton
                  provider='facebook'
                  appId='719113475453514'
                  onLoginSuccess={this.handleSocialLogin.bind(this)}
                  onLoginFailure={(err) => this.setState({ hasError: true, hasErrorTitle: 'Atenção', hasErrorMsg: 'Não foi possível logar com Facebook' }, () => setTimeout(location.reload(), 3000))}
                  style={socialBtn}
                  className="btn-primary-action btn-fb"
                  >
                    {
                      this.state.isLoadingFacebook ? 
                      <>
                        <i className="fas fa-spin fa-circle-notch" style={{marginRight: '.5rem'}}></i> {` `}
                        Fazendo login, por favor aguarde...
                      </>
                      :
                      <>
                        <FontAwesomeIcon icon={faFacebookF} style={{marginRight: '.75rem'}}/>
                        Continuar com Facebook
                      </>
                    }
                  </SocialButton>

                  <SocialButton
                  provider='google'
                  appId='229842119649-sr1fbvv3a994sv5cp9b14gs12v1q49se.apps.googleusercontent.com' // chave: WsXrp_RO2s9yhQ4G7zkA2_vt
                  onLoginSuccess={this.handleSocialLogin.bind(this)}
                  onLoginFailure={(err) => console.error(err)}
                  // onLoginFailure={
                    // (err) => this.setState({ hasError: true, hasErrorTitle: 'Atenção', hasErrorMsg: 'Não foi possível logar com Google' })
                  // }
                  style={socialBtn}
                  className="btn-primary-action btn-g"
                  >
                    {
                      this.state.isLoadingGoogle ? 
                      <>
                        <i className="fas fa-spin fa-circle-notch" style={{marginRight: '.5rem'}}></i> {` `}
                        Fazendo login, por favor aguarde...
                      </>
                      :
                      <>
                        <FontAwesomeIcon icon={faGoogle} style={{marginRight: '.75rem'}}/>
                        Continuar com Google
                      </>
                    }
                  </SocialButton>
                </span> */}
                </article>
              </>
            )}
          </div>
          {/* { !this.state.isLoading && <h3> Não tem uma conta? <a href="/cadastro"> Cadastre-se </a> </h3> }  */}
        </section>

        <Footer />

        <Modal
          closeButton
          show={this.state.socialId}
          onHide={() => this.setState({socialId: null, loginStep: 1})}
          aria-labelledby="social-login-warning"
          className="px-0 social-login-warning"
        >
          <img src={warn} />
          <p style={{fontSize: window.innerWidth < 440 ? 18 : 20, color: '#333333', textAlign: 'center', marginBottom: '2rem'}}>
            {' '}
            Conta vinculada ao <b> {this.state.socialId} </b>{' '}
          </p>
          <div>
            <p>
              {' '}
              O Email <b> {this.state.email || 'utilizado'} </b> está vinculado a um login com o {this.state.socialId}.{' '}
            </p>
            <p>
              {' '}
              Para continuar com este Email, faça login com o {this.state.socialId} ou
              <a href="/recuperar-senha">solicite uma mudança de senha </a>{' '}
            </p>

            <SocialButton
              provider="facebook"
              appId="221611832923342"
              onLoginSuccess={this.handleSocialLogin.bind(this)}
              onLoginFailure={(err) => console.error(err)}
              style={{...socialBtn, backgroundColor: '#3b5998'}}
              className="btn-primary-action"
            >
              <FontAwesomeIcon icon={faFacebookF} style={{marginRight: '.75rem'}} />
              Continuar com Facebook
            </SocialButton>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
