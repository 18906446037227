import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart as CartRedux, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import './index.scss';
import {Diversos} from '../../services/diversos';
import TagManager from 'react-gtm-module';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import Selo from '../../components/Selo';
import Combo from '../../components/SeloCombo';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';

interface StateProps {
  cart: CartRedux;
}

interface DispatchProps {
  update(cart: ProductType): void;
  drop(rowid: string): void;
}

interface OwnProps {
  item: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class CartItem extends Component<Props> {
  componentDidMount() {
    // TagManager.dataLayer({
    //   gtmId: 'GTM-P7FFP62',
    //   dataLayer: {
    //     productId: this.props.item.CODIGO,
    //     productName: this.props.item.NOME,
    //     productBrand: this.props.item.MARCA,
    //     productStock: this.props.item.ESTOQUE,
    //     productPrice: this.props.item.price,
    //     // productPicture: `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.props.item.FOTOS[0].link}`,
    //     page: 'Produto',
    //   },
    //   dataLayerName: 'PageDataLayer',
    // });
  }

  // Funções repetidas do Produto. Melhorar
  private IncreaseItem = () => {
    if (this.props.item.qty + 1 <= this.props.item.estoque) {
      this.props.update({
        ...this.props.item,
        qty: this.props.item.qty + 1,
      });
    }
  };

  private DecreaseItem = () => {
    if (this.props.item.qty === 1) {
      this.props.drop(this.props.item.rowid);
    } else {
      this.props.update({
        ...this.props.item,
        qty: this.props.item.qty - 1,
      });
    }
  };

  private handleChange(event) {
    const diferenca = Number(event.target.value) - Number(this.props.item.qty);

    if (Number(event.target.value) > Number(this.props.item.qty)) {
      let qtdJaAdd = 0;

      if (this.props.cart && this.props.cart.produtos) {
        for (let i = 0; i < this.props.cart.produtos.length; i++) {
          if (this.props.cart.produtos[i].codigo === this.props.item.codigo) {
            qtdJaAdd += Number(this.props.cart.produtos[i].qty);
          }
        }
      }

      if (qtdJaAdd + diferenca > this.props.item.estoque) {
        // swal('Atenção', `Quantidade máxima em estoque ${this.state.produto.ESTOQUE} unidade(s)`, 'error');
        return false;
      }
    }

    this.props.update({
      ...this.props.item,
      qty: event.target.value,
    });
  }

  private handleAmountChange = () => {};

  render() {
    let tipoPromo = 0;
    let preco1 = 0;
    let preco2 = 0;
    let preco3 = 0;
    let preco4 = 0;
    let preco5 = 0;
    let precoFinal = 0;

    if (this.props.item && this.props.item.promo) {
      if (
        this.props.item.promo.CDPRODU1 &&
        !this.props.item.promo.CDPRODU2 &&
        !this.props.item.promo.CDPRODU3 &&
        !this.props.item.promo.CDPRODU4 &&
        !this.props.item.promo.CDPRODU5
      ) {
        tipoPromo = 1;
        preco1 = this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
        precoFinal = preco1 * this.props.item.promo.CDQTD1;
      } else if (
        this.props.item.promo.CDPRODU1 &&
        this.props.item.promo.CDPRODU2 &&
        !this.props.item.promo.CDPRODU3 &&
        !this.props.item.promo.CDPRODU4 &&
        !this.props.item.promo.CDPRODU5
      ) {
        tipoPromo = 2;
        preco1 = this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
        precoFinal = preco1 * this.props.item.promo.CDQTD1;
      } else if (
        this.props.item.promo.CDPRODU1 &&
        this.props.item.promo.CDPRODU2 &&
        this.props.item.promo.CDPRODU3 &&
        !this.props.item.promo.CDPRODU4 &&
        !this.props.item.promo.CDPRODU5
      ) {
        tipoPromo = 3;
        preco1 = this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
        precoFinal = preco1 * this.props.item.promo.CDQTD1;
      } else if (
        this.props.item.promo.CDPRODU1 &&
        this.props.item.promo.CDPRODU2 &&
        this.props.item.promo.CDPRODU3 &&
        this.props.item.promo.CDPRODU4 &&
        !this.props.item.promo.CDPRODU5
      ) {
        tipoPromo = 4;
        preco1 = this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
        precoFinal = preco1 * this.props.item.promo.CDQTD1;
      } else if (
        this.props.item.promo.CDPRODU1 &&
        this.props.item.promo.CDPRODU2 &&
        this.props.item.promo.CDPRODU3 &&
        this.props.item.promo.CDPRODU4 &&
        this.props.item.promo.CDPRODU5
      ) {
        tipoPromo = 5;
        preco1 = this.props.item.promo.CDDESC1 > 0 ? this.props.item.PRECO - this.props.item.PRECO * (this.props.item.promo.CDDESC1 / 100) : this.props.item.promo.CDPRECO1;
        precoFinal = preco1 * this.props.item.promo.CDQTD1;
      }
    }

    return (
      <>
        <Row className="mx-0 cart-item-container">
          <Col xs={4} md={4} className="cart-item-img-container">
            <Image src={`${this.props.item.foto}`} thumbnail className="cart-item-img" />
          </Col>
          <Col xs={6} md={6} className="pr-0" style={{paddingLeft: 10}}>
            <Row className="mx-0">
              <p className="cart-item-name">{this.props.item.nome}</p>
              {this.props.item.promo && this.props.item.promo.CDPROMOCAO ? (
                <>
                  <br />
                  <span className={'badge badge-secondary'}>Kit promocional</span>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mx-0">
              <p className="cart-item-price">
                {this.props.item.priceOrigin && this.props.item.priceOrigin > this.props.item.price && this.props.item.price > 0 ? (
                  <>
                    <small>De R$ ${Diversos.number_format(this.props.item.priceOrigin, 2, ',', '')}</small>
                    <br />
                    Por R$ ${Diversos.number_format(this.props.item.price, 2, ',', '')}
                  </>
                ) : (
                  `R$ ${Diversos.number_format(this.props.item.price, 2, ',', '')} `
                )}
              </p>
            </Row>
          </Col>
          <Col xs={2} md={2} className="cart-item-actions">
            <Row className="mx-0">
              <Button variant="link" title="Excluir item do carrinho" className={'cart-item-remove'} onClick={() => this.props.drop(this.props.item.rowid)}>
                <FontAwesomeIcon icon={faTrashAlt} className="cart-item-action color-modifier" title="Excluir item" />
              </Button>
            </Row>
            <Row className="w-100 mx-0">
              <label> Qtde </label>
              <input
                type="number"
                className="cart-amount-input"
                name="quantidade"
                defaultValue={this.props.item.qty || ''}
                value={this.props.item.qty || ''}
                onChange={this.handleChange.bind(this)}
                min={this.props.item.promo && this.props.item.promo.CDPROMOCAO && this.props.item.qtdPromo && this.props.item.qtdPromo > 1 ? this.props.item.qtdPromo : 1}
                max={this.props.item.estoque}
                step={this.props.item.promo && this.props.item.promo.CDPROMOCAO && this.props.item.qtdPromo && this.props.item.qtdPromo > 1 ? this.props.item.qtdPromo : 1}
                // onKeyDown={(event) => {
                //   event.preventDefault();
                //   event.stopPropagation();
                // }}
              />
            </Row>
          </Col>
        </Row>
        <hr />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...CartActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
