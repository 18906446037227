import React from 'react';
import './index.scss';

import DivaFlower from '../../assets/Images/divaflower.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCookieBite} from '@fortawesome/free-solid-svg-icons';
import {ParamTypes} from '../../store/ducks/param/types';

const CookieBanner = (props) => (
  <section className="cookie-banner">
    <FontAwesomeIcon icon={faCookieBite} />
    <p> Ei! A Depimiel utiliza cookies para melhorar sua experiência de navegação e compra! Leia sobre nossa Política De Cookies </p>
    <button className="btn-outlined-action btn-accept-cookies" onClick={props.accept}>
      {' '}
      Ok{' '}
    </button>
  </section>
);

export default CookieBanner;
