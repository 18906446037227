import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from "react-input-mask";
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {Spinner} from 'react-bootstrap'

import logoPreta from '../../assets/Images/domakoski-preta.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
  

interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;

  setParam(data: Param): void;
}


interface OwnProps {
  item?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

  
class EmailEdit extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,

      isLoading: false,
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,

      novoEmail: '',
      confNovoEmail: '',
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

   self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleSubmit () {
    const self = this;
    let param: any = {};
    
    if (( !self.state.novoEmail ) || ( !self.state.confNovoEmail )) {
      self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
      return;
    }

    if ((self.state.novoEmail) !== (self.state.confNovoEmail)) {
      self.setMsg( 'error', "Formulário incorreto", "Os emails digitados precisam ser idênticos.");
      return;
    }

    if ( !Diversos.validateEmail(self.state.customer.email) ) {
      self.setMsg( 'error', "Email inválido", "Necessário informe um email válido para continuar.");
      return;
    }

    param.email = self.state.novoEmail;

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(`/customer/${self.props.user.codigo}`, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`);
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`);
    } finally {
      self.setState({ isLoading: false, redirect: "/meu-cadastro" });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   */
  private async setMsg ( type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      
      self.setState({
        customerHasError: true,
        customerHasErrorTitle: title,
        customerHasErrorMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasError: false }), timeout)
      });

    } else {

      self.setState({
        customerHasSuccess: true,
        customerHasSuccessTitle: title,
        customerHasSuccessMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
      });

    }
  }

  render () {
    return (
      <>
        {
          this.state.isLoadingCustomer === true ?
          <span className="py-3 w-100 m-auto text-center d-flex justify-content-center">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-center m-auto spinner" style={{fontSize: 24}}/>
          </span>
          :
          <>

          {
          this.state.customerHasError &&
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.customerHasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.customerHasErrorMsg} </p>
          </div>
          }

          {
          this.state.customerHasSuccess &&
          <div className="alert-box success-box">
            <p className="login-success-title"> {` - ${this.state.customerHasSuccessTitle} - `} </p>
            <p className="alert-msg"> {this.state.customerHasSuccessMsg} </p>
          </div>
          }

            <div className="data-input-wrapper">
              <Form.Group controlId="formGridEmail">
                <Form.Label> Digite seu novo Email * </Form.Label>
                <Form.Control type="email" placeholder="E-mail" defaultValue={this.state.novoEmail || ""} onChange={(event) => this.setState({novoEmail:  event.target.value})}/>
              </Form.Group>

              <Form.Group controlId="formGridEmail">
                <Form.Label> Repita seu Email * </Form.Label>
                <Form.Control type="email" placeholder="E-mail" onChange={(event) => this.setState({confNovoEmail: event.target.value})} />
              </Form.Group>
              <a className="btn-link-action" href="/reset-senha"> Solicitar uma mudança de senha </a> 
            </div>

            <div className="data-actions">
              <a href="/meu-cadastro" className="btn-link-action"> Voltar </a>
              <Button className="btn-primary-action btn-register" type="submit" onClick={this.handleSubmit.bind(this)} disabled={this.state.isLoading}>
                { (this.state.isLoading) ? 'Salvando...' : 'Salvar' }
              </Button>
            </div>
          </>
        }
      </>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailEdit);
