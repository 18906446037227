import axios from 'axios';

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

const apiAxio = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_RD_STATION
});

export default class Api {

  public async postConversion ( param: any = null ) {
    
    if ( !param ) {
      return {status: false, msg: 'Parametros invalidos'};
    }
    
    let paramApi = {
      "event_type": "CONVERSION",
      "event_family":"CDP",
      "payload": {
        "conversion_identifier": param.formName,
        "name": param.nome,
        "email": param.email,
        // "job_title": "job title value",
        "state": "granted",
        "city": param.cidade,
        "country": "BR",
        "personal_phone": param.fone2,
        "mobile_phone": param.fone,
        // "twitter": "twitter handler of the contact",
        // "facebook": "facebook name of the contact",
        // "linkedin": "linkedin user name of the contact",
        // "website": "website of the contact",
        // "cf_custom_field_api_identifier": "custom field value",
        // "company_name": "company name",
        // "company_site": "company website",
        // "company_address": "company address",
        // "client_tracking_id": "lead tracking client_id",
        // "traffic_source": "Google",
        // "traffic_medium": "cpc",
        // "traffic_campaign": "easter-50-off",
        // "traffic_value": "easter eggs",
        // "tags": ["codigo", "2019"],
        "available_for_mailing": param.newsletter,
        // "legal_bases": [
          // {
          //   "category": "data_processing",
          //   "type": "pre_existent_contract"
          // },
          // {
          //   "category": "communications",
          //   "type": "consent",
          //   "status": "granted"
          // }
        // ]
      }
    }
    
    try {
      const {data} = await this.post('/events', paramApi);
      console.log( data );
      if (( data.errors ) && (data.errors.length > 0)) {
        throw new Error( data.errors[0].error_message );
      }
      
      return {
        status: true,
        msg: data.event_uuid
      };
      
    } catch (e) {
      return {
        status: false,
        msg: e.message
      };
    }
  }
  
  /*
  * ABAIXO FUNCOES INTERNAS PARA SERVIR AS FUNCOES PUBLICAS
  * */
  private async get (url: string) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';   
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN_PUB_RD_STATION;

      return apiAxio.get(url, {headers});
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  private async post (url: string, body: any = null) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN_PUB_RD_STATION;

      var bodyEnd = {
        ...body
      };

      return apiAxio.post(url, bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  private async put (url: string, body: any = null) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN_PUB_RD_STATION;

      var bodyEnd = {
        ...body
      };

      return apiAxio.put(url, bodyEnd, { headers });
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }

  private async delete (url: string) {
    try {
      let headers = {};
      headers['Accept'] = 'application/json';
      headers['Authorization'] = ' Bearer ' + process.env.REACT_APP_API_TOKEN_PUB_RD_STATION;

      return apiAxio.delete(url, {headers});
    } catch (e) {
      console.error('api (ERROR): ', e);
    }
  }
}
