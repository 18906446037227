import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import queryString from 'query-string';
import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {Spinner} from 'react-bootstrap'

import logoPreta from '../../assets/Images/domakoski-preta.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faArrowLeft, faArrowRight, faExclamationTriangle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'


interface StateProps {
  user: User;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class ShippingEdit extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();
    // const { back } = queryString.parse(props.location.search);
    const { back } = queryString.parse(window.location.search);

    this.state = {
      redirect: null,

      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoading: false,
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,
      back: back,
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) {
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

   self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async getAddressByCep () {
    const self = this;

    if (( !self.state.customer.cep ) || ( Diversos.getnums( self.state.customer.cep ).length !== 8 ))
      return false;

    self.setState({isLoadingCep: true});

    let param = {cep : self.state.customer.cep}

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if ( data.status ) {
        self.setState({
          customer: {
            ...self.state.customer,
            rua: data.msg.logradouro,
            bairro: data.msg.bairro,
            cidade: data.msg.localidade,
            estado: data.msg.uf,
            codmun: data.msg.ibge,
          }
        });
      }

    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  private async handleSubmit () {
    const self = this;
    let param: any = {};

    if (( !self.state.customer.cep ) || ( !self.state.customer.rua ) || ( !self.state.customer.numero ) || ( !self.state.customer.bairro ) || ( !self.state.customer.cidade ) || ( !self.state.customer.estado )) {
      self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
      return;
      }

    param.cep = self.state.customer.cep;
    param.rua = self.state.customer.rua;
    param.numero = self.state.customer.numero;
    param.complemento = self.state.customer.complemento;
    param.bairro = self.state.customer.bairro;
    param.cidade = self.state.customer.cidade;
    param.estado = self.state.customer.estado;
    param.codmun = self.state.customer.codmun;

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(`/customer/${self.props.user.codigo}`, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.props.doLogin({...self.props.user, cep: param.cep});

        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`);

        if (this.state.back)
          window.history.back();
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`);
    } finally {
      self.setState({ isLoading: false, redirect: "/meu-cadastro" });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type
   * @param title
   * @param msg
   */
  private async setMsg ( type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {

      self.setState({
        customerHasError: true,
        customerHasErrorTitle: title,
        customerHasErrorMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasError: false }), timeout)
      });

    } else {

      self.setState({
        customerHasSuccess: true,
        customerHasSuccessTitle: title,
        customerHasSuccessMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
      });

    }
  }

  render () {
    return (
      <>
        {
          this.state.isLoadingCustomer === true ?
          <>
            <span className="py-3 w-100 m-auto text-center d-flex justify-content-center">
              <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-center m-auto spinner" style={{fontSize: 24}}/>
              {/* <p className="loading-asset"> Carregando <br/> Informações... </p> */}
            </span>
          </>
          :
          <>

            {
            this.state.customerHasError &&
            <div className="alert-box error-box">
              <p className="login-error-title"> {` - ${this.state.customerHasErrorTitle} - `} </p>
              <p className="alert-msg"> {this.state.customerHasErrorMsg} </p>
            </div>
            }

            {
            this.state.customerHasSuccess &&
            <div className="alert-box success-box">
              <p className="login-success-title"> {` - ${this.state.customerHasSuccessTitle} - `} </p>
              <p className="alert-msg"> {this.state.customerHasSuccessMsg} </p>
            </div>
            }

            <div className="data-input-wrapper">
              <Form.Row className="mx-auto">
                <Form.Group as={Col} controlId="formGridCEP" xs={12}>
                  <Form.Label> CEP * </Form.Label>
                  <InputMask
                  mask="99.999-999"
                  // ref={this.novoCepRef}
                  disabled={this.state.isLoadingCep}
                  value={this.state.customer.cep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, cep: event.target.value}}, this.getAddressByCep.bind(this))}
                  >
                    {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="00.000-000" required/>}
                  </InputMask>
                  {
                    this.state.isLoadingCep &&
                    <Form.Text className={"text-muted"}>
                      <i className={"fas fa-spin fa-spinner"}></i> {" "}
                      Pesquisando CEP, por favor aguarde...
                    </Form.Text>
                  }
                </Form.Group>
              </Form.Row>
              <Form.Row className="mx-auto">
                <Form.Group as={Col} controlId="formGridRua" xs={12} md={8}>
                  <Form.Label> Rua * </Form.Label>
                  <Form.Control
                  placeholder="Avenida Paulista"
                  value={this.state.customer.rua}
                  // ref={this.novoRuaRef}
                  disabled={this.state.isLoadingCep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, rua: event.target.value}})}
                  required/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridNumero" xs={12} md={4}>
                  <Form.Label> Número * </Form.Label>
                  <Form.Control
                  value={this.state.customer.numero}
                  // ref={this.novoNumeroRef}
                  disabled={this.state.isLoadingCep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, numero: event.target.value}})}
                  required/>
                </Form.Group>
              </Form.Row>
              <Form.Row className="mx-auto">
                <Form.Group as={Col} ontrolId="formGridBairro">
                  <Form.Label> Bairro * </Form.Label>
                  <Form.Control
                  value={this.state.customer.bairro}
                  // ref={this.novoBairroRef}
                  disabled={this.state.isLoadingCep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, bairro: event.target.value}})}
                  required/>
                </Form.Group>
              </Form.Row>
              <Form.Row className="mx-auto">
                <Form.Group as={Col} controlId="formGridCidade">
                  <Form.Label> Cidade * </Form.Label>
                  <Form.Control
                  placeholder="São Paulo"
                  value={this.state.customer.cidade}
                  // ref={this.novoCidadeRef}
                  disabled={this.state.isLoadingCep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, cidade: event.target.value}})} required/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEstado">
                  <Form.Label> Estado * </Form.Label>
                  <Form.Control
                  as="select"
                  value={this.state.customer.estado}
                  // ref={this.novoUfRef}
                  disabled={this.state.isLoadingCep}
                  onChange={(event) => this.setState({customer: {...this.state.customer, estado: event.target.value}})}
                  required>
                    {
                      Diversos.getUFs().map((row, index) => (
                        <option key={index} value={row.value}> {row.label} </option>
                      ))
                    }
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row className="mx-auto">
                <Form.Group as={Col} controlId="formGridComplemento">
                  <Form.Label> Complemento </Form.Label>
                  <Form.Control
                  placeholder="Ap. 7"
                  value={this.state.customer.complemento}
                  onChange={(event) => this.setState({customer: {...this.state.customer, complemento: event.target.value}})}/>
                </Form.Group>
              </Form.Row>
            </div>

            <div className="data-actions">
              <a href={this.state.back ? "/checkout/entrega" : "/meu-cadastro"} className="btn-link-action"> Voltar </a>
              <Button className="btn-primary-action btn-register" type="button" onClick={this.handleSubmit.bind(this)} disabled={this.state.isLoading}>
                { (this.state.isLoading) ? 'Salvando...' : 'Salvar' }
              </Button>
            </div>
          </>
        }
      </>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingEdit);
