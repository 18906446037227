import React from 'react';
import './index.scss';

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faPhone, faMapMarkerAlt, faArrowRight, faBarcode, faBoxOpen, faCreditCard, faTruck} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Loja1 from '../../assets/Images/loja-1.png';
import Loja2 from '../../assets/Images/loja-2.png';
import televendasImg from '../../assets/Images/televendas-pg.png';

class Televendas extends React.Component<{}> {
  state: any = null;

  constructor(props) {
    super(props);
  }

  render() {
    const mdDevice = window.innerWidth <= 1200;

    return (
      <>
        {/* <h1> Televendas </h1>
        <p className="mb-4">
          {' '}
          Ficou muito mais fácil você realizar suas compras na Diva. Com o televendas você faz as suas compras de forma rápida e segura. Nossas atendentes estão preparadas para
          oferecer a melhor solução para você.{' '}
        </p> */}
        <aside className="category-img-container">
          <img src={televendasImg} alt="Banner Nossas Saloes" />
        </aside>
        <div className="tele-info-container">
          <span>
            {' '}
            <a>
              {/* <h2> Ligue </h2>
              <h3> (41) 2170-7272 </h3>
              <p> Seg. à Sex. das 9H às 18:00H </p> */}
            </a>{' '}
          </span>
          {/* <span> <a>
            <h3> Loja 1 </h3>
            <p> <FontAwesomeIcon icon={faPhone}/> Tel.: 41 3079-6631 | 3026-4619 </p>
            <p> <FontAwesomeIcon icon={faWhatsapp}/> WhatsApp: 41 98735-8501 </p>
          </a> </span>
          <span> <a>
            <h3> Loja 2  </h3>
            <p> <FontAwesomeIcon icon={faPhone}/> Tel.: 41 3079-6631 | 3026-4619 </p>
            <p> <FontAwesomeIcon icon={faWhatsapp}/> WhatsApp: 41 98735-8501 </p>
          </a> </span> */}
        </div>
        <section className="d-none d-lg-block vantagens-container vantagens-televendas">
          <article className="mx-0 vantagens-wrapper">
            <div className="vantagem-col">
              <FontAwesomeIcon icon={faCreditCard} />
              <h4> Até 6x no Cartão* </h4>
            </div>
            <div className="vantagem-col">
              <FontAwesomeIcon icon={faBarcode} />
              <h4>
                {' '}
                5% Desconto <br /> no Boleto{' '}
              </h4>
            </div>
            <div className="vantagem-col">
              <FontAwesomeIcon icon={faWhatsapp} />
              <h4>
                {' '}
                <a
                  href="https://api.whatsapp.com/send?l=pt&phone=+554111111111&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%shop.depimiel.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Compre pelo WhatsApp (41) 1111"
                >
                  {' '}
                  Compre <br /> pelo WhatsApp{' '}
                </a>{' '}
              </h4>
            </div>
            <div className="vantagem-col">
              <FontAwesomeIcon icon={faBoxOpen} />
              <h4>
                {' '}
                Compre <br /> e retire{' '}
              </h4>
            </div>
            <div className="vantagem-col">
              <FontAwesomeIcon icon={faTruck} />
              <h4>
                {' '}
                Compra 100% <br /> Segura e Rápida{' '}
              </h4>
            </div>
          </article>
        </section>
      </>
    );
  }
}

export default Televendas;
