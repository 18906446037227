import React from 'react';
import './index.scss';

import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faPhone, faMapMarkerAlt, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import Loja1 from '../../assets/Images/loja-1.png';
import Loja2 from '../../assets/Images/loja-2.png';
import Televendas from '../../assets/Images/televendas.png';
import DivaBanner from '../../assets/Images/DivaBanner.png';

class Lojas extends React.Component<{}> {
  state: any = null;

  constructor(props) {
    super(props);
  }

  render() {
    const mdDevice = window.innerWidth <= 1200;

    return (
      <>
        <h1> Nossas Lojas </h1>
        <p className="mb-4"></p>
        <aside className="category-img-container">
          <img src={DivaBanner} alt="Banner Nossas Lojas" />
        </aside>
        <div className="lojas-container">
          {/* <img src={Loja1}/> */}

          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width={'100%'}
                height={'100%'}
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Rua%20XV%20de%20Novembro%2C%20506%20-%20Centro%2C%20Curitiba%20-%20PR%2C%2080020-310&t=&z=19&ie=UTF8&iwloc=&output=embed"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              ></iframe>
            </div>
          </div>

          {/* <span>
					<h3> Loja 1 </h3>
					<p> <FontAwesomeIcon icon={faPhone}/> Tel.: 41 3079-6631 | 3026-4619 </p>
					<p> <FontAwesomeIcon icon={faWhatsapp}/> WhatsApp da Loja: <a href="https://wa.me/5541987358501"> 41 98735-8501 </a> </p>
					<p> <FontAwesomeIcon icon={faMapMarkerAlt}/> End.: XV de Novembro, 506, CEP - 80020310 </p>
					<a className="btn-link-action" href="https://www.google.com/search?q=diva%20cosmeticos&oq=diva+cosmeticos&aqs=chrome..69i57j35i39j0l6.3449j0j7&sourceid=chrome&ie=UTF-8&sxsrf=ALeKk03wSOu7BL7GXmnY0TwYv1kIj0eS6g:1604412478502&npsic=0&rflfq=1&rlha=0&rllag=-25430662,-49270552,172&tbm=lcl&rldimm=15605914939069749384&lqi=Cg9kaXZhIGNvc21ldGljb3MiA4gBAVoiCg9kaXZhIGNvc21ldGljb3MiD2RpdmEgY29zbWV0aWNvcw&ved=2ahUKEwjpsuqexubsAhV3E7kGHX9xBE8QvS4wBXoECA8QGw&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:4&rlst=f#rlfi=hd:;si:5145086007664110956,l,Cg9kaXZhIGNvc21ldGljb3MiA4gBAVoiCg9kaXZhIGNvc21ldGljb3MiD2RpdmEgY29zbWV0aWNvcw;mv:[[-25.430154599999998,-49.2687163],[-25.431169699999998,-49.272388199999995]]" target="_blank" rel="noopener noreferrer" title="Visualizar no Maps"> Ver no mapa <FontAwesomeIcon icon={faArrowRight}/> </a>
				</span> */}
        </div>
        <div className="lojas-container">
          {/* <img src={Loja2}/> */}

          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                width={'100%'}
                height={'100%'}
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Rua%20XV%20de%20Novembro%2C%20171%20-%20Centro%2C%20Curitiba%20-%20PR%2C%2080020-310&t=&z=19&ie=UTF8&iwloc=&output=embed"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              ></iframe>
            </div>
          </div>

          {/* <span>
					<h3> Loja 2 </h3>
					<p> <FontAwesomeIcon icon={faPhone}/> Tel.: 41 3077-8065 | 3014-8002 </p>
					<p> <FontAwesomeIcon icon={faWhatsapp}/> WhatsApp da Loja: <a href="https://wa.me/5541987542849"> 41 98754-2849 </a> </p>
					<p> <FontAwesomeIcon icon={faMapMarkerAlt}/> End.: XV de Novembro, 171, CEP - 80020310 </p>
					<a className="btn-link-action" href="https://www.google.com/search?q=diva%20cosmeticos&oq=diva+cosmeticos&aqs=chrome..69i57j35i39j0l6.3449j0j7&sourceid=chrome&ie=UTF-8&sxsrf=ALeKk03wSOu7BL7GXmnY0TwYv1kIj0eS6g:1604412478502&npsic=0&rflfq=1&rlha=0&rllag=-25430662,-49270552,172&tbm=lcl&rldimm=15605914939069749384&lqi=Cg9kaXZhIGNvc21ldGljb3MiA4gBAVoiCg9kaXZhIGNvc21ldGljb3MiD2RpdmEgY29zbWV0aWNvcw&ved=2ahUKEwjpsuqexubsAhV3E7kGHX9xBE8QvS4wBXoECA8QGw&rldoc=1&tbs=lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:4&rlst=f#rlfi=hd:;si:15605914939069749384,l,Cg9kaXZhIGNvc21ldGljb3MiA4gBAVoiCg9kaXZhIGNvc21ldGljb3MiD2RpdmEgY29zbWV0aWNvcw;mv:[[-25.430154599999998,-49.2687163],[-25.431169699999998,-49.272388199999995]];tbs:lrf:!1m4!1u3!2m2!3m1!1e1!2m1!1e3!3sIAE,lf:1,lf_ui:4" target="_blank" rel="noopener noreferrer" title="Visualizar no Maps"> Ver no mapa <FontAwesomeIcon icon={faArrowRight}/> </a>
				</span> */}
        </div>
      </>
    );
  }
}

export default Lojas;
