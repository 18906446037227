import React, { Component } from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart as CartRedux, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import './index.scss';
import {Diversos} from '../../services/diversos';
import CartItem from '../CartItem';

//Bootstrap Components
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Badge from 'react-bootstrap/Badge'
declare var window;

interface StateProps { 
  cart: CartRedux;
  param: Param;
}

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {} 

type Props = StateProps & DispatchProps & OwnProps;

class Cart extends Component<Props> {

  state: any = null;

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  private getCartTotal () {
    const self = this;
    let total = 0.00;
    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      total += self.props.cart.produtos[i].price * self.props.cart.produtos[i].qty;
    }
    return total;
  }

  private getCartAmount () {
    const self = this;
    let amount = 0;
    for ( var i = 0; i < self.props.cart.produtos.length; i++ ) {
      amount += 1 * self.props.cart.produtos[i].qty;
    }

    return amount;
  }

  private handleCheckout () {
    const self = this;
    self.props.setParam({ ...self.props.param, cartOpened: false });
    setTimeout(() => {
      window.location.href = "/checkout";
    }, 200);
  }

  render () {
    return (
      <>
        <Modal.Header closeButton className="cart-header">
          <Modal.Title className="cart-title">
            Meu Carrinho <Badge pill variant="light">
              {/* {this.props.cart.produtos.length} */}
              { this.getCartAmount() }
            </Badge>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="cart-content">

          {
            ((this.props.cart.produtos) && (this.props.cart.produtos.length <= 0)) ? 
            <div className="empty-cart">
              <h4 className="empty-cart-title"> Seu carrinho está vazio </h4>
              <p className="empty-cart-text"> Navegue por nossa loja e encontre seus produtos favoritos! </p>
              <Button className="btn-secondary-action cart-keep-shopping"
              onClick={() => this.props.setParam({ ...this.props.param, cartOpened: false })}> Continuar comprando </Button>
            </div> 
            :
            <>
              <div className="cart-populated">
                {
                  this.props.cart.produtos.map((row, index) => (
                    <CartItem item={row} key={index}/>
                  ))
                }
              </div>
              <Container className="cart-continue-container">
                <Row className="mx-0 mt-auto">
                  <Col md={12} className="cart-continue">
                    <Row className="mx-0 w-100">
                      <span>
                        <h5 className="partial-total"> SUBTOTAL: <strong> R$ {Diversos.number_format(this.getCartTotal(),2,",","")} </strong> </h5>
                      </span>
                    </Row>
                    <Row md={12} className="m-auto w-100">
                      <Button className="cart-primary-action" onClick={this.handleCheckout.bind(this)}> Finalizar Compra </Button>
                    </Row>
                    <Row md={12}>
                      <Button className="btn-link-action cart-secondary-action" onClick={() => this.props.setParam({ ...this.props.param, cartOpened: false })}> Continuar comprando </Button>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </>
          }
        </Modal.Body>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cart);