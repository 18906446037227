import React from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import {Param} from '../../store/ducks/param/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Redirect} from 'react-router-dom';
import Api from '../../services/api';
import ApiRd from '../../services/api-rd';
import {Diversos} from '../../services/diversos';
import PasswordStrengthBar from 'react-password-strength-bar';

import Footer from '../../components/Footer';

import divaBranco from '../../assets/Images/logo-branco.png';
import divaRoxo from '../../assets/Images/logo.png';
import DivaFlower from '../../assets/Images/divaflower.png';

import InputMask from 'react-input-mask';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Tooltip from 'react-bootstrap/Tooltip';
import Toast from 'react-bootstrap/Toast';
import Col from 'react-bootstrap/Col';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {Spinner} from 'react-bootstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight, faArrowLeft, faEye, faEyeSlash, faSpinner, faCheck, faKey} from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps & any;

// const { password } = this.state;

class Cadastro extends React.Component<Props> {
  api: any = null;
  apiRd: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;
  formTop: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();
    this.apiRd = new ApiRd();

    this.novoCepRef = React.createRef();
    this.novoRuaRef = React.createRef();
    this.novoNumeroRef = React.createRef();
    this.novoBairroRef = React.createRef();
    this.novoCidadeRef = React.createRef();
    this.novoUfRef = React.createRef();
    this.novoComplementoRef = React.createRef();
    this.formTop = React.createRef();

    this.state = {
      redirect: null,
      hidden: true,
      confHidden: true,
      tooltipOpen: true,
      toastOpen: false,

      // VARIAVEIS DE CONTROLE PARA FORM DE NOVO CADASTRO
      isLoadingNovo: false,
      novoNome: null,
      novoSobrenome: null,
      novoCpf: null,
      novoTelefone: null,
      novoEmail: this.props.location.state && this.props.location.state.email ? this.props.location.state.email : null,
      novoSenha: null,
      novoConfSenha: null,
      novoCep: null,
      novoRua: null,
      novoNumero: null,
      novoBairro: null,
      novoCidade: null,
      novoEstado: null,
      novoComplemento: null,
      novoCodmun: null,
      novoHasError: false,
      novoHasErrorTitle: null,
      novoHasErrorMsg: null,
      currentStep: 1,
      email: '',
      username: '',
      password: '',
      checkingEmail: false,
      emailChecked: false,

      newsletter: false,
      phoneMask: '(99) 9999-99999',

      // VARIAVEIS DE CONTROLE PARA FORM DE CADASTRO JA EXISTENTE
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,

      isLoadingCep: false,
    };

    this.toggleShow = this.toggleShow.bind(this);
  }

  componentDidMount() {
    const self = this;

    if (self.props.user && self.props.user.status === true) {
      self.setState({redirect: '/meu-cadastro'});
    }

    self.setState({toastOpen: true});
  }

  private async getAddressByCep() {
    const self = this;

    if (!self.state.novoCep) return false;

    self.setState({isLoadingCep: true});

    let param = {cep: self.state.novoCep};

    try {
      const {data} = await self.api.post('/shipping/cep', param);

      if (data.status) {
        self.setState({
          novoRua: data.msg.logradouro,
          novoBairro: data.msg.bairro,
          novoCidade: data.msg.localidade,
          novoEstado: data.msg.uf,
          novoCodmun: data.msg.ibge,
        });
      }
    } catch (e) {
      console.error(`ERROR: /shipping/cep: ${e}}`);
    } finally {
      self.setState({isLoadingCep: false});
    }
  }

  scrollToRef = () => window.scrollTo(0, 0);

  private async validateEmail(event) {
    let timeout = 3000;

    this.setState({checkingEmail: true});

    setTimeout(() => this.setState({checkingEmail: false}), timeout);

    this.setState({emailChecked: true});

    setTimeout(() => this.setState({emailChecked: false}), 1500);
  }

  private async handleSubmitNovo(pressedButton) {
    const self = this;
    let paramNewsletter: any = {};

    this.scrollToRef();

    if (this.state.currentStep === 1) {
      if ((!self.state.novoEmail && !self.props.param.newUser) || !self.state.novoSenha) {
        self.setMsg('error', 'Formulário incompleto', `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      // if (self.state.novoSenha.length < 6) {
      //   self.setMsg('error', 'Segurança de Senha', `A senha escolhida é muito curta, necessário conter no mínimo 6 (seis) dígitos`, 2);
      //   return;
      // }

      // if (self.state.novoSenha.search(/[a-z]/) == -1 || self.state.novoSenha.search(/[A-Z]/) == -1) {
      //   self.setMsg('error', 'Segurança de Senha', `A senha escolhida deve conter letras maiúsculas e minúsculas`, 2);
      //   return;
      // }

      // if (self.state.novoSenha.search(/[0-9]/) == -1) {
      //   self.setMsg('error', 'Segurança de Senha', `A senha escolhida deve conter ao menos um número`, 2);
      //   return;
      // }

      if (!Diversos.validateEmail(self.state.novoEmail)) {
        self.setMsg('error', 'E-mail Inválido', 'Por favor, digite um E-mail válido para continuar', 2);
        return;
      }

      this.setState({novoHasError: false, currentStep: 2});
      return;
    }

    if (this.state.currentStep === 2) {
      if (pressedButton === 1) {
        this.setState({
          currentStep: this.state.currentStep - 1,
        });
        return;
      }

      if (((!self.state.novoNome || !self.state.novoSobrenome) && !self.props.param.newUser) || !self.state.novoCpf || !self.state.novoCelular) {
        self.setMsg('error', 'Formulário incompleto', `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }

      if (!Diversos.validateCPF(self.state.novoCpf)) {
        self.setMsg('error', 'CPF inválido', `Necessário informar um CPF válido para continuar.`, 2);
      }

      this.setState({novoHasError: false, currentStep: 3});
      return;
    }

    if (this.state.currentStep === 3) {
      if (pressedButton === 1) {
        this.setState({
          currentStep: this.state.currentStep - 1,
        });
        return;
      }

      if (!self.state.novoCep || !self.state.novoRua || !self.state.novoNumero || !self.state.novoBairro || !self.state.novoCidade || !self.state.novoEstado) {
        self.setMsg('error', 'Formulário incompleto', `Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.`, 2);
        return;
      }
    }

    // Workaround para adicionar o prefixo 9 para telefones celularaes
    if (self.state.novoCelular[5] >= 54) {
      self.setState({novoCelular: '9' + self.state.novoCelular});
      console.log(self.state.novoCelular);
    }

    self.setState({novoHasError: false, isLoadingNovo: true});

    let param = {
      email: self.state.novoEmail,
      senha: self.state.novoSenha,
      nome: self.state.novoNome + ' ' + self.state.novoSobrenome,
      cpf: self.state.novoCpf,
      nascimento: self.state.novoNascimento,
      telefone: self.state.novoTelefone,
      celular: self.state.novoCelular,
      cep: self.state.novoCep,
      rua: self.state.novoRua,
      numero: self.state.novoNumero,
      bairro: self.state.novoBairro,
      cidade: self.state.novoCidade,
      estado: self.state.novoEstado,
      complemento: self.state.novoComplemento,
      codmun: self.state.novoCodmun,
    };

    try {
      const {data} = await self.api.post('/customer', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        if (self.state.newsletter) {
          paramNewsletter = {
            nome: self.state.novoNome + ' ' + self.state.novoSobrenome,
            email: self.state.novoEmail,
            recebeEmail: 'S',
            data: new Date(),
          };

          const response = await self.api.post('/customer/newsletter', paramNewsletter);

          // if (!response.data.status) {
          //   throw new Error(response.data.msg);
          // }
        }

        self.props.doLogin({
          codigo: data.msg.codigo,
          nome: data.msg.nome,
          email: data.msg.email,
          cpf: data.msg.cpf,
          status: true,
          avatar: '',
          token: null,
        });

        setTimeout(() => {
          self.setState({
            redirect: '/',
          });
        }, 400);
      }
    } catch (e) {
      console.error(e);
      self.setMsg('error', 'Atenção', `Não foi possível criar conta. ${e.message}`, 2);
    } finally {
      self.setState({isLoadingNovo: false});
    }
  }

  private async setMsg(type: string, title: string, msg: string, form: number) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      if (form === 1) {
        self.setState(
          {
            customerHasError: true,
            customerHasErrorTitle: title,
            customerHasErrorMsg: msg,
          },
          () => {
            setTimeout(() => self.setState({customerHasError: false}), timeout);
          },
        );
      } else {
        self.setState(
          {
            novoHasError: true,
            novoHasErrorTitle: title,
            novoHasErrorMsg: msg,
          },
          () => {
            setTimeout(() => self.setState({novoHasError: false}), timeout);
          },
        );
      }
    } else {
      if (form === 1) {
        self.setState(
          {
            customerHasSuccess: true,
            customerHasSuccessTitle: title,
            customerHasSuccessMsg: msg,
          },
          () => {
            setTimeout(() => self.setState({customerHasSuccess: false}), timeout);
          },
        );
      } else {
        self.setState(
          {
            novoHasSuccess: true,
            novoHasSuccessTitle: title,
            novoHasSuccessMsg: msg,
          },
          () => {
            setTimeout(() => self.setState({novoHasSuccess: false}), timeout);
          },
        );
      }
    }
  }

  toggleShow(input: number) {
    if (input === 1) {
      this.setState({hidden: !this.state.hidden});
      return;
    }

    this.setState({confHidden: !this.state.confHidden});
  }

  progressBar() {
    var progress = 0;
    const tax = 6.65;

    if (this.props.param.newUser) progress += tax * 2;

    if (this.state.novoEmail) {
      progress += tax;
    }
    if (this.state.novoSenha) {
      progress += tax;
    }
    if (this.state.novoConfSenha) {
      progress += tax;
    }
    if (this.state.novoNome) {
      progress += tax;
    }
    if (this.state.novoCpf) {
      progress += tax;
    }
    if (this.state.novoNascimento) {
      progress += tax;
    }
    if (this.state.novoCelular) {
      progress += tax;
    }
    if (this.state.novoTelefone) {
      progress += tax;
    }
    if (this.state.novoCep) {
      progress += tax;
    }
    if (this.state.novoRua) {
      progress += tax;
    }
    if (this.state.novoNumero) {
      progress += tax;
    }
    if (this.state.novoBairro) {
      progress += tax;
    }
    if (this.state.novoCidade) {
      progress += tax;
    }
    if (this.state.novoEstado) {
      progress += tax;
    }
    if (this.state.novoComplemento) {
      progress += tax;
    }

    return progress;
  }

  setPasswordStrenght(score) {
    const self = this;

    if (self.state.novoSenha) {
      score += 1;

      if (self.state.novoSenha.length >= 6) {
        score += 2;
      }

      if (!(self.state.novoSenha.search(/[a-z]/) == -1 || self.state.novoSenha.search(/[A-Z]/) == -1)) {
        score += 3;
      }

      if (!(self.state.novoSenha.search(/\d/) == -1)) {
        score += 2;
      }
    }

    return score;
  }

  toggleToast() {
    this.setState({toastOpen: !this.state.toastOpen});
  }

  handlePhoneMask() {
    let phone: string = Diversos.getnums(this.state.novoCelular);

    console.log(Diversos.getnums(this.state.novoCelular).length);

    if (phone.length === 11) {
      this.setState({phoneMask: '(99) 9 9999-9999'});
    } else {
      this.setState({phoneMask: '(99) 9999-9999'});
    }
  }

  private async _handleCheckCpf() {
    const self = this;
    self.setState({novoHasError: false});

    if (Diversos.getnums(self.state.novoCpf).length === 11 && !Diversos.validateCPF(self.state.novoCpf)) {
      self.setMsg('error', 'Atenção', 'O CPF informado não é válido.', 2);
    } else if (Diversos.validateCPF(self.state.novoCpf)) {
      let param = {
        field: 'cpf',
        value: self.state.novoCpf,
      };

      try {
        const {data} = await self.api.post(`/customer/check-customer`, param);

        if (data.status === true) {
          self.setMsg('error', 'Atenção', 'O CPF informado já possui um cadastro no site.', 2);
        }
      } catch (e) {
        // nao faz nada passou...
      }
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const smDevice = window.innerWidth <= 576;
    const progress = Math.floor(this.progressBar());

    return (
      <>
        {this.state.novoHasError && (
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.novoHasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.novoHasErrorMsg} </p>
          </div>
        )}

        <section className="user-pages sign-up" style={{minHeight: 1000}}>
          <div className="form-bg" style={{flexDirection: this.state.isLoading ? 'column' : 'row', alignItems: this.state.isLoading ? 'center' : ''}}>
            <a href="/">
              {' '}
              <img src={smDevice ? divaRoxo : divaBranco} className="user-page-logo" alt="Depimiel logo" />{' '}
            </a>
            {this.state.isLoading ? (
              <div className="d-flex w-100 mt-5" style={{alignItems: 'center', justifyContent: 'center'}}>
                <i className="fas fa-spin fa-spinner fa-3x"></i>
              </div>
            ) : (
              <>
                <article className="user-form-container">
                  <h1>
                    {`
                  Olá${this.props.user.nome || ''}! ${this.props.param.newUser ? 'Conclua' : 'Faça'} seu cadastro na Depimiel!
                `}
                  </h1>
                  {/* <span className="sign-up-progress">
                  <small>
                    Etapa {this.state.currentStep} de 3
                    {this.state.currentStep === 3 && ' - Quase lá! :)'}
                  </small>
                  <ProgressBar animated min={0} now={progress} label={`${progress} %`} style={{backgroundColor: '#f3f3f4'}}/>
                </span> */}
                  <Form className="d-flex flex-column w-100 mt-3">
                    {this.state.currentStep !== 1 ? null : (
                      <>
                        {!this.props.param.newUser ? (
                          <Form.Row ref={this.formTop}>
                            <Form.Group as={Col}>
                              <Form.Label> Email *</Form.Label>
                              {(this.state.checkingEmail || this.state.emailChecked) && (
                                <FontAwesomeIcon icon={this.state.emailChecked ? faSpinner : faCheck} className="pswd-addon" spin={this.state.emailChecked ? true : false} />
                              )}
                              <Form.Control
                                type="email"
                                name={'email'}
                                placeholder="Email"
                                value={this.state.novoEmail || ''}
                                onChange={(event) => this.setState({novoEmail: event.target.value})}
                                onBlur={this.validateEmail.bind(this)}
                                required
                              />
                            </Form.Group>
                          </Form.Row>
                        ) : (
                          <p style={{fontWeight: 'lighter', textAlign: 'center'}}> Escolha uma senha forte para continuar </p>
                        )}

                        <Form.Row>
                          <Form.Group as={Col} style={{marginBottom: '.5rem'}}>
                            <Form.Label> Senha *</Form.Label>
                            <FontAwesomeIcon icon={this.state.hidden ? faEyeSlash : faEye} className="pswd-addon" onClick={this.toggleShow.bind(this, 1)} />
                            <Form.Control
                              type={this.state.hidden ? 'password' : 'text'}
                              name={'senha'}
                              placeholder="Senha"
                              value={this.state.novoSenha || ''}
                              onChange={(event) => this.setState({novoSenha: event.target.value})}
                              required
                              disabled={this.state.emailChecked && true}
                            />
                          </Form.Group>
                          {/* <span onClick={this.toggleToast.bind(this)} style={{width: '100%'}}>
                            <PasswordStrengthBar
                              password={this.state.novoSenha ? this.state.novoSenha : ''}
                              minLength={6}
                              scoreWords={['fraca', 'média', 'forte', 'muito forte']}
                              shortScoreWord="muito fraca"
                              onChangeScore={(score) => {
                                this.setPasswordStrenght(score);
                              }}
                              style={{display: 'block', width: '100%', paddingLeft: '5px', paddingRight: '5px'}}
                            />
                          </span> */}
                        </Form.Row>

                        {/* <Form.Row className="pswd-requirements">
                          <Form.Text muted className={this.state.novoSenha && this.state.novoSenha.length >= 6 ? 'achieved' : ''}>
                            Mínimo 6 dígitos
                          </Form.Text>
                          <Form.Text
                            muted
                            className={this.state.novoSenha && !(this.state.novoSenha.search(/[a-z]/) == -1 || this.state.novoSenha.search(/[A-Z]/) == -1) ? 'achieved' : ''}
                          >
                            Letras maiúsculas e minúsculas
                          </Form.Text>
                          <Form.Text muted className={this.state.novoSenha && !(this.state.novoSenha.search(/\d/) == -1) ? 'achieved' : ''}>
                            Números
                          </Form.Text>
                        </Form.Row> */}
                      </>
                    )}
                    {this.state.currentStep !== 2 ? null : (
                      <>
                        <Form.Row ref={this.formTop}>
                          <Form.Group as={Col}>
                            <Form.Label> Nome *</Form.Label>
                            <Form.Control
                              type="text"
                              name={'nome'}
                              placeholder="Nome"
                              value={this.state.novoNome || ''}
                              onChange={(event) => this.setState({novoNome: event.target.value})}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label> Sobrenome *</Form.Label>
                            <Form.Control
                              type="text"
                              name={'sobrenome'}
                              placeholder="Sobrenome"
                              value={this.state.novoSobrenome || ''}
                              onChange={(event) => this.setState({novoSobrenome: event.target.value})}
                              required
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> CPF *</Form.Label>
                            <InputMask
                              name={'cpf'}
                              mask="999.999.999-99"
                              value={this.state.novoCpf || ''}
                              onChange={(event) => this.setState({novoCpf: event.target.value}, this._handleCheckCpf.bind(this))}
                            >
                              {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="000.000.000-00" required />}
                            </InputMask>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> Data de Nascimento </Form.Label>
                            <InputMask
                              name={'nascimento'}
                              mask="99/99/9999"
                              value={this.state.novoNascimento || ''}
                              onChange={(event) => this.setState({novoNascimento: event.target.value})}
                            >
                              {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="99/99/9999" />}
                            </InputMask>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> Celular *</Form.Label>
                            <InputMask
                              name={'celular'}
                              mask="(99) 9 9999-9999"
                              value={this.state.novoCelular || ''}
                              onChange={(event) => this.setState({novoCelular: event.target.value})}
                            >
                              {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(41) 9-9999-9999" required />}
                            </InputMask>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} sm={6}>
                            <Form.Label> Telefone 2 </Form.Label>
                            <InputMask
                              name={'telefone'}
                              mask={this.state.phoneMask}
                              value={this.state.novoTelefone || ''}
                              onBlur={this.handlePhoneMask.bind(this)}
                              onChange={(event) => this.setState({novoTelefone: event.target.value})}
                            >
                              {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(00) 0000-0000" />}
                            </InputMask>
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                    {this.state.currentStep !== 3 ? null : (
                      <>
                        <Form.Row ref={this.formTop}>
                          <Form.Group as={Col} xs={12}>
                            <Form.Label> CEP * </Form.Label>
                            <InputMask
                              name={'cep'}
                              mask="99.999-999"
                              ref={this.novoCepRef}
                              value={this.state.novoCep || ''}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => {
                                let tmp = Diversos.getnums(event.target.value);
                                if (tmp.length >= 8) this.setState({novoCep: event.target.value}, this.getAddressByCep.bind(this));
                                else this.setState({novoCep: event.target.value});
                              }}
                            >
                              {(inputProps) => <Form.Control {...inputProps} type="text" placeholder="00.000-000" required />}
                            </InputMask>
                            {this.state.isLoadingCep && (
                              <Form.Text className={'text-muted'}>
                                <i className={'fas fa-spin fa-spinner mr-1'}></i> Pesquisando CEP, por favor aguarde...
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col} xs={12} sm={8}>
                            <Form.Label> Rua * </Form.Label>
                            <Form.Control
                              name={'endereco'}
                              placeholder="Avenida Paulista"
                              value={this.state.novoRua || ''}
                              ref={this.novoRuaRef}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => this.setState({novoRua: event.target.value})}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col} xs={12} sm={4}>
                            <Form.Label> Número * </Form.Label>
                            <Form.Control
                              name={'numero'}
                              value={this.state.novoNumero || ''}
                              ref={this.novoNumeroRef}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => this.setState({novoNumero: event.target.value})}
                              required
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> Bairro * </Form.Label>
                            <Form.Control
                              name={'bairro'}
                              value={this.state.novoBairro || ''}
                              ref={this.novoBairroRef}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => this.setState({novoBairro: event.target.value})}
                              required
                            />
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> Cidade * </Form.Label>
                            <Form.Control
                              name={'cidade'}
                              placeholder="São Paulo"
                              value={this.state.novoCidade || ''}
                              ref={this.novoCidadeRef}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => this.setState({novoCidade: event.target.value})}
                              required
                            />
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label> Estado * </Form.Label>
                            <Form.Control
                              name={'uf'}
                              as="select"
                              value={this.state.novoEstado || ''}
                              ref={this.novoUfRef}
                              disabled={this.state.isLoadingCep}
                              onChange={(event) => this.setState({novoEstado: event.target.value})}
                              required
                            >
                              {Diversos.getUFs().map((row, index) => (
                                <option key={index} value={row.value || ''}>
                                  {' '}
                                  {row.label}{' '}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group as={Col}>
                            <Form.Label> Complemento </Form.Label>
                            <Form.Control
                              name={'complemento'}
                              placeholder="Ap. 7"
                              value={this.state.novoComplemento || ''}
                              onChange={(event) => this.setState({novoComplemento: event.target.value})}
                            />
                          </Form.Group>
                        </Form.Row>
                      </>
                    )}
                  </Form>
                  <Row className="multistep-controller">
                    {this.state.currentStep !== 1 ? (
                      <button className="btn btn-link-action text-center mx-2" type="button" onClick={this.handleSubmitNovo.bind(this, 1)}>
                        <FontAwesomeIcon icon={faArrowLeft} className="mx-1" /> Voltar
                      </button>
                    ) : null}
                    {this.state.currentStep < 3 ? (
                      <button className="btn btn-link-action mx-2" type="button" onClick={this.handleSubmitNovo.bind(this, 2)}>
                        Próximo <FontAwesomeIcon icon={faArrowRight} className="mx-1" />
                      </button>
                    ) : null}
                  </Row>
                  {this.state.currentStep === 3 ? (
                    <>
                      <Form.Check
                        custom
                        id="custom-checkbox"
                        className="custom-checkbox-1"
                        label={`Quero receber novidades, promoções e ofertas personalizadas da Depimiel.`}
                        onSelect={() => this.setState({newsletter: true})}
                      />
                      <small className="terms-of-usage">
                        {' '}
                        Ao continuar, você concorda com nossos{' '}
                        <a target="_blank" href="/politica-de-privacidade">
                          {' '}
                          Termos de Uso{' '}
                        </a>{' '}
                      </small>
                      <button
                        className="btn-primary-action btn-user"
                        style={{marginTop: 0}}
                        title="Fazer Login"
                        onClick={this.handleSubmitNovo.bind(this)}
                        disabled={this.state.isLoadingNovo}
                      >
                        {this.state.isLoadingNovo ? (
                          <Spinner animation="border" role="status" variant="light" size="sm">
                            <span className="sr-only"> Loading... </span>
                          </Spinner>
                        ) : (
                          'Cadastrar'
                        )}
                      </button>
                    </>
                  ) : null}
                </article>
              </>
            )}
          </div>
          {!this.state.isLoading && (
            <h3 className={this.state.currentStep === 2 ? 'mrgn-mod-1' : this.state.currentStep === 3 ? 'mrgn-mod-2' : 'mrgn-mod'}>
              {' '}
              Possui uma conta? <a href="/login"> Faça Login </a>{' '}
            </h3>
          )}
        </section>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cadastro);
