import React, {Component} from 'react';
import './App.scss';

import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {BrowserRouter as Router, Route, Link, RouteComponentProps, Switch, Redirect} from 'react-router-dom';
import {store, persistor} from '../store';

import Home from './Home';
import Blog from './Blog';
import Produto from './Produto';
import ResetSenha from './ResetSenha';
import Login from './Login';
import Cadastro from './Cadastro';
import MeuCadastro from './MeuCadastro';
import MeusPedidos from './MeusPedidos';
import TrocasDevolucoes from './TrocasDevolucoes';
import FreteEnvio from './FreteEnvio';
import Departamento from './Departamento';
import Promocao from './Promocao';
import Diva from './Diva';
import Lojas from './Lojas';
import OfertasDoMes from './OfertasDoMes';
import Pesquisa from './Pesquisa';
import Checkout from './Checkout';
import CheckoutPagamento from './CheckoutPagamento';
import CheckoutFim from './CheckoutFim';
import NoMatchPage from './NoMatchPage';
import FAQ from './FAQ';
import Marcas from './Marcas';
import DetalheMarca from './DetalheMarca';
import Institucional from './Institucional';
import EdicaoPerfil from './EdicaoPerfil';
import Privacidade from './PoliticaDePrivacidade';
import EmManutencao from './EmManutencao';

class App extends Component<{}> {
  private myRouters() {
    return (
      <Switch>
        {/* DECLARA AS ROTAS USADAS NO PROJETO */}
        <Route path="/" exact strict component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/blog" exact component={Blog} />
        <Route path="/cadastro" exact component={Cadastro} />
        <Route path="/meu-cadastro" exact component={MeuCadastro} />
        <Route path="/reset-senha" exact component={EdicaoPerfil} />
        <Route path="/editar/perfil" exact component={EdicaoPerfil} />
        <Route path="/editar/pessoal" exact component={EdicaoPerfil} />
        <Route path="/editar/endereco" exact component={EdicaoPerfil} />
        <Route path="/meus-pedidos" exact component={MeusPedidos} />
        <Route path="/pedido/:pedido" exact component={MeusPedidos} />
        <Route path="/trocas-e-devolucoes" exact component={TrocasDevolucoes} />
        <Route path="/frete-e-envio" exact component={FreteEnvio} />
        <Route path="/recuperar-senha" exact component={ResetSenha} />
        <Route path="/departamento/:menu1" exact strict component={Departamento} />
        <Route path="/departamento/:menu1/:menu2" exact strict component={Departamento} />
        <Route path="/departamento/:menu1/:menu2/:menu3" exact strict component={Departamento} />
        <Route path="/busca/:termo" exact strict component={Pesquisa} />
        <Route path="/promocoes" exact strict component={Promocao} />
        <Route path="/lojas" exact strict component={Lojas} />
        <Route path="/ofertas-do-mes" exact strict component={OfertasDoMes} />
        <Route path="/checkout" exact strict component={Checkout} />
        <Route path="/checkout/entrega" exact strict component={CheckoutPagamento} />
        <Route path="/checkout/fim" exact strict component={CheckoutFim} />
        <Route path="/departamento" exact strict component={Departamento} />
        <Route path="/central-de-relacionamento" exact strict component={FAQ} />
        <Route path="/marcas" exact strict component={Marcas} />
        <Route path="/marca/:marca" exact strict component={DetalheMarca} />
        <Route path="/institucional" exact strict component={Institucional} />
        <Route path="/institucional/sobre" exact strict component={Institucional} />
        <Route path="/institucional/lojas" exact strict component={Institucional} />
        <Route path="/institucional/saloes" exact strict component={Institucional} />
        <Route path="/institucional/televendas" exact strict component={Institucional} />
        <Route path="/institucional/atendimento" exact strict component={Institucional} />
        <Route path="/politica-de-privacidade" exact strict component={Privacidade} />
        <Route path="/:nome" exact component={Produto} />
        <Route component={NoMatchPage} />
        {/* <Route component={EmManutencao} /> */}
      </Switch>
    );
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>{this.myRouters()}</Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
