import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import queryString from 'query-string';
import Pagination from 'react-js-pagination';
import Shimmer from '@bit/aju.main.shimmer';
import {Helmet} from 'react-helmet';
import {ApplicationState} from '../../store';
import * as UserActions from '../../store/ducks/user/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import './index.scss';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import {User, UserTypes} from '../../store/ducks/user/types';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FilterModal from '../../components/FilterModal';
import Product from '../../components/Product';
import FilterSidebar from '../../components/FilterSidebar';
import CategoryBanner from '../../assets/Images/category-banner.png';
import BadSearch from '../../assets/Images/no-results.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowDown, faChevronDown, faChevronUp, faFilter} from '@fortawesome/free-solid-svg-icons';
declare var window;

interface RouteParams {
  menu1: string;
  menu2: string;
  menu3: string;
}

interface StateProps {
  user: User;
}

type Props = StateProps;

class Pesquisa extends Component<Props> {
  api: any = null;
  state: any = {};

  constructor(props) {
    super(props);
    this.api = new Api();
    const {pag, marcas, preco, sort} = queryString.parse(props.location.search);
    const {termo} = props.match.params;

    const smDevice = window.innerWidth <= 576;
    const mdDevice = window.innerWidth <= 1024;
    let perPage = 27;

    if (smDevice) {
      perPage = 20;
    }

    this.state = {
      paramTermo: termo.replace(/-/g, ' '),
      isLoadingMenu: false,
      isLoadingProdutos: false,
      produtos: [],
      page: pag ? pag : 1,
      produtosLastPage: 1,
      produtosPerPage: perPage,
      produtosTotal: 1,
      brandValues: [],
      brands: [],
      filtroMarcas: !marcas ? [] : Array.isArray(marcas) ? marcas : [marcas],
      filtroPrecos: !preco ? [] : preco.toString().split('-'),
      loginToFav: false,
      sort: !sort ? '' : sort,

      menu1: null,
      menu2: null,
      menu3: null,
    };
  }

  async componentDidMount() {
    const self = this;
    self.getProdutos();

    if (!self.props.user || self.props.user.status !== true) self.setState({loginToFav: true});
  }

  // FUNCAO PARA BUSCAR LISTA DE PRODUTOS NA API
  private async getProdutos() {
    const self = this;
    const {marcas, preco, pag, sort} = queryString.parse(window.location.search);
    let filtros: any = {};

    if (marcas) filtros = {...filtros, marcas};

    if (preco) filtros = {...filtros, preco};

    if (sort) filtros = {...filtros, sort};

    filtros = {...filtros, perPage: self.state.produtosPerPage};

    self.setState({isLoadingProdutos: true});

    let param: any = {
      page: self.state.page,
      termo: self.state.paramTermo,
    };

    if (marcas) {
      param.filterMarca = marcas;
    }

    if (preco) {
      param.filterPreco = preco;
    }

    if (sort) {
      param.orderByParam = sort;
    }

    try {
      const {data} = await self.api.post(`/product/search-dicionario/${self.state.page}?${queryString.stringify(filtros)}`, param);

      if (!data.status) {
        throw new Error(`Falha ao buscar produtos`);
      }

      let marcas: any = [];

      for (let i = 0; i < data.marcas.length; i++) {
        marcas.push({MARCA: data.marcas[i].labor});
      }

      self.setState({
        produtos: data.msg.data,
        produtosLastPage: data.msg.lastPage,
        page: data.msg.page,
        produtosPerPage: data.msg.perPage,
        produtosTotal: data.msg.total,
        brandValues: data.msg.data.marca,
        marcas: marcas ? marcas : [],
      });

      self.handleMenu();
    } catch (e) {
      console.error(e);
      self.setState({produtos: []});
    } finally {
      self.setState({isLoadingProdutos: false});
    }

    self.brandsList();
  }

  private async brandsList() {
    const self = this;
    let marcas = [];

    for (var i = 0; i < self.state.produtos.length; i++) {
      self.setState({
        brands: [...self.state.brands, self.state.produtos[i].MARCA],
      });
    }
  }

  // FAZ TRATAMENTO DO LINK PARA REDIRECIONAMENTO DE PAGINACAO
  private handlePagination(pag: number) {
    const self = this;
    let link = window.location.pathname;
    const param = queryString.parse(window.location.search);
    const brands = self.state.brandValues;
    const price = self.state.priceValue;

    let newsParam = param;

    if (brands) newsParam = {...newsParam, marcas: brands};

    if (price) newsParam = {...newsParam, preco: price};

    if (pag <= 1 || !pag) newsParam = {...newsParam, pag: '1'};
    else if (pag > self.state.produtosLastPage) newsParam = {...newsParam, pag: self.state.produtosLastPage};
    else newsParam = {...newsParam, pag: pag.toString()};

    if (self.state.sort && self.state.sort !== 'default') newsParam = {...newsParam, sort: self.state.sort};

    window.location.href = queryString.stringifyUrl({url: link, query: newsParam});
  }

  private async handleMenu() {
    const self = this;

    self.setState({isLoadingMenu: true});

    try {
      const {data} = await self.api.get(`/menu/nivel1`);

      if (!data.status) {
        throw new Error(`Falha ao buscar dados do menu`);
      }

      self.setState({
        menu1: data.msg,
        menu2: {},
        menu3: {},
        menu3Selected: null,
      });
    } catch (e) {
      console.error(e);
    } finally {
      self.setState({isLoadingMenu: false});
    }
  }

  private handleLoadMore() {
    const self = this;

    if (self.state.produtos.length >= 24) {
      self.setState({produtosPerPage: !self.state.produtosPerPage});
      return;
    }

    self.setState({produtosPerPage: true});
  }

  private _henderSEOTags() {
    if (this.state.isLoadingProdutos) {
      return <></>;
    } else {
      const title = `${Diversos.capitalize(this.state.paramTermo)} - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/${window.location.pathname}`;
      const description = `Melhores ofertas de ${this.state.paramTermo} você encontra na ${process.env.REACT_APP_TITLE}`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index" />
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="719113475453514" />
          <meta property="og:site_name" content={title} />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={require(`../../assets/Images/logo.png`)} />
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
      );
    }
  }

  render() {
    const mdDevice = window.innerWidth <= 992;

    return (
      <>
        {this._henderSEOTags()}

        <Header searchTermo={this.state.paramTermo} />
        <section className="departamento">
          <Row className="mx-0 departamento-container">
            {this.state.isLoadingMenu || this.state.isLoadingProdutos ? (
              <p className="loading-content">
                {' '}
                <i className="fas fa-spin fa-spinner"></i>{' '}
              </p>
            ) : this.state.produtos.length !== 0 ? (
              <>
                <Col lg={3} xl={3} className="filter-col">
                  {mdDevice ? (
                    <Row className="mx-0 d-block d-lg-none filter-mobile">
                      <button className="btn-link-action" title="Abrir opções de filtros" onClick={() => this.setState({showModal: true})}>
                        <FontAwesomeIcon icon={faFilter} />
                        <p> Filtrar </p>
                      </button>
                    </Row>
                  ) : (
                    <>
                      <FilterSidebar
                        title={this.state.paramTermo}
                        marcas={this.state.marcas}
                        marca={this.state.brands.map((row, index) => row)}
                        menu1={this.state.menu1}
                        menu2={''}
                        menu3={''}
                        ehPesquisa={true}
                      />
                    </>
                  )}
                </Col>

                <Col lg={9} className="products-col">
                  {/* <aside className="category-img-container">
                        <img src={CategoryBanner} alt="Categoria Cabelos - Kits de tratamento para Cabelos" />
                      </aside> */}
                  <aside className="sort-products">
                    <p>
                      {' '}
                      <span> Total de </span> {this.state.produtosTotal} produtos{' '}
                    </p>
                    <Form inline>
                      <Form.Label> Ordenar por: </Form.Label>
                      <Form.Control
                        as="select"
                        size="sm"
                        custom
                        className="sort-select"
                        value={this.state.sort}
                        onChange={(event) => this.setState({sort: event.target.value}, this.handlePagination.bind(this, 1))}
                      >
                        <option value={'default'}> Relevância </option>
                        <option value={'vendas_desc'}> Mais vendidos </option>
                        <option value={'preco_asc'}> Menor Preço </option>
                        <option value={'preco_desc'}> Maior Preço </option>
                        <option value={'nome_asc'}> Alfabética A-Z </option>
                        <option value={'nome_desc'}> Alfabética Z-A </option>
                        <option value={'stock_desc'}> Em estoque </option>
                      </Form.Control>
                    </Form>
                  </aside>
                  <div className="vitrine">
                    <h2> Confira os melhores produtos </h2>
                  </div>
                  <Row className="mx-0">
                    {this.state.produtos.map((row, index) => (
                      // this.state.produtos.slice(0, numeroProdutos).map((index) => (
                      <Col xs={6} sm={6} md={6} xl={4} key={index} className="px-0 mb-5 d-flex justify-content-center">
                        <Product
                          imageNormal={
                            row.FOTOS && row.FOTOS.length > 0
                              ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                              : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          }
                          imageOver={
                            row.FOTOS && row.FOTOS.length > 0
                              ? row.FOTOS.length > 1
                                ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                                : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                              : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                          }
                          item={row}
                          fav={this.state.loginToFav}
                        />
                      </Col>
                    ))}
                  </Row>

                  {/* PAGINACAO */}
                  {this.state.produtos.length <= 0 ? null : (
                    <Row>
                      <Col className="mt-1 mb-1 d-flex justify-content-center">
                        <Pagination
                          activePage={this.state.page}
                          itemsCountPerPage={this.state.produtosPerPage}
                          totalItemsCount={this.state.produtosTotal}
                          pageRangeDisplayed={5}
                          onChange={this.handlePagination.bind(this)}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </Col>
                    </Row>
                  )}

                  {/* <button className="btn-load-more" onClick={this.handleLoadMore.bind(this)}>
                        {
                          !this.state.produtosPerPage ?
                            <>
                              <h4> Carregar mais produtos </h4>
                              <FontAwesomeIcon icon={faChevronDown} />
                            </>
                            :
                            <>
                              <h4> Carregar menos </h4>
                              <FontAwesomeIcon icon={faChevronUp} />
                            </>
                        }
                      </button> */}

                  {/* <article className="category-text">
                        <h5> Tudo sobre cabelos loiros </h5>
                        <p>
                          Quem tem cabelos loiros conhece a importância de buscar produtos que ofereçam cuidados
                          especiais, pois essa tonalidade exige uma atenção mais regrada e itens específicos para
                          mantê-los com uma boa aparência.
                        </p>
                        <p>
                          Principalmente quando se trata dos cabelos loiros tingidos, descoloridos, com luzes ou mechas,
                          é preciso adotar algumas medidas que irão reconstruir e hidratar os fios, pois eles estão
                          sujeitos a maiores danos.
                        </p>
                        <p>
                          <strong> Cuidados com os cabelos loiros </strong>
                          São vários os cuidados que devem ser tomados com os cabelos loiros. Antes mesmo de falar de
                          produtos, é preciso entender que devem ser evitados o uso excessivo de fontes de calor
                          — como secadores e pranchas alisadoras —, pois elas podem prejudicar os fios, já que eles
                          estão mais suscetíveis a danos devido aos efeitos da descoloração.
                        </p>
                        <p>
                          Antes de fazer as descolorações e utilizar tintura é preciso dar uma atenção especial à rotina
                          de hidratação, nutrição e reconstrução, ou seja, ao cronograma capilar. É importante para a
                          sua saúde dos fios manter esses cuidados, principalmente se você passa por procedimentos
                          químicos, como alisamentos.
                        </p>
                        <p>
                          Para não apenas deixá-los hidratados e com uma aparência saudável, mas também para manter a
                          cor desejada, um dos truques é utilizar produtos que tenham propriedades desamareladoras.
                          Nesta categoria, é possível encontrar tanto cremes matizantes quanto kits de shampoo roxo
                          combinados com condicionador e máscara.
                        </p>
                        <p>
                          Uma outra forma de deixar os fios num tom desejado é com o uso de produtos de camomila.
                          Indicado principalmente para quem tem cabelos loiros naturais, esses itens prometem dar
                          um toque mais suave e um clareamento natural, dispensando o uso de elementos químicos —
                          além de deixar um cheirinho maravilhoso.
                        </p>
                        <p>
                          Se você está buscando produtos feitos para cuidar de cabelos loiros, não perca tempo!
                          Navegue em nossa loja Beleza na Web e encontre os itens que mais são adequados para deixar
                          os seus fios lindos e bem tratados!
                        </p>
                      </article> */}
                </Col>
              </>
            ) : (
              <>
                <article className="bad-search">
                  <img src={BadSearch} alt="Sem Resultados" />
                  <h3> Não encontramos resultados que correspondem à sua busca </h3>
                </article>
              </>
            )}
          </Row>
        </section>
        <Footer />

        {/* Cria modal dos filtros */}
        <Modal show={this.state.showModal} onHide={() => this.setState({showModal: false})} dialogClassName="modal-100w" aria-labelledby="filter-modal" className="filter-modal">
          <FilterModal
            // handleFilter={this.handlePagination.bind(this)}
            title={this.state.paramTermo}
            marcas={this.state.marcas}
            marca={this.state.brands.map((row, index) => row)}
            menu1={this.state.menu1}
            menu2={''}
            menu3={''}
            ehPesquisa={true}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pesquisa);
