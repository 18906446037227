import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import './index.scss';
import App from './pages/App';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab);

// const tagManagerArgs = {
//   gtmId: 'GTM-P7FFP62'
// }

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
