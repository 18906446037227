import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import Header from '../Header';
import Footer from '../Footer';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';

import InputMask from 'react-input-mask'
import Row from 'react-bootstrap/Row'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import {Spinner} from 'react-bootstrap'

import logoPreta from '../../assets/Images/domakoski-preta.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'


interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  doLogout(): void;

  setParam(data: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;


class InfoEdit extends React.Component<Props> {

  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,

      isLoading: false,
      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,
      nome: "",
      sobrenome: "",
      phoneMask: '(99) 9999-99999',
    }
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) {
      self.getCustomer();
    }
  }

  private async getCustomer () {
    const self = this;

   self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({
          customer: data.msg,
          nome: data.msg.nome.split(" ")[0],
          sobrenome: data.msg.nome.split(" ")[1],
        });
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleSubmit () {
    const self = this;
    let param: any = {};

    if ((( !self.state.nome ) || ( !self.state.sobrenome ) || ( !self.state.customer.cpf ) ||
    ( !self.state.customer.telefone )) && ( !self.props.param.newUser )) {
      self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
      return;
    }

    if ( !Diversos.validateCPF(self.state.customer.cpf) ) {
      self.setMsg( 'error', "CPF inválido", "Necessário informar um CPF válido para continuar.");
      return;
    }

    param.nome = self.state.nome +" "+ self.state.sobrenome;
    param.cpf = self.state.customer.cpf;
    param.nascimento = self.state.customer.nascimento;
    param.sexo = self.state.customer.sexo;
    param.celular = self.state.customer.celular;
    param.telefone = self.state.customer.telefone;

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(`/customer/${self.props.user.codigo}`, param);

      console.log(data.msg);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`);
        self.props.setParam({
          ...self.props.param,
          newUser: false
        });

        if ((data.msg.googleid !== "0") || (data.msg.facebookid !== "0")) {
          self.setState({ redirect: '/' });
        }
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`);
    } finally {
      self.setState({ isLoading: false, redirect: "/meu-cadastro" });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type
   * @param title
   * @param msg
   */
  private async setMsg ( type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {

      self.setState({
        customerHasError: true,
        customerHasErrorTitle: title,
        customerHasErrorMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasError: false }), timeout)
      });

    } else {

      self.setState({
        customerHasSuccess: true,
        customerHasSuccessTitle: title,
        customerHasSuccessMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
      });

    }
  }

  handlePhoneMask () {
    let phone: string = Diversos.getnums(this.state.customer.telefone);

    console.log(Diversos.getnums(this.state.customer.telefone).length);

    if (phone.length === 11) {
      this.setState({ phoneMask: '(99) 9 9999-9999' })
    } else {
      this.setState({ phoneMask: '(99) 9999-9999' })
    }
  }

  render () {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <>
        {
          this.state.isLoadingCustomer === true ?
          <span className="py-3 w-100 m-auto text-center d-flex justify-content-center">
            <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-center m-auto spinner" style={{fontSize: 24}}/>
          </span>
          :
          <>

            {
            this.state.customerHasError &&
            <div className="alert-box error-box">
              <p className="login-error-title"> {` - ${this.state.customerHasErrorTitle} - `} </p>
              <p className="alert-msg"> {this.state.customerHasErrorMsg} </p>
            </div>
            }

            {
            this.state.customerHasSuccess &&
            <div className="alert-box success-box">
              <p className="login-success-title"> {` - ${this.state.customerHasSuccessTitle} - `} </p>
              <p className="alert-msg"> {this.state.customerHasSuccessMsg} </p>
            </div>
            }

            <div className="data-input-wrapper">
            {
              !this.props.param.newUser ?
              <>
                <Form.Row className="mx-auto">
                  <Form.Group as={Col} controlId="formGridNome">
                    <Form.Label> Nome *</Form.Label>
                    <Form.Control type="text" placeholder="Nome" value={this.state.nome} onChange={(event) => this.setState({nome: event.target.value})} required/>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label> Sobrenome *</Form.Label>
                    <Form.Control type="text" placeholder="Sobrenome" value={this.state.sobrenome} onChange={(event) => this.setState({sobrenome: event.target.value})} required/>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mx-auto">
                  <Form.Group as={Col} controlId="formGridCpf">
                    <Form.Label> CPF *</Form.Label>
                    <InputMask mask="999.999.999-99" value={this.state.customer.cpf} onChange={(event) => this.setState({customer: {...this.state.customer, cpf: event.target.value}})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="000.000.000-00"  required/>}
                    </InputMask>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mx-auto">
                  <Form.Group as={Col} controlId="formGridNascimento">
                    <Form.Label> Data de Nascimento </Form.Label>
                    <InputMask mask="99/99/9999" value={this.state.customer.nascimento} onChange={(event) => this.setState({customer: {...this.state.customer, nascimento: event.target.value}})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="99/99/9999" />}
                    </InputMask>
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mx-auto">
                  <Form.Group as={Col} controlId="formGridTelefone">
                    <Form.Label> Celular * </Form.Label>
                    <InputMask mask="(99) 9 9999-9999" value={this.state.customer.celular} onChange={(event) => this.setState({customer: {...this.state.customer, celular: event.target.value}})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(00) 0-0000-0000"  required/>}
                    </InputMask>
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridTelefone2" xs={12} sm={6}>
                    <Form.Label> Telefone 2 </Form.Label>
                    <InputMask mask={this.state.phoneMask} value={this.state.customer.telefone} onBlur={this.handlePhoneMask.bind(this)} onChange={(event) => this.setState({customer: {...this.state.customer, telefone: event.target.value}})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="(00) 0-0000-0000" />}
                    </InputMask>
                  </Form.Group>
                </Form.Row>
              </>
              :
                <Form.Row className="mx-auto">
                  <Form.Group as={Col} controlId="formGridCpf">
                    <Form.Label> CPF *</Form.Label>
                    <InputMask mask="999.999.999-99" value={this.state.customer.cpf} onChange={(event) => this.setState({customer: {...this.state.customer, cpf: event.target.value}})}>
                      {(inputProps) => <Form.Control {...inputProps} type="tel" placeholder="000.000.000-00"  required/>}
                    </InputMask>
                  </Form.Group>
                </Form.Row>
            }
            </div>

            <div className="data-actions">
              { !this.props.param.newUser && <a href="/meu-cadastro" className="btn-link-action"> Voltar </a> }
              <Button className="btn-primary-action btn-register" type="submit" onClick={this.handleSubmit.bind(this)} disabled={this.state.isLoading}>
                { (this.state.isLoading) ? 'Salvando...' : 'Salvar' }
              </Button>
            </div>
          </>
        }
      </>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InfoEdit);
