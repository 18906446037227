import React from 'react';
import './index.scss';
import {Link, Redirect} from 'react-router-dom';
import Api from '../../services/api';
import {Diversos} from '../../services/diversos';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Param} from '../../store/ducks/param/types';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import * as ParamActions from '../../store/ducks/param/actions';
import {Cart} from '../../store/ducks/cart/types';

import Slider from 'react-slick';
import swal from 'sweetalert';
import MultilevelSidebar from 'react-multilevel-sidebar';
import 'react-multilevel-sidebar/src/Sidebar.css';

import CartComponent from '../CartComponent';
import CookieBanner from '../CookieBanner';
import ModalPrimeiraCompra from '../modalPrimeiraCompra';

// Import de Icones
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser, faUserCircle, faInfoCircle, faChevronDown, faBars, faMapMarkerAlt, faReceipt, faSearch, faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {faFacebookSquare, faInstagram} from '@fortawesome/free-brands-svg-icons';

// Import de Imagens
import logo from '../../assets/Images/logo.png';
import logoBlog from '../../assets/Images/logoBlog.svg';
import topBanner from '../../assets/Images/banner-top.png';
// import topBannerMobile from '../../assets/Images/top-banner-mobile.png'
import topBannerMobile from '../../assets/Images/navigation-mobile.png';
import whats from '../../assets/whats-app-fale-conosco.png';
import CartIcon from '../../assets/Images/cart.png';

// Import de Componentes Bootstrap
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import InputMask from 'react-input-mask';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';

declare var window;

interface StateProps {
  user: User;
  param: Param;
  cart: Cart;
}

interface OwnProps {
  searchTermo?: string;
  tipo?: number;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

type Props = StateProps & DispatchProps & OwnProps;

class Header extends React.Component<Props> {
  api: any = null;
  state: any = null;
  cepRef: any = null;
  searchbarRef: any;
  listener: any;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.searchbarRef = React.createRef();
    this.cepRef = React.createRef();

    this.state = {
      cep: this.props.user && this.props.user.cep ? this.props.user.cep : this.props.param && this.props.param.cep ? this.props.param.cep : '',
      hasErrorCep: false,
      hasErrorCepMsg: '',
      hasSuccessCep: false,
      hasSuccessCepMsg: '',
      liveSearch: [],
      isLoading: true,
      menu: null,
      searchTerm: this.props.searchTermo ? this.props.searchTermo : null,
      searchbarHasError: false,
      redirect: null,
      isLoadingMarcas: true,
      marcas: [],
      isLoadingCustomer: true,
      customer: null,
      headerStatus: 'top',
      sidebarOpened: false,
      deliveryAdd: false,
      options: [
        {
          title: 'Entrega',
          content: [{id: 0, name: this.props.user && this.props.user.status === true ? `Entregar em` : 'Selecione o endereço de  entrega', delivery: true}],
        },
        {
          title: 'Navegação',
          content: [
            {
              id: 0,
              name: 'Promoções',
              to: '/promocoes',
            },
            {
              id: 1,
              name: 'Departamentos',
              children: [{content: []}],
            },
            // { id: 2, name: "Promoções", to: "/promocoes" },
            // { id: 3, name: "Ofertas do mês", to: "/" }
          ],
        },
        {
          title: 'SAC',
          content: [
            {
              id: 5,
              name: 'Minha conta',
              children: [
                {
                  content: [
                    {id: 51, name: 'Meus pedidos', to: '/meus-pedidos'},
                    {id: 52, name: 'Meu cadastro', to: '/meu-cadastro#meu-perfil'},
                    {id: 53, name: 'Favoritos', to: '/meu-cadastro#favoritos'},
                    {
                      id: 54,
                      name: this.props.user && this.props.user.status === true ? 'Reset de Senha' : 'Recuperar Senha',
                      to: this.props.user && this.props.user.status === true ? '/reset-senha' : '/recuperar-senha',
                    },
                  ],
                },
              ],
            },
            {id: 6, name: 'Atendimento', to: '/institucional/atendimento'},
            // {id: 7, name: 'Nossas lojas', to: '/institucional/lojas'},
            // {id: 7, name: 'Nossos solões', to: '/institucional/saloes'},
            {id: 7, name: 'Televendas', to: '/institucional/televendas'},
            {id: 8, name: 'Dúvidas frequentes', to: '/central-de-relacionamento'},
            {id: 9, name: 'Sobre a Depimiel', to: '/'},
            {id: 10, name: 'Blog', to: '/blog'},
          ],
        },
        {
          title: 'Social',
          content: [
            {id: 11, icon: <FontAwesomeIcon icon={faFacebookSquare} style={{fontSize: '1.5rem', marginRight: '.5rem'}} />, name: '/depimiel', fbLink: true},
            {id: 12, icon: <FontAwesomeIcon icon={faInstagram} style={{fontSize: '1.5rem', marginRight: '.5rem'}} />, name: '@depimielbrasil', igLink: true},
          ],
        },
      ],
    };
  }

  handleSidebarToggle = (isOpen) => {
    this.setState({sidebarOpened: isOpen});
  };

  handleClick = (itemOptions) => {
    if (itemOptions.delivery) {
      this.setState({
        deliveryAdd: true,
      });
    }

    if (itemOptions.fbLink) {
      window.open('https://www.facebook.com/depimiel', '_blank');
    }

    if (itemOptions.igLink) {
      window.open('https://www.instagram.com/depimielbrasil', '_blank');
    }
  };

  async componentDidMount() {
    const self = this;

    if (self.props.user && self.props.user.status !== false) {
      self.getCustomer();
    }

    self.getMarcas(); // busca marcas no bd

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get('/menu/nivel1-por-produto');
      // const { data } = await self.api.get('/menu/nivel1');

      if (data.status === false) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        let aux: any = [];
        let aux2: any = [];

        for (var i = 0; i < data.msg.length; i++) {
          aux.push({
            codigo: data.msg[i].CDMENU,
            descricao: data.msg[i].DESCRICAO,
            menu2: data.msg[i].menu2,
          });

          aux2.push({
            id: i,
            name: Diversos.capitalize(data.msg[i].DESCRICAO),
            to: '/departamento/' + Diversos.toSeoUrl(data.msg[i].DESCRICAO),
          });
        }

        let tmp = self.state.options;
        tmp[1].content[1].children[0].content = aux2;

        self.setState({menu: aux, options: tmp});
      }
    } catch (e) {
      console.error(e);
      self.setState({menu: null});
    } finally {
      self.setState({isLoading: false});
    }

    if (window.pageYOffset >= 55) {
      this.setState({headerStatus: ''});
    }

    this.listener = document.addEventListener('scroll', (e) => {
      if (document.scrollingElement === null) {
        alert('scrollingElement é null');
      } else {
        var scrolled = document.scrollingElement.scrollTop;
        if (scrolled >= 40) {
          if (this.state.headerStatus !== '') {
            this.setState({headerStatus: ''});
          }
        } else {
          if (this.state.headerStatus !== 'top') {
            this.setState({headerStatus: 'top'});
          }
        }
      }
    });
  }

  componentDidUpdate() {
    document.removeEventListener('scroll', this.listener);
  }

  private async getMarcas() {
    const self = this;

    self.setState({isLoadingMarcas: true});

    try {
      const {data} = await self.api.post('/product/marcas', {});

      if (data.status === false) {
        throw new Error('Nao foi possível recuperar menu');
      } else {
        self.setState({marcas: data.msg});
      }
    } catch (e) {
      console.error(e);
      self.setState({marcas: null});
    } finally {
      self.setState({isLoadingMarcas: false});
    }
  }

  private async getCustomer() {
    const self = this;

    self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if (data.status === false) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        self.setState({customer: data.msg});
      }
    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message,
      });
    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleSearch(event) {
    const self = this;
    event.preventDefault();

    if (!self.state.searchTerm) {
      return;
    }

    self.setState({redirect: `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`});

    window.location.href = `/busca/${Diversos.toSeoUrl(self.state.searchTerm)}`;
  }

  private getCartAmount() {
    const self = this;
    let amount = 0;
    for (var i = 0; i < self.props.cart.produtos.length; i++) {
      amount += 1 * self.props.cart.produtos[i].qty;
    }

    if (isNaN(amount)) return 0;

    return amount;
  }

  private brandsFirstLetter() {
    const self = this;
    const str: string[] = [];

    for (var i = 0; i < self.state.marcas.length; i++) {
      str[i] = self.state.marcas[i].MARCA.slice(0, 1);
    }

    const uniqueLetters = Array.from(new Set(str));

    return uniqueLetters;
  }

  private async _handleCepCheck() {
    const self = this;
    let cep = Diversos.getnums(self.state.cep);
    const minCep = 80000000;
    const maxCep = 82999999;

    if (cep.length !== 8) {
      return false;
    }

    self.setState({
      isLoadingCep: true,
      hasErrorCep: false,
      hasErrorCepMsg: '',
      hasSuccessCep: false,
      hasSuccessCepMsg: '',
    });

    let param = {cep};

    try {
      const {data} = await self.api.post('/shipping/check-coverage', param);

      if (data.status) {
        self.setState({hasSuccessCep: true, hasSuccessCepMsg: data.msg});
        self.props.setParam({...self.props.param, cep: Diversos.maskCEP(cep)});
        self.setState({deliveryAdd: false});
      } else throw new Error('Infelizmente, por enquanto nós não atendemos o seu CEP');
    } catch (e) {
      // console.error(e);
      self.setState({
        hasErrorCep: true,
        hasErrorCepMsg: 'Infelizmente, por enquanto nós não atendemos o seu CEP',
      });
    } finally {
      self.setState({isLoadingCep: false});

      if (Diversos.freteGratis(cep)) {
        // swal('FRETE GRATIS', 'Compras acima de R$ 99,99 o frete para região de Curitiba é gratuíto, aproveite :)', 'success');
      }
    }
  }

  private async handleLiveSearch() {
    const self = this;

    if (!self.state.searchTerm) {
      self.setState({liveSearch: []});
      return false;
    }

    setTimeout(async () => {
      let page = 1;
      let param = {termo: self.state.searchTerm};

      try {
        const {data} = await self.api.post(`/product/live-search/${page}`, param);

        if (!data.status) throw new Error(data.msg);

        self.setState({liveSearch: data.msg.data});
      } catch (e) {
        if (process.env.REACT_APP_ENVIROMENT === 'development') {
          console.log(e);
        }

        self.setState({liveSearch: []});
      }
    }, 200);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const Scrolled = this.state.headerStatus !== 'top';
    const xsDevice = window.innerWidth <= 468;
    const mdDevice = window.innerWidth <= 1024;

    return (
      <>
        {!this.props.tipo || this.props.tipo !== 2 ? (
          <>
            <header className="d-none d-md-block">
              <div className="top-header">
                {/* <Slider
                  className="top-header-slider"
                  dots={false}
                  arrows={false}
                  autoplay={false}
                  infinite={true}
                  speed={1500}
                  autoplaySpeed={6000}
                  slidesToShow={1}
                  slidesToScroll={1}
                >
                  <a href="/" title="Ver">
                    <img src={mdDevice ? topBannerMobile : topBanner} alt="Banner promocional" />
                  </a>
                </Slider> */}
              </div>
              <Row
                className={`mx-0 user-nav ${Scrolled ? 'add-shadow' : ''}`}
                style={{
                  position: Scrolled ? 'fixed' : 'static',
                  paddingTop: '.5rem',
                  paddingBottom: '.5rem',
                }}
              >
                <Col xs={2} lg={3} className="logo-col">
                  <a href="/">
                    {' '}
                    <img src={logo} alt="Logo Depimiel" />{' '}
                  </a>
                </Col>
                <Col xs={10} lg={5} className="search-col">
                  <Form onSubmit={this.handleSearch.bind(this)}>
                    <InputGroup className="search-bar-container">
                      <FormControl
                        name={'pesquisa'}
                        autoComplete={'off'}
                        placeholder={xsDevice ? 'O que você procura?' : 'Buscar produtos'}
                        aria-label="Buscar produtos"
                        aria-describedby="searchAction"
                        className="search-bar"
                        value={this.state.searchTerm || ''}
                        onChange={(event) => this.setState({searchTerm: event.target.value}, this.handleLiveSearch.bind(this))}
                        // onClick={() => this.handleSearch.bind(this)}
                        onKeyPress={(event) => event.key === 'Enter' && this.handleSearch.bind(this)}
                      />
                      <InputGroup.Append>
                        <Button variant="primary" type={'submit'} className="btn-search-bar">
                          {' '}
                          <FontAwesomeIcon icon={faSearch} />{' '}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>

                  <div className={'live-search-content'} style={{display: this.state.liveSearch.length > 0 ? 'block' : 'none'}}>
                    <ul className={'list-group'}>
                      {this.state.liveSearch.map((row, index) => (
                        <li className={'list-group-item'}>
                          <a href={`/${Diversos.toSeoUrl(row.NOME)}`}>
                            {row.FOTOS && row.FOTOS.length > 0 && (
                              <img src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`} style={{width: 30, height: 30}} alt={row.NOME} />
                            )}{' '}
                            {row.NOME.toUpperCase()}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <button onClick={this.handleSearch.bind(this)}>Ver mais</button>
                  </div>
                </Col>
                <Col lg={4} className="user-col">
                  <span className="d-block d-lg-none" onClick={() => this.handleSidebarToggle(true)}>
                    <FontAwesomeIcon icon={faBars} className="menu-icon" />
                  </span>
                  <a
                    href={this.props.user && this.props.user.status === true ? '/meu-cadastro' : '/login'}
                    className="d-none d-sm-flex flex-row align-items-center justify-content-around"
                  >
                    <FontAwesomeIcon icon={faUser} />
                    <p className="mt-3">
                      {this.props.user && this.props.user.status && this.props.user.codigo ? (
                        <>
                          Olá{' '}
                          <strong style={{textTransform: 'capitalize'}}>
                            {this.props.user.nome.split(' ').length > 0 ? this.props.user.nome.toLowerCase().split(' ')[0] : this.props.user.nome.toLowerCase()}
                          </strong>
                        </>
                      ) : (
                        <>
                          Olá <strong>faça seu login</strong> <br /> ou <strong>cadastre-se</strong>
                        </>
                      )}
                    </p>
                  </a>
                  <a href={this.props.user && this.props.user.status === true ? '/meus-pedidos' : '/login'} className="d-none d-sm-block">
                    <FontAwesomeIcon icon={faReceipt} />
                    Pedidos
                  </a>
                  <a href="/central-de-relacionamento" className="d-none d-sm-block">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    Ajuda
                  </a>
                  <span className="cart-icon-container" onClick={() => this.props.setParam({...this.props.param, cartOpened: true})}>
                    <p> {this.getCartAmount()} </p>
                    <img src={CartIcon} alt="Cart Icon" />
                  </span>
                </Col>
              </Row>

              <nav className="main-nav">
                <ul className="navigation">
                  {this.state.isLoading ? (
                    <div className="w-100 h-100 text-center m-auto">
                      <FontAwesomeIcon icon="spinner" spin style={{color: 'rgba(255,255,255,.5)'}} />
                    </div>
                  ) : this.state.menu && this.state.menu.length > 0 ? (
                    this.state.menu.map((row, index) => {
                      if (!row.descricao) {
                        return <></>;
                      }

                      return (
                        <li key={index} className={row.menu2 && row.menu2.length > 0 && row.menu2.filter((q) => q.DESCRICAO).length > 0 ? 'dropdown-1-controller' : ''}>
                          <a
                            href={
                              row.descricao.toLowerCase() === 'unha'
                                ? `/departamento/${Diversos.toSeoUrl(row.descricao)}/maos-e-pes`
                                : `/departamento/${Diversos.toSeoUrl(row.descricao)}`
                            }
                            target="_self"
                          >
                            {Diversos.capitalize(row.descricao)}
                          </a>

                          {row.menu2 && row.menu2.length > 0 && row.menu2.filter((q) => q.DESCRICAO).length > 0 && (
                            <ul className={`dropdown-1`}>
                              {row.menu2.map((row2, index2) => {
                                if (!row2.DESCRICAO) {
                                  return <></>;
                                }

                                return (
                                  <li key={`${index}-${index2}`} className="menu-2">
                                    <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`} target="_self">
                                      {Diversos.capitalize(row2.DESCRICAO)}
                                    </a>
                                    {row2.menu3 && row2.menu3.length > 0 && (
                                      <ul className="submenu-2">
                                        {row2.menu3.slice(0, 10).map(
                                          (row3, index3) =>
                                            row3.DESCRICAO && (
                                              <li key={`${index2}-${index3}`}>
                                                <a
                                                  href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}/${Diversos.toSeoUrl(
                                                    row3.DESCRICAO,
                                                  )}`}
                                                  target="_self"
                                                >
                                                  {Diversos.capitalize(row3.DESCRICAO)}
                                                </a>
                                              </li>
                                            ),
                                        )}
                                      </ul>
                                    )}
                                    {row2.menu3 && row2.menu3.length > 5 && (
                                      <a className="menu-ver-mais" href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`}>
                                        {' '}
                                        + {Diversos.capitalize(row2.DESCRICAO)}{' '}
                                      </a>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })
                  ) : null}

                  {this.state.isLoading ? (
                    <></>
                  ) : this.state.menu && this.state.menu.length > 0 ? (
                    this.state.menu.map(
                      (row, index) =>
                        row.descricao &&
                        (row.descricao.toLowerCase() === 'profissionais' || row.descricao.toLowerCase() === 'cuidados') && (
                          <li key={index} className={row.menu2 && row.menu2.length > 0 ? 'dropdown-1-controller' : ''}>
                            <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}`} target="_self">
                              {Diversos.capitalize(row.descricao)}
                            </a>

                            {row.menu2 && row.menu2.length > 0 && (
                              <ul className={`dropdown-1`}>
                                {row.menu2.slice(0, 5).map(
                                  (row2, index2) =>
                                    row2.DESCRICAO && (
                                      <li key={`${index}-${index2}`} className="menu-2">
                                        <a href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`} target="_self">
                                          {Diversos.capitalize(row2.DESCRICAO)}
                                        </a>
                                        {row2.menu3 && row2.menu3.length > 0 && (
                                          <ul className="submenu-2">
                                            {row2.menu3.slice(0, 10).map(
                                              (row3, index3) =>
                                                row3.DESCRICAO && (
                                                  <li key={`${index2}-${index3}`}>
                                                    <a
                                                      href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}/${Diversos.toSeoUrl(
                                                        row3.DESCRICAO,
                                                      )}`}
                                                      target="_self"
                                                    >
                                                      {Diversos.capitalize(row3.DESCRICAO)}
                                                    </a>
                                                  </li>
                                                ),
                                            )}
                                          </ul>
                                        )}
                                        {row2.menu3 && row2.menu3.length > 5 && (
                                          <a className="menu-ver-mais" href={`/departamento/${Diversos.toSeoUrl(row.descricao)}/${Diversos.toSeoUrl(row2.DESCRICAO)}`}>
                                            {' '}
                                            + {Diversos.capitalize(row2.DESCRICAO)}{' '}
                                          </a>
                                        )}
                                      </li>
                                    ),
                                )}
                              </ul>
                            )}
                          </li>
                        ),
                    )
                  ) : null}

                  <li>
                    {' '}
                    <a href="/promocoes" target="_self">
                      {' '}
                      Promoções{' '}
                    </a>{' '}
                  </li>
                  <li>
                    {' '}
                    <a href="/blog" target="_self" rel="noopener noreferrer">
                      {' '}
                      Blog{' '}
                    </a>{' '}
                  </li>
                </ul>
              </nav>
            </header>

            <header className="d-block d-md-none">
              <div className="top-header">
                {/* <Slider
                  className="top-header-slider"
                  dots={false}
                  arrows={false}
                  autoplay={false}
                  infinite={true}
                  speed={1500}
                  autoplaySpeed={6000}
                  slidesToShow={1}
                  slidesToScroll={1}
                >
                  <a href="/" title="Ver">
                    <img src={mdDevice ? topBannerMobile : topBanner} alt="Banner promocional" />
                  </a>
                </Slider> */}
              </div>
              <Row
                className={`mx-0 user-nav ${Scrolled ? 'add-shadow' : ''} container-fluid`}
                style={{
                  position: Scrolled ? 'fixed' : 'static',
                  paddingTop: '.5rem',
                  paddingBottom: '.5rem',
                }}
              >
                <Col xs={2} className="user-col justify-content-start align-items-start">
                  <span onClick={() => this.handleSidebarToggle(true)}>
                    <FontAwesomeIcon icon={faBars} className="menu-icon" />
                  </span>
                </Col>
                <Col xs={8} lg={0} className="text-center justify-content-center align-items-start">
                  <a href="/">
                    {' '}
                    <img src={logo} alt="Logo Depimiel" style={{height: 50, width: 'auto'}} />{' '}
                  </a>
                </Col>
                <Col xs={2} className="user-col justify-content-end align-items-start">
                  <span className="cart-icon-container" onClick={() => this.props.setParam({...this.props.param, cartOpened: true})}>
                    <p> {this.getCartAmount()} </p>
                    <img src={CartIcon} alt="Cart Icon" />
                  </span>
                </Col>
                <Col xs={12} lg={0} className="search-col pt-2 px-0">
                  <Form onSubmit={this.handleSearch.bind(this)}>
                    <InputGroup className="search-bar-container">
                      <FormControl
                        name={'pesquisa'}
                        autoComplete={'off'}
                        placeholder={xsDevice ? 'O que você procura?' : 'Buscar produtos'}
                        aria-label="Buscar produtos"
                        aria-describedby="searchAction"
                        className="search-bar"
                        value={this.state.searchTerm || ''}
                        onChange={(event) => this.setState({searchTerm: event.target.value}, this.handleLiveSearch.bind(this))}
                        // onClick={() => this.handleSearch.bind(this)}
                        onKeyPress={(event) => event.key === 'Enter' && this.handleSearch.bind(this)}
                      />
                      <InputGroup.Append>
                        <Button variant="primary" type={'submit'} className="btn-search-bar">
                          {' '}
                          <FontAwesomeIcon icon={faSearch} />{' '}
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form>

                  <div className={'live-search-content'} style={{display: this.state.liveSearch.length > 0 ? 'block' : 'none'}}>
                    <ul className={'list-group'}>
                      {this.state.liveSearch.map((row, index) => (
                        <li className={'list-group-item'}>
                          <a href={`/${Diversos.toSeoUrl(row.NOME)}`}>
                            {row.FOTOS && row.FOTOS.length > 0 && (
                              <img src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`} style={{width: 30, height: 30}} alt={row.NOME} />
                            )}{' '}
                            {row.NOME.toUpperCase()}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <button onClick={this.handleSearch.bind(this)}>Ver mais</button>
                  </div>
                </Col>
              </Row>
            </header>
          </>
        ) : (
          <>
            <header className="checkout-header">
              <Container fluid className="checkout-nav">
                <Row className="w-100 m-auto d-flex align-items-center">
                  <Col className="d-flex justify-content-center align-items-center" lg="12" md="12" xs="12">
                    <Link to="/">
                      {' '}
                      <img src={logo} className="logo" alt="logo Depimiel" />{' '}
                    </Link>
                  </Col>
                </Row>
              </Container>
            </header>
          </>
        )}

        <Modal
          show={this.state.deliveryAdd}
          onHide={() => this.setState({deliveryAdd: false, hasErrorCep: false, hasErrorCepMsg: '', hasSuccessCep: false, hasSuccessCepMsg: ''})}
          dialogClassName="delivery-address-container"
          className="px-0 delivery-modal"
          aria-labelledby="Modal para selecionar seu endereço de entrega"
        >
          <div className="delivery-address">
            <Modal.Header closeButton>
              <span>
                <h4> Selecione o endereço que deseja receber suas entregas </h4>
                <small> Os preços e produtos podem variar de acordo com a localidade </small>
              </span>
            </Modal.Header>
            <Form onSubmit={this._handleCepCheck.bind(this)}>
              <Form.Group as={Col} className="px-0 mb-0 mt-3">
                <Form.Label> seu CEP </Form.Label>
                <InputMask
                  name={'cep'}
                  mask="99.999-999"
                  ref={this.cepRef}
                  disabled={this.state.isLoadingCep}
                  value={this.state.cep}
                  onChange={(event) => this.setState({cep: event.target.value}, this._handleCepCheck.bind(this))}
                >
                  {(inputProps) => (
                    <Form.Control
                      {...inputProps}
                      type="text"
                      placeholder="00.000-000"
                      required
                      isInvalid={this.state.hasErrorCep}
                      isValid={this.state.hasSuccessCep}
                      disabled={this.state.isLoadingCep}
                    />
                  )}
                </InputMask>
                {this.state.isLoadingCep && (
                  <Form.Text className="mt-2 text-muted">
                    <i className={'fas fa-spin fa-spinner'}></i> Pesquisando CEP, por favor aguarde...
                  </Form.Text>
                )}

                {this.state.hasSuccessCep && <Form.Text className="mt-2 text-success">{this.state.hasSuccessCepMsg}</Form.Text>}

                {this.state.hasErrorCep && (
                  <Alert variant={'danger'} className={'mt-3'}>
                    <i className={'fas fa-times'}></i> {this.state.hasErrorCepMsg}
                  </Alert>
                )}
              </Form.Group>
              <span className="delivery-address-actions">
                <button
                  type={'button'}
                  className="btn-link-action"
                  onClick={() => this.setState({deliveryAdd: false, hasErrorCep: false, hasErrorCepMsg: '', hasSuccessCep: false, hasSuccessCepMsg: ''})}
                >
                  {' '}
                  Cancelar{' '}
                </button>
                {/*<button className="btn-primary-action btn-delivery-address"> Selecionar </button>*/}
              </span>
            </Form>
          </div>
        </Modal>

        <Modal
          show={this.props.param && this.props.param.cartOpened}
          onHide={() => this.props.setParam({...this.props.param, cartOpened: false})}
          dialogClassName="cart-container"
          className="px-0"
          aria-labelledby="Carrinho-de-compras"
        >
          <CartComponent />
        </Modal>

        <Modal
          show={this.props.param && this.props.param.firstPurchaseOpen}
          onHide={() => this.props.setParam({...this.props.param, firstPurchaseOpen: false})}
          dialogClassName="first-purchase-container"
          style={{display: 'flex'}}
          className="px-0"
          aria-labelledby="Modal-primeira-compra"
        >
          <ModalPrimeiraCompra />
        </Modal>

        <MultilevelSidebar
          open={this.state.sidebarOpened}
          onToggle={this.handleSidebarToggle}
          options={this.state.options}
          onItemClick={this.handleClick}
          wrapperClassName="sidebar-mobile-menu"
          header={
            <>
              <Container className="sidebar-header">
                <a
                  className="sidebar-user-link"
                  href={this.props.user && this.props.user.status === true ? '/meu-cadastro' : '/login'}
                  target="_self"
                  title={this.props.user && this.props.user.status === true ? 'Meu cadastro' : 'Fazer login'}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="sidebar-user-icon"></FontAwesomeIcon>
                  <span className="sidebar-user-action">
                    <span className="sidebar-user-title"> Meu perfil </span>
                    {this.props.user && this.props.user.status === true ? <span> Dados da conta </span> : <span> Cadastre-se ou faça login </span>}
                  </span>
                </a>
              </Container>
            </>
          }
        />

        {!this.props.param || (!this.props.param.acceptCookies && <CookieBanner accept={() => this.props.setParam({...this.props.param, acceptCookies: true})} />)}
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
  cart: state.cart.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
