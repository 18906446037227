import React, {Component} from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Diversos} from '../../services/diversos';
import {Redirect} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import moment from 'moment';
import 'moment/locale/pt-br';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import swal from 'sweetalert';
declare var window: any;

interface StateProps {
  cart?: Cart;
  param: Param;
  user?: User;
}

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

interface OwnProps {
  imageOver: any;
  imageNormal: any;
  item?: any;
  fav?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

class Product extends Component<Props> {
  state: any;

  constructor(props) {
    super(props);
    this.state = {
      imgSrc: this.props.imageNormal,
      favHover: false,
      temp: [],
      favClicked: false,
      rediect: null,
    };
  }

  componentDidMount() {}

  handleMouseOver() {
    this.setState({
      imgSrc: this.props.imageOver,
    });
  }

  handleMouseOut() {
    this.setState({
      imgSrc: this.props.imageNormal,
    });
  }

  private async handleAddCart(event) {
    const self = this;
    event.preventDefault();

    let preco = self.props.item.PRECO;
    let precoOriginal = self.props.item.PRECO;

    if (
      self.props.item.PREPRO > 0 &&
      self.props.item.PREPRO < self.props.item.PRECO &&
      moment(self.props.item.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
      moment(self.props.item.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD')
    ) {
      preco = this.props.item.PREPRO;
    }

    let qtdJaAdd = 1;

    if (self.props.cart && self.props.cart.produtos) {
      for (let i = 0; i < self.props.cart.produtos.length; i++) {
        if (self.props.cart.produtos[i].codigo === self.props.item.CODIGO) {
          if (self.props.cart.produtos[i].price === preco) {
            qtdJaAdd += Number(self.props.cart.produtos[i].qty);
          }
        }
      }
    }

    if (qtdJaAdd > self.props.item.ESTOQUE) {
      swal('Atenção', `Quantidade máxima em estoque ${self.props.item.ESTOQUE} unidade(s)`, 'error');
      return false;
    }

    self.props.add({
      codigo: self.props.item.CODIGO,
      nome: self.props.item.NOME,
      priceOrigin: precoOriginal,
      price: preco,
      qty: 1,
      foto: self.props.imageNormal,
      estoque: self.props.item.ESTOQUE,
      peso: self.props.item.PESO,
    });

    self.props.setParam({...self.props.param, cartOpened: true});

    // TagManager.dataLayer({
    //   gtmId: 'GTM-P7FFP62',
    //   dataLayer: {
    //     productId: self.props.item.CODIGO,
    //     productName: self.props.item.NOME,
    //     productBrand: self.props.item.MARCA,
    //     productStock: self.props.item.ESTOQUE,
    //     productPrice: preco,
    //     productPicture: `${process.env.REACT_APP_BASE_URL_PICTURE}/${self.props.item.FOTOS[0].link}`,
    //     page: window.location.pathname,
    //   },
    //   dataLayerName: 'AddToCart',
    // });
  }

  private async handleFavorites(code, event) {
    const self = this;
    event.preventDefault();
    this.setState({favClicked: true});

    let achou = false;

    if (self.props.param.favorites) {
      for (var i = 0; i < self.props.param.favorites.length && !achou; i++) {
        if (code == self.props.param.favorites[i]) {
          self.props.param.favorites.splice(i, 1);
          achou = true;
        }
      }

      if (!achou) {
        self.props.param.favorites.push(code);
      }

      self.props.setParam({...self.props.param, favorites: self.props.param.favorites});
    }
  }

  areFavourited() {
    const self = this;

    // if ((self.props.user) && (self.props.user.status === true))
    //   return false;

    if (self.props.param.favorites) return self.props.param.favorites.includes(self.props.item.CODIGO);
    else return false;
  }

  private async favIcon(hovered) {
    this.setState({
      favHover: hovered,
    });
  }

  promoPercentage() {
    // let preco = ((((this.props.item.PREPRO) - (this.props.item.PRECO)) / this.props.item.PREPRO) * 100);
    if (
      this.props.item.PREPRO > 0 &&
      this.props.item.PREPRO < this.props.item.PRECO &&
      moment(this.props.item.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
      moment(this.props.item.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD')
    ) {
      let preco = 100 - (this.props.item.PREPRO * 100) / this.props.item.PRECO;
      return preco.toFixed(0);
    } else {
      return '';
    }
  }

  handleSeloPromocao() {
    const self = this;

    if (!self.props.item || !self.props.item.PROMOCAO || self.props.item.PROMOCAO.length <= 0) {
      return <></>;
    }

    let tipoPromo = 0;
    let preco1 = 0;
    let preco2 = 0;
    let preco3 = 0;
    let preco4 = 0;
    let preco5 = 0;
    let precoFinal = 0;

    if (
      self.props.item.PROMOCAO[0].CDPRODU1 &&
      !self.props.item.PROMOCAO[0].CDPRODU2 &&
      !self.props.item.PROMOCAO[0].CDPRODU3 &&
      !self.props.item.PROMOCAO[0].CDPRODU4 &&
      !self.props.item.PROMOCAO[0].CDPRODU5
    ) {
      tipoPromo = 1;

      preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      if (self.props.item.PROMOCAO[0].CDDESC1 > 0) preco1 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC1 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO1 > 0) preco1 = self.props.item.PROMOCAO[0].CDPRECO1;
      else preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      precoFinal = preco1 * self.props.item.PROMOCAO[0].CDQTD1;
    } else if (
      self.props.item.PROMOCAO[0].CDPRODU1 &&
      self.props.item.PROMOCAO[0].CDPRODU2 &&
      !self.props.item.PROMOCAO[0].CDPRODU3 &&
      !self.props.item.PROMOCAO[0].CDPRODU4 &&
      !self.props.item.PROMOCAO[0].CDPRODU5
    ) {
      tipoPromo = 2;

      if (self.props.item.PROMOCAO[0].CDDESC1 > 0) preco1 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC1 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO1 > 0) preco1 = self.props.item.PROMOCAO[0].CDPRECO1;
      else preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      preco1 = preco1 * self.props.item.PROMOCAO[0].CDQTD1;

      if (self.props.item.PROMOCAO[0].CDDESC2 > 0) preco2 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC2 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO2 > 0) preco2 = self.props.item.PROMOCAO[0].CDPRECO2;
      else preco2 = self.props.item.PROMOCAO[0].produto2.PRECO;

      preco2 = preco2 * self.props.item.PROMOCAO[0].CDQTD2;

      precoFinal = preco1 + preco2;
    } else if (
      self.props.item.PROMOCAO[0].CDPRODU1 &&
      self.props.item.PROMOCAO[0].CDPRODU2 &&
      self.props.item.PROMOCAO[0].CDPRODU3 &&
      !self.props.item.PROMOCAO[0].CDPRODU4 &&
      !self.props.item.PROMOCAO[0].CDPRODU5
    ) {
      tipoPromo = 3;

      if (self.props.item.PROMOCAO[0].CDDESC1 > 0) preco1 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC1 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO1 > 0) preco1 = self.props.item.PROMOCAO[0].CDPRECO1;
      else preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      preco1 = preco1 * self.props.item.PROMOCAO[0].CDQTD1;

      if (self.props.item.PROMOCAO[0].CDDESC2 > 0) preco2 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC2 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO2 > 0) preco2 = self.props.item.PROMOCAO[0].CDPRECO2;
      else preco2 = self.props.item.PROMOCAO[0].produto2.PRECO;

      preco2 = preco2 * self.props.item.PROMOCAO[0].CDQTD2;

      if (self.props.item.PROMOCAO[0].CDDESC3 > 0) preco3 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC3 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO3 > 0) preco3 = self.props.item.PROMOCAO[0].CDPRECO3;
      else preco3 = self.props.item.PROMOCAO[0].produto3.PRECO;

      preco3 = preco3 * self.props.item.PROMOCAO[0].CDQTD3;

      precoFinal = preco1 + preco2 + preco3;
    } else if (
      self.props.item.PROMOCAO[0].CDPRODU1 &&
      self.props.item.PROMOCAO[0].CDPRODU2 &&
      self.props.item.PROMOCAO[0].CDPRODU3 &&
      self.props.item.PROMOCAO[0].CDPRODU4 &&
      !self.props.item.PROMOCAO[0].CDPRODU5
    ) {
      tipoPromo = 4;

      if (self.props.item.PROMOCAO[0].CDDESC1 > 0) preco1 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC1 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO1 > 0) preco1 = self.props.item.PROMOCAO[0].CDPRECO1;
      else preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      preco1 = preco1 * self.props.item.PROMOCAO[0].CDQTD1;

      if (self.props.item.PROMOCAO[0].CDDESC2 > 0) preco2 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC2 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO2 > 0) preco2 = self.props.item.PROMOCAO[0].CDPRECO2;
      else preco2 = self.props.item.PROMOCAO[0].produto2.PRECO;

      preco2 = preco2 * self.props.item.PROMOCAO[0].CDQTD2;

      if (self.props.item.PROMOCAO[0].CDDESC3 > 0) preco3 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC3 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO3 > 0) preco3 = self.props.item.PROMOCAO[0].CDPRECO3;
      else preco3 = self.props.item.PROMOCAO[0].produto3.PRECO;

      preco3 = preco3 * self.props.item.PROMOCAO[0].CDQTD3;

      if (self.props.item.PROMOCAO[0].CDDESC4 > 0) preco4 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC4 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO4 > 0) preco4 = self.props.item.PROMOCAO[0].CDPRECO4;
      else preco4 = self.props.item.PROMOCAO[0].produto4.PRECO;

      preco4 = preco4 * self.props.item.PROMOCAO[0].CDQTD4;

      precoFinal = preco1 + preco2 + preco3 + preco4;
    } else if (
      self.props.item.PROMOCAO[0].CDPRODU1 &&
      self.props.item.PROMOCAO[0].CDPRODU2 &&
      self.props.item.PROMOCAO[0].CDPRODU3 &&
      self.props.item.PROMOCAO[0].CDPRODU4 &&
      self.props.item.PROMOCAO[0].CDPRODU5
    ) {
      tipoPromo = 5;

      if (self.props.item.PROMOCAO[0].CDDESC1 > 0) preco1 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC1 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO1 > 0) preco1 = self.props.item.PROMOCAO[0].CDPRECO1;
      else preco1 = self.props.item.PROMOCAO[0].produto1.PRECO;

      preco1 = preco1 * self.props.item.PROMOCAO[0].CDQTD1;

      if (self.props.item.PROMOCAO[0].CDDESC2 > 0) preco2 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC2 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO2 > 0) preco2 = self.props.item.PROMOCAO[0].CDPRECO2;
      else preco2 = self.props.item.PROMOCAO[0].produto2.PRECO;

      preco2 = preco2 * self.props.item.PROMOCAO[0].CDQTD2;

      if (self.props.item.PROMOCAO[0].CDDESC3 > 0) preco3 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC3 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO3 > 0) preco3 = self.props.item.PROMOCAO[0].CDPRECO3;
      else preco3 = self.props.item.PROMOCAO[0].produto3.PRECO;

      preco3 = preco3 * self.props.item.PROMOCAO[0].CDQTD3;

      if (self.props.item.PROMOCAO[0].CDDESC4 > 0) preco4 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC4 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO4 > 0) preco4 = self.props.item.PROMOCAO[0].CDPRECO4;
      else preco4 = self.props.item.PROMOCAO[0].produto4.PRECO;

      preco4 = preco4 * self.props.item.PROMOCAO[0].CDQTD4;

      if (self.props.item.PROMOCAO[0].CDDESC5 > 0) preco5 = self.props.item.PRECO - self.props.item.PRECO * (self.props.item.PROMOCAO[0].CDDESC5 / 100);
      else if (self.props.item.PROMOCAO[0].CDPRECO5 > 0) preco5 = self.props.item.PROMOCAO[0].CDPRECO5;
      else preco5 = self.props.item.PROMOCAO[0].produto5.PRECO;

      preco5 = preco5 * self.props.item.PROMOCAO[0].CDQTD5;

      precoFinal = preco1 + preco2 + preco3 + preco4 + preco5;
    }

    return (
      <a href="#" onClick={this.handleAddCartPromo.bind(this)} className="selo-promo-vitrine">
        <div className="flag-promo">
          Leve +
          <br />
          por -
        </div>
        <div className="body-promo">
          {tipoPromo === 1 ? `Compre ${self.props.item.PROMOCAO[0].CDQTD1} por` : `Leve mais por menos`}
          <br />
          <span>R$ {Diversos.number_format(precoFinal, 2, ',', '')}</span>
        </div>
      </a>
    );
  }

  private async handleAddCartPromo(event) {
    const self = this;
    event.preventDefault();

    if (!self.props.item.PROMOCAO || self.props.item.PROMOCAO.length <= 0 || !self.props.item.PROMOCAO[0].CDPRODU1) {
      return window.alert('Nenhum promoção encontrada.');
    }

    let qtd = 0;
    let preco = 0;
    let precoOriginal = self.props.item.PRECO;
    let i = 1;

    while (self.props.item.PROMOCAO[0][`CDPRODU${i}`]) {
      qtd = self.props.item.PROMOCAO[0][`CDQTD${i}`];

      if (self.props.item.PROMOCAO[0][`CDDESC${i}`] > 0) {
        preco = precoOriginal - precoOriginal * (self.props.item.PROMOCAO[0][`CDDESC${i}`] / 100);
      } else if (self.props.item.PROMOCAO[0][`CDPRECO${i}`] > 0) {
        preco = self.props.item.PROMOCAO[0][`CDPRECO${i}`];
      }

      self.props.add({
        codigo: self.props.item.PROMOCAO[0][`CDPRODU${i}`],
        nome: self.props.item.PROMOCAO[0][`produto${i}`].NOME,
        priceOrigin: precoOriginal,
        price: preco > 0 ? preco : precoOriginal,
        qty: qtd,
        estoque: self.props.item.PROMOCAO[0][`produto${i}`].ESTOQUE,
        foto:
          !self.props.item.PROMOCAO[0][`produto${i}`].FOTOS || !self.props.item.PROMOCAO[0][`produto${i}`].FOTOS[0]
            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
            : `${process.env.REACT_APP_BASE_URL_PICTURE}/${self.props.item.PROMOCAO[0][`produto${i}`].FOTOS[0].link}`,
        peso: self.props.item.PROMOCAO[0][`produto${i}`].PESO,
        promo: self.props.item.PROMOCAO[0],
        qtdPromo: qtd,
      });

      i++;
    }

    self.props.setParam({...self.props.param, cartOpened: true});
  }

  render() {
    return (
      <a
        href={`/${Diversos.toSeoUrl(this.props.item.NOME)}`}
        target="_self"
        onMouseOver={this.handleMouseOver.bind(this)}
        onMouseOut={this.handleMouseOut.bind(this)}
        className="mb-5 product-container"
        title="Ver detalhes do produto"
      >
        {this.props.item.PREPRO > 0 && this.props.item.PREPRO < this.props.item.PRECO && <Badge className="product-badge">{`-${this.promoPercentage()}%`}</Badge>}
        {/* <button
          className="product-fav"
          style={{left: '1rem'}}
          title={this.props.param.favorites && this.areFavourited() ? 'Remover do Favoritos' : 'Favoritar item'}
          onMouseOver={this.favIcon.bind(this, true)}
          onMouseOut={this.favIcon.bind(this, false)}
          onClick={this.handleFavorites.bind(this, this.props.item.CODIGO)}
        >
          <i className={this.props.param.favorites && (this.areFavourited() || this.state.favHover) ? 'fas fa-heart' : 'far fa-heart'} />
        </button> */}
        <div className="w-100 img-wrapper">
          <Image src={this.state.imgSrc} className="product-img" alt="" />
        </div>
        <div className="product-info">
          <p className="product-name"> {this.props.item.NOME} </p>
          {this.props.item.PREPRO > 0 &&
          this.props.item.PREPRO < this.props.item.PRECO &&
          moment(this.props.item.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
          moment(this.props.item.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD') ? (
            <>
              <p className="product-price-alt" style={{fontSize: 12}}>
                {' '}
                R$ {Diversos.number_format(this.props.item.PRECO, 2, ',', '')}{' '}
              </p>
              <p className="product-price"> R$ {Diversos.number_format(this.props.item.PREPRO, 2, ',', '')} </p>
              {this.props.item.PARCELADO == !'N' && (
                <p className="product-price-split">ou 10x de R$ {Diversos.number_format(this.props.item.PREPRO / this.props.item.PARCELADO, 2, ',', '')}</p>
              )}
            </>
          ) : (
            <>
              <p className="product-price"> R$ {Diversos.number_format(this.props.item.PRECO, 2, ',', '')} </p>
              {this.props.item.PARCELADO == !'N' && (
                <p className="product-price-split">ou 10x de R$ {Diversos.number_format(this.props.item.PREPRO / this.props.item.PARCELADO, 2, ',', '')}</p>
              )}
            </>
          )}
        </div>
        {!this.props.item.ESTOQUE || this.props.item.ESTOQUE <= 0 ? (
          <p className="sem-estoque"> Produto indisponível </p>
        ) : (
          <>
            <Button className="btn-primary-action btn-add-cart" onClick={this.handleAddCart.bind(this)}>
              {' '}
              Comprar{' '}
            </Button>

            {this.props.item && this.props.item.PROMOCAO && this.props.item.PROMOCAO.length > 0 && this.props.item.PROMOCAO[0] && this.handleSeloPromocao()}
          </>
        )}
      </a>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Product);
