import React from 'react';
import './index.scss';
import { Redirect } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import divaBranco from '../../assets/Images/logo-branco.png'
import loreal from '../../assets/Images/loreal.png'
import wella from '../../assets/Images/wella.png'
import sch from '../../assets/Images/sch.png'
import mac from '../../assets/Images/mac.png'
import payot from '../../assets/Images/payot.png'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import { Col, Row, Modal, Carousel, Breadcrumb, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

class Marcas extends React.Component<Props> {

  api: any = null;
  state: any = null;
  listener: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      redirect: null,
      isLoading: false,
      backToTop: false,
      marcasIsLoading: true,
      marcas: []
    }
  }

  componentDidMount() {
    this.getMarcas();

    if (window.pageYOffset >= 300) {
      this.setState({ backToTop: true })
    }

    this.listener = document.addEventListener("scroll", e => {
      if (document.scrollingElement === null) {
        alert ('scrollingElement é null');
      } else {
      var scrolled = document.scrollingElement.scrollTop;
        if (scrolled >= 300) {
          this.setState({ backToTop: true });
        } else {
          this.setState({ backToTop: false });
        }
      }
    });
  }

  private async getMarcas () {
    const self = this;

    self.setState({marcasIsLoading: true});

    try {
      const {data} = await self.api.get(`/marcas`);

      if ( data.status === false ) {
        throw new Error(`Nenhum banner encontrado: ${data.msg}`);
      } else {
        self.setState({marcas: data.msg});
      }

    } catch (e) {
      if ( process.env.NODE_ENV === 'development' ) {
        console.error(e);
      }

      self.setState({marcas: []});

    } finally {
      self.setState({marcasIsLoading: false});
    }
  }

  render () {

    const smDevice = window.innerWidth <= 576;
    const mdDevice = window.innerWidth <= 992;

    const letters = ['0-9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'W', 'Y', 'Z'];

    let indexFix = 0;

    return (
        <>
        <Header/>

        <span className="back-to-top" style={{display: mdDevice ? 'flex' : 'none', opacity: this.state.backToTop ? '100%' : '0'}} onClick={() => window.scrollTo(0, 40)}>
          <FontAwesomeIcon icon={faArrowUp}/>
          <small> Voltar </small>
        </span>

        <section className="brands">
          <div className="brands-content-wrapper">
          <Row className="mx-0 brands-breadcrumb">
            <Breadcrumb as={"div"}>
              <Breadcrumb.Item href="/"> <FontAwesomeIcon icon={faArrowDown}/> Home </Breadcrumb.Item>
              <Breadcrumb.Item href="#"> Marcas </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row className="mx-0">
            <Col lg={2} className="px-0 brands-select-col">
              <Form.Control as="select" custom className="custom-select">
                <option> Todas as categorias </option>
                <option> Cabelos </option>
                <option> Maquiagens </option>
                <option> Unhas </option>
                <option> Facial </option>
                <option> Corporal </option>
              </Form.Control>
            </Col>
            <Col xs={12} lg={10} className="brands-filter-col">
              <ol className="brands-filter-letters">
                {
                  letters.map((row, index) => (
                    <li key={index}> <a href={`#${row}`}> {row} </a> </li>
                  ))
                }
              </ol>
            </Col>
          </Row>

          {
            letters.map((row, index) => (
              <section className="brands-container">
                <span id={row} className="anchor-offset-fix"/>
                <h3 className={(window.location.href.indexOf(row) != -1) ? 'selected-brand' : ''}> { row } </h3>
                {
                  this.state.marcasIsLoading ?
                    <Row className="mx-0 brand-letter-container">
                      <Col className={'col-12 text-center py-3'}>
                        <i className={'fas fa-spin fa-spinner fa-2x'}></i>
                      </Col>
                    </Row>
                    :
                    this.state.marcas.length <= 0 ?
                      <Row className="mx-0 brand-letter-container">
                        <Col className={'col-12 text-center py-3'}>
                          <div className={'alert alert-warning'}>
                            <i className={'fas fa-exclamation-circle mr-2'}></i>
                            Nenhuma marca localizada.
                          </div>
                        </Col>
                      </Row>
                      :
                      <Row className="mx-0 brand-letter-container">
                        {
                          this.state.marcas.map((row2, index2) => {
                            if (row2.marca[0].toLowerCase() === row.toLowerCase()) {
                              return (
                                <a href={`/marca/${row2.marca}`} className="marca-wrapper">
                                  <span className="brand-wrapper">
                                    <img
                                      src={`${process.env.REACT_APP_BASE_URL_PICTURE}/marcas/${row2.logo}`}
                                      alt={`Carrosel Marca ${row2.marca}`}
                                    />
                                  </span>
                                </a>
                              );
                            }
                          })
                        }
                      </Row>
                }
              </section>
            ))
          }
          </div>
        </section>

        <Footer/>
        </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Marcas);
