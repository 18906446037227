import React from 'react';
import './index.scss';

import Header from '../../components/Header';
import Footer from '../../components/Footer';

import privacidade from '../../assets/Images/privacidade.png';

class PoliticaDePrivacidade extends React.Component {
  render() {
    return (
      <>
        <Header />

        <section className="politica-de-privacidade">
          <h1> Política de privacidade </h1>
          {/* <img src={privacidade} /> */}

          <article>
            <p>
              A DEPIMIEL não irá, em hipótese alguma, divulgar os seus dados pessoais de endereçamento, pagamento e conteúdo do pedido. Com relação à segurança no tráfego de dados,
              toda transação que envolver pagamento, seja por cartão de crédito ou não, estará encriptada com a tecnologia SSL (Secure Socket Layer). Isso significa que só a
              Depimiel terá acesso a estes dados.
            </p>
          </article>

          {/* <article>
            <h2> Seção 1 - Informações Gerais </h2>
            <p>
              A presente Política de Privacidade contém informações sobre coleta, uso,
              armazenamento, tratamento e proteção dos dados pessoais dos usuários e
              visitantes do site <b>www.divacosmeticos.com</b>, com a finalidade de demonstrar
              absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre
              os tipos de dados que são coletados, os motivos da coleta e a forma como os
              usuários podem gerenciar ou excluir as suas informações pessoais.
            </p>
            <p>
              Esta Política de Privacidade aplica-se a todos os usuários e visitantes do site
              <b> www.divacosmeticos.com </b> e integra os Termos e Condições Gerais de Uso do site,
              devidamente inscrita no CNPJ sob o nº ___, situado em Rua XV de Novembro, 177 –
              Centro – Curitiba/PR – CEP: 80020-310, doravante nominada Diva Cosméticos.
            </p>
            <p>
              O presente documento foi elaborado em conformidade com a Lei Geral de Proteção
              de Dados Pessoais (Lei <a href="https://presrepublica.jusbrasil.com.br/legislacao/612902269/lei-13709-18" target="_blank" rel="noopener noreferrer">13.709</a>/18), o <a href="https://presrepublica.jusbrasil.com.br/legislacao/117197216/lei-12965-14" target="_blank" rel="noopener noreferrer"> Marco Civil da Internet </a> (Lei <a href="https://presrepublica.jusbrasil.com.br/legislacao/117197216/lei-12965-14" target="_blank" rel="noopener noreferrer">12.965</a>/14) (e o
              Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em
              decorrência de eventual atualização normativa, razão pela qual se convida o
              usuário a consultar periodicamente esta seção.
            </p>
          </article>

          <article>
            <h2> SEÇÃO 2 - COMO RECOLHEMOS OS DADOS PESSOAIS DO USUÁRIO E DO VISITANTE? </h2>
            <p> Os dados pessoais do usuário e visitante são recolhidos pela plataforma da seguinte forma: </p>
            <ul>
              <li>
                Quando o usuário cria uma conta/perfil na plataforma
                <b>www.divacosmeticos.com</b>: esses dados são os dados de identificação básicos,
                como e-mail, nome completo e cidade de residência. A partir deles, podemos
                identificar o usuário e o visitante, além de garantir uma maior segurança e
                bem-estar às suas necessidades. Ficam cientes os usuários e visitantes de que
                seu perfil na plataforma estará acessível a todos demais usuários e visitantes
                da plataforma.
              </li>
              <li>
                Quando um usuário e visitante acessa OU páginas do site
                www.divacosmeticos.com: as informações sobre interação e acesso são
                coletadas pela empresa para garantir uma melhor experiência ao usuário e
                visitante. Estes dados podem tratar sobre as palavras-chaves utilizadas em
                uma busca, o compartilhamento de um documento específico, comentários,
                visualizações de páginas, perfis, a URL de onde o usuário e visitante provêm, o
                navegador que utilizam e seus IPs de acesso, dentre outras que poderão ser
                armazenadas e retidas.
              </li>
              <li>
                Por intermédio de terceiro: a plataforma www.divacosmeticos.com recebe
                dados de terceiros, como Facebook e Google, quando um usuário faz login com
                o seu perfil de um desses sites. A utilização desses dados é autorizada
                previamente pelos usuários junto ao terceiro em questão.
              </li>
            </ul>
          </article>

          <article>
            <h2> SEÇÃO 3 - QUAIS DADOS PESSOAIS RECOLHEMOS SOBRE O USUÁRIO E VISITANTE? </h2>
            <p> Os dados pessoais do usuário e visitante recolhidos são os seguintes: </p>
            <ul>
              <li>
                Dados para a criação da conta/perfil na plataforma www.divacosmeticos.com:
                e-mail, nome completo, CPF, endereço completo e telefone.
              </li>
              <li>
                Dados para otimização da navegação: acesso a páginas, palavras-chave
                utilizadas na busca, recomendações, comentários, interação com outros perfis
                e usuários, perfis seguidos, endereço de IP.
              </li>
              <li>
                Dados para concretizar transações: dados referentes ao pagamento e
                transações, tais como, número do cartão de crédito e outras informações
                sobre o cartão, além dos pagamentos efetuados.
              </li>
              <li>
                Newsletter: o e-mail cadastrado pelo visitante que optar por se inscrever na
                Newsletter será coletado e armazenado até que o usuário solicite o
                descadastro.
              </li>
              <li>
                Dados sensíveis: a plataforma poderá coletar os seguintes dados sensíveis do
                usuário: origem étnica ou racial, opinião política, convicção religiosa, dados
                genéticos, dados relativos à saúde, orientação sexual.
              </li>
              <li>
                Dados relacionados a contratos: diante da formalização do contrato de
                compra e venda ou de prestação de serviços entre a plataforma e o usuário e
                visitante poderão ser coletados e armazenados dados relativos a execução
              </li>
            </ul>
          </article>

          <article>
            <h2> SEÇÃO 3 - PARA QUE FINALIDADES UTILIZAMOS OS DADOS PESSOAIS DO USUÁRIO E VISITANTE? </h2>
            <p> Os dados pessoais do usuário e do visitante coletados e armazenados pela plataforma <b>www.divacosmeticos.com</b> tem por finalidade: </p>
            <ul>
              <li>
                Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido,
                facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a
                empresa, melhorar a experiência dos usuários e fornecer funcionalidades
                específicas a depender das características básicas do usuário.
              </li>
              <li>
                Melhorias da plataforma: compreender como o usuário utiliza os serviços da
                plataforma, para ajudar no desenvolvimento de negócios e técnicas.
              </li>
              <li>
                Anúncios: apresentar anúncios personalizados para o usuário com base nos
                dados fornecidos.
              </li>
              <li>
                Comercial: os dados são usados para personalizar o conteúdo oferecido e
                gerar subsídio à plataforma para a melhora da qualidade no funcionamento
                dos serviços.
              </li>
              <li>
                Previsão do perfil do usuário: tratamento automatizados de dados pessoais
                para avaliar o uso na plataforma
              </li>
              <li>
                Dados de cadastro: para permitir o acesso do usuário a determinados
                conteúdos da plataforma, exclusivo para usuários cadastrados
              </li>
              <li>
                Dados de contrato: conferir às partes segurança jurídica e facilitar a conclusão
                do negócio.
              </li>
            </ul>
            <p>
              O tratamento de dados pessoais para finalidades não previstas nesta Política de
              Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo
              que os direitos e obrigações aqui previstos permanecem aplicáveis.
            </p>
          </article>

          <article>
            <h2> SEÇÃO 4 - POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS? </h2>
            <p> 
              Os dados pessoais do usuário e visitante são armazenados pela plataforma durante
              o período necessário para a prestação do serviço ou o cumprimento das
              finalidades previstas no presente documento, conforme o disposto no inciso <a href="https://www.jusbrasil.com.br/topicos/200399061/inciso-i-do-artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018" target="_blank" rel="noopener noreferrer">I</a> do
              artigo <a href="https://www.jusbrasil.com.br/topicos/200399064/artigo-15-da-lei-n-13709-de-14-de-agosto-de-2018" target="_blank" rel="noopener noreferrer">15</a> da Lei <a href="https://presrepublica.jusbrasil.com.br/legislacao/612902269/lei-13709-18" target="_blank" rel="noopener noreferrer">13.709</a>/18.
            </p>
            <p>
              Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando
              os casos em que a lei oferecer outro tratamento.
            </p>
            <p>
              Ainda, os dados pessoais dos usuários apenas podem ser conservados após o
              término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da
              referida lei:
            </p>
            <ol>
              <li>
                Cumprimento de obrigação legal ou regulatória pelo controlador;
              </li>
              <li>
                Estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização
                dos dados pessoais;
              </li>
              <li>
                Transferência a terceiro, desde que respeitados os requisitos de tratamento de
                dados dispostos nesta Lei;
              </li>
              <li>
                Uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que
                anonimizados os dados.
              </li>
            </ol>
          </article>

          <article>
            <h2> SEÇÃO 5 - SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS </h2>
            <p>
              A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a
              proteger os dados pessoais de acessos não autorizados e de situações de
              destruição, perda, alteração, comunicação ou difusão de tais dados
            </p>
            <p>
              Os dados relativos a cartões de crédito são criptografados usando a tecnologia
              "secure socket layer" (SSL) que garante a transmissão de dados de forma segura e
              confidencial, de modo que a transmissão dos dados entre o servidor e o usuário
              ocorre de maneira cifrada e encriptada.
            </p>
            <p>
              A plataforma não se exime de responsabilidade por culpa exclusiva de terceiros,
              como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário,
              como no caso em que ele mesmo transfere seus dados a terceiros. O site se
              compromete a comunicar o usuário em caso de alguma violação de segurança dos
              seus dados pessoais.
            </p>
            <p>
              Os dados pessoais armazenados são tratados com confidencialidade, dentro dos
              limites legais. No entanto, podemos divulgar suas informações pessoais caso
              sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
            </p>
          </article>

          <article>
            <h2> SEÇÃO 6 - COMPARTILHAMENTO DOS DADOS </h2>
            <p>
              O compartilhamento de dados do usuário ocorre apenas com os dados referentes a
              publicações realizadas pelo próprio usuário, tais ações são compartilhadas
              publicamente com os outros usuários.
            </p>
            <p>
              Os dados do perfil do usuário são compartilhados publicamente em sistemas de
              busca e dentro da plataforma, sendo permitido ao usuário modificar tal
              configuração para que seu perfil não apareça nos resultados de busca de tais
              ferramentas.
            </p>
          </article>

          <article>
            <h2> SEÇÃO 6 - OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS? </h2>
            <p>
              Os terceiros indicados recebem os dados na medida do necessário para permitir
              que eles realizem os serviços contratados.
            </p>
            <p>
              Com relação aos fornecedores de serviços terceirizados como processadores de
              transação de pagamento, informamos que cada qual tem sua própria política de
              privacidade. Desse modo, recomendamos a leitura das suas políticas de
              privacidade para compreensão de quais informações pessoais serão usadas por
              esses fornecedores.
            </p>
            <p>
              Os fornecedores podem ser localizados ou possuir instalações localizadas em
              países diferentes. Nessas condições, os dados pessoais transferidos podem se
              sujeitar às leis de jurisdições nas quais o fornecedor de serviço ou suas instalações
              estão localizados.
            </p>
            <p>
              Ao acessar nossos serviços e prover suas informações, você está consentindo o
              processamento, transferência e armazenamento desta informação em outros
              países.
            </p>
            <p>
              Ao ser redirecionado para um aplicativo ou site de terceiros, você não será mais
              regido por essa Política de Privacidade ou pelos Termos de Serviço da nossa
              plataforma. Não somos responsáveis pelas práticas de privacidade de outros sites e
              lhe incentivamos a ler as declarações de privacidade deles.
            </p>
          </article>
          
          <article>
            <h2> SEÇÃO 07 – COOKIES OU DADOS DE NAVEGAÇÃO </h2> 
            <p>
              Os cookies referem-se a arquivos de texto enviados pela plataforma ao
              computador do usuário e visitante e que nele ficam armazenados, com informações
              relacionadas à navegação no site. Tais informações são relacionadas aos dados de
              acesso como local e horário de acesso e são armazenadas pelo navegador do
              usuário e visitante para que o servidor da plataforma possa lê-las posteriormente a
              fim de personalizar os serviços da plataforma.
            </p>
            <p>
              O usuário e o visitante da plataforma manifesta conhecer e aceitar que pode ser
              utilizado um sistema de coleta de dados de navegação mediante à utilização de
              cookies.
            </p>
            <p>
              O cookie persistente permanece no disco rígido do usuário e visitante depois que o
              navegador é fechado e será usado pelo navegador em visitas subsequentes ao site.
              Os cookies persistentes podem ser removidos seguindo as instruções do seu
              navegador. Já o cookie de sessão é temporário e desaparece depois que o
              navegador é fechado. É possível redefinir seu navegador da web para recusar
              todos os cookies, porém alguns recursos da plataforma podem não funcionar
              corretamente se a capacidade de aceitar cookies estiver desabilitada.
            </p>
          </article>
          
          <article>
            <h2> SEÇÃO 8 - CONSENTIMENTO </h2>
            <p>
              Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário
              está consentindo com a presente Política de Privacidade.
            </p>
            <p>
              O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de
              cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a
              veracidade das informações por ele disponibilizadas.
            </p>
            <p>
              O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto
              deve entrar em contato através do e-mail sac@divacosmeticos.com <span> ou por correio
              enviado ao seguinte endereço: Rua XV de Novembro, 177 – Centro – Curitiba/PR –
              CEP: 80020-310 </span>
            </p>
          </article>

          <article>
            <h2> SEÇÃO 9 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE </h2>
            <p>
              Reservamos o direito de modificar essa Política de Privacidade a qualquer
              momento, então, é recomendável que o usuário e visitante revise-a com frequência.
            </p>
            <p>
              As alterações e esclarecimentos vão surtir efeito imediatamente após sua
              publicação na plataforma. Quando realizadas alterações os usuários serão
              notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais
              modificações, o usuário e visitante demonstra sua concordância com as novas
              normas.
            </p>
            <p>
              Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários
              podem ser transferidos para os novos proprietários para que a permanência dos
              serviços oferecidos.
            </p>
          </article>

          <article>
            <h2> SEÇÃO 10 – JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS </h2>
            <p>
              Para a solução de controvérsias decorrentes do presente instrumento será
              aplicado integralmente o Direito brasileiro.
            </p>
            <p>
              Os eventuais litígios deverão ser apresentados no foro da comarca em que se
              encontra a sede da empresa.
            </p>
          </article> */}
        </section>

        <Footer />
      </>
    );
  }
}

export default PoliticaDePrivacidade;
