import React, {Component} from 'react';
import './index.scss';

import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {Cart, ProductType} from '../../store/ducks/cart/types';
import * as CartActions from '../../store/ducks/cart/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import {Helmet} from 'react-helmet';
import Api from '../../services/api';
import {User} from '../../store/ducks/user/types';
import {Diversos} from '../../services/diversos';
import Lottie from 'react-lottie';
import Loading from '../../assets/animations/loading.json';
import Slider from 'react-slick';
import TagManager from 'react-gtm-module';
import swal from 'sweetalert';

import moment from 'moment';
import 'moment/locale/pt-br';

// import de Componentes
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import ProductPromo from '../../components/ProductPromo';
import SemEstoque from '../../components/SemEstoque';
import ProductRatings from '../../components/ProductRatings';
import ComboProduct from '../../components/ComboProduct';
import CantLoadProducts from '../../components/CantLoadProducts';
import Selo from '../../components/Selo';
// import Combo from '../../components/SeloCombo';
// import Combo3 from '../../components/SeloCombo3';
// import Combo4 from '../../components/SeloCombo4';
// import Combo5 from '../../components/SeloCombo5';

import logo from '../../assets/Images/logo.png';
import produto from '../../assets/Images/product.png';
import productPage from '../../assets/Images/productPage.png';
import productTransparent from '../../assets/Images/product-transparent.png';
import produtoHover from '../../assets/Images/produto-hover.jpeg';
import Combo1 from '../../assets/Images/combo-1.png';
import Combo2 from '../../assets/Images/combo-2.png';
import ProductBanner from '../../assets/Images/product-blog-banner.png';

// import de Componentes do Bootstrap
import {Col, Row, Modal, Carousel, Card, Breadcrumb, Accordion, Form, FormControl, InputGroup} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp, faArrowDown, faArrowRight, faExclamation, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

interface StateProps {
  cart?: Cart;
  param: Param;
  user: User;
}

interface DispatchProps {
  add(cart: ProductType): void;
  update(cart: ProductType): void;
  drop(rowid: string): void;
  setParam(data: Param): void;
}

// interface OwnProps {
//   imageOver: any,
//   imageNormal: any,
// }

type Props = StateProps & DispatchProps;

class Produto extends Component<Props> {
  api: any = null;
  state: any = null;
  static: any;

  constructor(props) {
    super(props);
    this.api = new Api();
    const {nome} = props.match.params;
    this.state = {
      paramNome: nome,
      isLoading: true,
      produto: false,
      quantity: 1,
      menu1: null,
      menu2: null,
      menu3: null,
      estoqueModal: null,
      activeIndex: 0,

      infoOpen: true,
      instructions: false,
      composition: false,

      email: '',

      freteCep: null,
      freteModal: null,
      freteHasError: null,
      freteHasErrorMsg: null,
      freteData: null,
      freteIsLoading: false,

      similarIsLoading: true,
      similarData: null,
      similarHasError: false,
      similarHasErrorMsg: null,
    };
  }

  async componentDidMount() {
    const self = this;

    if (!self.state.paramNome) {
      window.location.href = '/error/404';
      return;
    }

    self.setState({isLoading: true});

    try {
      const {data} = await self.api.get(`/product/${self.state.paramNome}/filter/name`);

      if (!data.status) {
        throw new Error('Produto não localizado');
      } else {
        self.setState(
          {
            produto: data.msg,
          },
          () => {
            let price = self.state.produto.PRECO;

            if (
              moment(self.state.produto.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
              moment(self.state.produto.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD')
            ) {
              if (self.state.produto.PREPRO > 0 && self.state.produto.PREPRO < self.state.produto.PRECO) {
                price = self.state.produto.PREPRO;
              }
            }

            // TagManager.dataLayer({
            //   gtmId: 'GTM-P7FFP62',
            //   dataLayer: {
            //     productId: self.state.produto.CODIGO,
            //     productName: self.state.produto.NOME,
            //     productBrand: self.state.produto.MARCA,
            //     productStock: self.state.produto.ESTOQUE,
            //     productPrice: price,
            //     productPicture: `${process.env.REACT_APP_BASE_URL_PICTURE}/${self.state.produto.FOTOS[0].link}`,
            //     page: 'Produto',
            //   },
            //   dataLayerName: 'PageDataLayer',
            // });
          },
        );

        if (data.msg.COMPLEMENTO && data.msg.COMPLEMENTO.MENU1) {
          let paramMenu1 = {menu1: data.msg.COMPLEMENTO.MENU1};
          const response = await self.api.post(`/menu/nivel1-search`, paramMenu1);

          if (response.data.status === true) {
            self.setState({menu1: response.data.msg});
            self.getSimilares();
          }
        }

        if (data.msg.COMPLEMENTO && data.msg.COMPLEMENTO.MENU2) {
          let paramMenu2 = {
            menu1: data.msg.COMPLEMENTO.MENU1,
            menu2: data.msg.COMPLEMENTO.MENU2,
          };
          const response2 = await self.api.post(`/menu/nivel2-search`, paramMenu2);

          if (response2.data.status === true) self.setState({menu2: response2.data.msg});
        }

        if (data.msg.COMPLEMENTO && data.msg.COMPLEMENTO.MENU3) {
          let paramMenu3 = {
            menu1: data.msg.COMPLEMENTO.MENU1,
            menu2: data.msg.COMPLEMENTO.MENU2,
            menu3: data.msg.COMPLEMENTO.MENU3,
          };
          const response3 = await self.api.post(`/menu/nivel3-search`, paramMenu3);

          if (response3.data.status === true) self.setState({menu3: response3.data.msg});
        }
      }
    } catch (e) {
      console.error(e);
      window.location.href = '/error/404';
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async handleAddCart(event) {
    const self = this;
    event.preventDefault();

    let qtd = this.state.quantity;

    if (qtd <= 0) {
      return window.alert('Selecione ao menos 1 item para concluir a compra');
    } else {
      let preco = self.state.produto.PRECO;
      let precoOriginal = self.state.produto.PRECO;

      if (
        self.state.produto.PREPRO > 0 &&
        self.state.produto.PREPRO < preco &&
        moment(self.state.produto.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
        moment(self.state.produto.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD')
      ) {
        preco = self.state.produto.PREPRO;
      }

      let qtdJaAdd = 1;

      if (self.props.cart && self.props.cart.produtos) {
        for (let i = 0; i < self.props.cart.produtos.length; i++) {
          if (self.props.cart.produtos[i].codigo === this.state.produto.CODIGO) {
            if (self.props.cart.produtos[i].price === preco) {
              qtdJaAdd += Number(self.props.cart.produtos[i].qty);
            }
          }
        }
      }

      if (qtdJaAdd > this.state.produto.ESTOQUE) {
        swal('Atenção', `Quantidade máxima em estoque ${this.state.produto.ESTOQUE} unidade(s)`, 'error');
        return false;
      }

      self.props.add({
        codigo: self.state.produto.CODIGO,
        nome: self.state.produto.NOME,
        priceOrigin: precoOriginal,
        price: preco,
        qty: qtd,
        estoque: this.state.produto.ESTOQUE,
        foto:
          !this.state.produto.FOTOS || !this.state.produto.FOTOS[0]
            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
            : `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.produto.FOTOS[0].link}`,
        peso: this.state.produto.PESO,
        promo: null,
        qtdPromo: 1,
      });

      self.props.setParam({...self.props.param, cartOpened: true});

      // TagManager.dataLayer({
      //   gtmId: 'GTM-P7FFP62',
      //   dataLayer: {
      //     productId: self.state.produto.CODIGO,
      //     productName: self.state.produto.NOME,
      //     productBrand: self.state.produto.MARCA,
      //     productStock: self.state.produto.ESTOQUE,
      //     productPrice: preco,
      //     productPicture: `${process.env.REACT_APP_BASE_URL_PICTURE}/${self.state.produto.FOTOS[0].link}`,
      //     page: window.location.pathname,
      //   },
      //   dataLayerName: 'AddToCart',
      // });
    }
  }

  private async handleSimularFrete() {
    const self = this;
    self.setState({
      freteModal: true,
      freteIsLoading: true,
    });
    self.getShippingModes();
  }

  private async getShippingModes() {
    const self = this;

    self.setState({freteIsLoading: true});

    try {
      let param = {cep: self.state.freteCep};
      const {data} = await self.api.post(`/shipping/modes`, param);

      if (data.status === false) {
        throw new Error('Não foi possível buscar opções de entrega.');
      } else {
        self.setState({freteData: data.msg});
      }
    } catch (e) {
      console.error(e);

      self.setState({
        freteData: null,
        fretehasError: true,
        freteHasErrorMsg: e.message,
      });
    } finally {
      self.setState({freteIsLoading: false});
    }
  }

  private async getSimilares() {
    const self = this;

    self.setState({similarIsLoading: true});

    try {
      let param = {menu1: self.state.menu1.CDMENU};
      const {data} = await self.api.post(`/product/departamento`, param);

      if (data.status === false) {
        throw new Error('Não foi possível buscar produtos similares.');
      } else {
        self.setState({similarData: data.msg.data});
      }
    } catch (e) {
      console.error(e);

      self.setState({
        similarData: null,
        similarhasError: true,
        similarHasErrorMsg: e.message,
      });
    } finally {
      self.setState({similarIsLoading: false});
    }
  }

  private _henderSEOTags() {
    if (this.state.isLoading) {
      return <></>;
    } else {
      const title = `${Diversos.capitalize(this.state.produto.NOME)} - ${process.env.REACT_APP_TITLE}`;
      const url = `${process.env.REACT_APP_URL}/produto/${Diversos.toSeoUrl(this.state.produto.NOME)}/${this.state.produto.CODIGO}`;
      const description = `Oferta de ${Diversos.capitalize(this.state.produto.NOME)} para compra online - ${process.env.REACT_APP_TITLE}`;
      const detalhes = this.state.produto.COMPLEMENTO && this.state.produto.COMPLEMENTO.DESCRICAO1 ? this.state.produto.COMPLEMENTO.DESCRICAO1 : description;
      const image =
        this.state.produto.FOTOS && this.state.produto.FOTOS[0]
          ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${this.state.produto.FOTOS[0].link}`
          : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-foto.png`;

      return (
        <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={url} />
          <meta name="url" content={url} />
          <meta name="robots" content="index" />
          <meta name="description" content={description} />
          <meta name="autor" content="TecWorks" />
          <meta name="company" content="TecWorks" />
          <meta name="revisit-after" content="2" />

          {/* LD+JSON  */}
          <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org/",
                "@type": "Product",
                "url": "${url}",
                "name": "${this.state.produto.NOME}",
                "image": "${image}",
                "description": "${detalhes}",
                "sku": "${this.state.produto.CODIGO}",
                "mpn": "${this.state.produto.CODIGO}",
                "brand": {
                    "@type": "Thing",
                    "name": "${this.state.produto.MARCA}"
                },
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "BRL",
                    "price": "${this.state.produto.PRECO}",
                    "priceValidUntil": "${moment().add(1, 'day').format()}",
                    "itemCondition": "http://schema.org/UsedCondition",
                    "availability": "${this.state.produto.ESTOQUE > 0 ? 'http://schema.org/InStock' : 'http://schema.org/OutOfStock'}",
                    "seller": {
                        "@type": "Organization",
                        "name": "${process.env.REACT_APP_TITLE}"
                    }
                }
              }
            `}
          </script>

          {/* TWITTER CARDS */}
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={url} />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={detalhes} />
          <meta name="twitter:image" content={image} />

          {/* FACEBOOK CARDS */}
          <meta property="fb:app_id" content="719113475453514" />
          <meta property="og:site_name" content={title} />
          <meta property="og:locale" content="pt_BR" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={image} />
          <meta property="og:description" content={detalhes} />
          <meta property="og:product:price:amount" content={this.state.produto.PRECO} />
          <meta property="og:product:price:currency" content="BRL" />
          <meta property="og:product:brand" content={this.state.produto.MARCA} />
          <meta property="og:product:condition" content="new" />
          <meta property="og:product:availability" content={this.state.produto.ESTOQUE > 0 ? 'in stock' : 'out stock'} />
        </Helmet>
      );
    }
  }

  goToIndex(newIndex: any) {
    return this.setState({activeIndex: newIndex});
  }

  private async handleFavorites(code, event) {
    const self = this;
    event.preventDefault();

    if (!self.props.user || !(self.props.user.status === true)) {
      self.setState({redirect: '/login'});
    }

    let achou = false;

    for (var i = 0; i < self.props.param.favorites.length && !achou; i++) {
      if (code == self.props.param.favorites[i]) {
        self.props.param.favorites.splice(i, 1);
        achou = true;
      }
    }

    if (!achou) {
      self.props.param.favorites.push(code);
    }

    self.props.setParam({
      ...self.props.param,
      favorites: self.props.param.favorites,
    });
  }

  areFavourited() {
    const self = this;

    return self.props.param && self.props.param.favorites && self.props.param.favorites.includes(self.state.produto.CODIGO);
  }

  private async favIcon(hovered) {
    this.setState({
      favHover: hovered,
    });
  }

  private async handleNotifyMe(event) {
    const self = this;

    event.preventDefault();

    if (!this.state.email) {
      self.setState(
        {
          notifyHasError: true,
          notifyHasErrorMsg: 'O campo "Email" não pode ficar vazio',
        },
        () => {
          setTimeout(() => self.setState({notifyHasError: false}), 5000);
        },
      );

      return;
    }

    if (!Diversos.validateEmail(self.state.email)) {
      self.setState(
        {
          notifyHasError: true,
          notifyHasErrorMsg: 'Por favor, digite um e-mail válido.',
        },
        () => {
          setTimeout(() => self.setState({notifyHasError: false}), 5000);
        },
      );

      return;
    }

    self.setState({notifyIsLoading: true});

    let param = {
      emailParam: self.state.email,
      produtoParam: self.state.produto.CODIGO,
    };

    try {
      const {data} = await self.api.post(`/product/out-of-stock`, param);
      console.log(data);
      if (!data.status) {
        throw new Error('Não foi possível concluir sua solicitação.');
      } else {
        self.setState(
          {
            notifyHasSuccess: true,
            notifyHasSuccessMsg: 'Você receberá uma notificação quando este produto chegar!',
          },
          () => {
            setTimeout(() => self.setState({notifyHasSuccess: false}), 5000);
          },
        );
      }
    } catch (e) {
      console.error(e);

      self.setState(
        {
          notifyhasError: true,
          notifyHasErrorMsg: e.message,
        },
        () => {
          setTimeout(() => self.setState({notifyHasError: false}), 5000);
        },
      );
    } finally {
      self.setState({notifyIsLoading: false});
    }
  }

  render() {
    const FOTOS = [productPage, produto];

    const productBreakpoints = [
      {
        breakpoint: 1676,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
        },
      },
    ];

    const smDevice = window.innerWidth <= 576;

    return (
      <>
        {this._henderSEOTags()}
        <Header />

        {!this.state.isLoading ? (
          <>
            <section className="product-section">
              <Row className="pt-5 product-info-container">
                <Col lg={6} className="image-tag">
                  {/* <button
                    className="product-fav"
                    style={{right: '1rem'}}
                    title="Favoritar item"
                    onMouseOver={this.favIcon.bind(this, true)}
                    onMouseOut={this.favIcon.bind(this, false)}
                    onClick={this.handleFavorites.bind(this, this.state.produto.CODIGO)}
                  >
                    <i className={this.areFavourited() || this.state.favHover ? 'fas fa-heart' : 'far fa-heart'} />
                  </button> */}
                  <Carousel className="product-carousel" interval={null} controls={false} indicators={false} activeIndex={this.state.activeIndex} defaultActiveIndex={0}>
                    {this.state.produto.FOTOS && this.state.produto.FOTOS.length > 0
                      ? this.state.produto.FOTOS.map((row, index) => (
                          <Carousel.Item key={index}>
                            <img
                              className="product-pic"
                              src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                              alt={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`}
                            />
                          </Carousel.Item>
                        ))
                      : null}
                  </Carousel>
                  {this.state.produto.FOTOS && this.state.produto.FOTOS.length > 0 ? (
                    <ol className="product-carousel-indicators">
                      {this.state.produto.FOTOS.slice(0, 5).map((row, index) => (
                        <li className={this.state.activeIndex === index ? 'product-carousel-active' : ''} onClick={this.goToIndex.bind(this, index)}>
                          <img className="product-carousel-thumbnail" src={`${process.env.REACT_APP_BASE_URL_PICTURE}/${row.link}`} />
                        </li>
                      ))}
                    </ol>
                  ) : (
                    <></>
                  )}
                </Col>

                <Col lg={6} className="product-tags">
                  <h1 className="name-tag"> {this.state.produto.NOME} </h1>
                  <span>
                    <p className="brand-tag"> Marca: {this.state.produto.MARCA} </p>
                    {this.state.produto.ESTOQUE <= 1 && (
                      <p className="brand-tag" style={{fontWeight: 'bold'}}>
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight: '.5rem'}} />
                        Produto sem estoque
                      </p>
                    )}
                  </span>

                  {this.state.menu1 && (
                    <Row className="breadcrumb-categories">
                      <Breadcrumb as={'div'}>
                        <Breadcrumb.Item href="#"> {this.state.menu1.DESCRICAO} </Breadcrumb.Item>
                        {this.state.menu2 && this.state.menu2.DESCRICAO && <Breadcrumb.Item href="#"> {this.state.menu2.DESCRICAO} </Breadcrumb.Item>}
                        {this.state.menu3 && this.state.menu3.DESCRICAO && <Breadcrumb.Item href="#"> {this.state.menu3.DESCRICAO} </Breadcrumb.Item>}
                      </Breadcrumb>
                    </Row>
                  )}

                  {this.state.produto.ESTOQUE <= 1 && (
                    <Form method="post" action="#" onSubmit={this.handleNotifyMe.bind(this)} className="out-of-stock">
                      <Form.Label> Avise-me quando este produto chegar! </Form.Label>
                      <InputGroup>
                        <FormControl
                          type="email"
                          placeholder="Digite seu Email"
                          aria-label="Notify my email when this product arrives"
                          onChange={(event) => this.setState({email: event.target.value})}
                        />
                        <InputGroup.Append>
                          <button type="submit"> Pronto! </button>
                        </InputGroup.Append>
                      </InputGroup>
                      {this.state.notifyHasError ? (
                        <Form.Text className="notify-error"> {this.state.notifyHasErrorMsg} </Form.Text>
                      ) : this.state.notifyHasSuccess ? (
                        <Form.Text className="notify-success"> {this.state.notifyHasSuccessMsg} </Form.Text>
                      ) : null}
                    </Form>
                  )}

                  {this.state.produto.PROMOCAO &&
                    this.state.produto.PROMOCAO.length > 0 &&
                    this.state.produto.PROMOCAO.map((row, index) => (
                      <ProductPromo
                        imageNormal={
                          row.produto1.FOTOS && row.produto1.FOTOS.length > 0
                            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.produto1.FOTOS[0].link}`
                            : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        imageOver={
                          row.produto1.FOTOS && row.produto1.FOTOS.length > 0
                            ? row.produto1.FOTOS.length > 1
                              ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.produto1.FOTOS[1].link}`
                              : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.produto1.FOTOS[0].link}`
                            : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        item={this.state.produto}
                      />
                    ))}

                  <div className="price-tag-container">
                    {this.state.produto.PREPRO > 0 &&
                    this.state.produto.PREPRO < this.state.produto.PRECO &&
                    moment(this.state.produto.INIPRO).format('YYYYMMDD') <= moment().format('YYYYMMDD') &&
                    moment(this.state.produto.FIMPRO).format('YYYYMMDD') >= moment().format('YYYYMMDD') ? (
                      <>
                        <h4>
                          {' '}
                          De: <span> R$ {Diversos.number_format(this.state.produto.PRECO, 2, ',', '')} </span>{' '}
                        </h4>
                        <h3> {Diversos.number_format(this.state.produto.PREPRO, 2, ',', '')} </h3>
                        {this.state.produto.PARCELADO == !'N' && (
                          <p>
                            {' '}
                            Ou {this.state.produto.PARCELADO} de R$ {Diversos.number_format(this.state.produto.PREPRO / this.state.produto.PARCELADO, 2, ',', '')}{' '}
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <h3> {Diversos.number_format(this.state.produto.PRECO, 2, ',', '')} </h3>
                        {this.state.produto.PARCELADO == !'N' && (
                          <p>
                            {' '}
                            Ou {this.state.produto.PARCELADO} de R$ {Diversos.number_format(this.state.produto.PREPRO / this.state.produto.PARCELADO, 2, ',', '')}{' '}
                          </p>
                        )}
                      </>
                    )}

                    <button className="btn-primary-action btn-buy" onClick={this.handleAddCart.bind(this)} disabled={this.state.produto.ESTOQUE <= 1}>
                      {' '}
                      Comprar{' '}
                    </button>
                  </div>
                </Col>
              </Row>
            </section>

            <section className="additional-info">
              <Row className="mx-0 info-n-similars">
                <Col lg={12} className="product-details-col">
                  <Accordion defaultActiveKey="0" className="product-details-accordion">
                    {this.state.produto.COMPLEMENTO && this.state.produto.COMPLEMENTO.DESCRICAO1 && (
                      <Card>
                        <Accordion.Toggle as={Card.Header} eventKey="0" className="product-details" onClick={() => this.setState({infoOpen: !this.state.infoOpen})}>
                          Informações
                          <FontAwesomeIcon icon={this.state.infoOpen ? faArrowUp : faArrowDown} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {' '}
                            <h2 style={{whiteSpace: 'pre-line', fontSize: 14, fontWeight: 400, color: 'black'}}> {this.state.produto.COMPLEMENTO.DESCRICAO1} </h2>{' '}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {this.state.produto.COMPLEMENTO && this.state.produto.COMPLEMENTO.DESCRICAO2 && (
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="1"
                          className="product-details"
                          onClick={() =>
                            this.setState({
                              instructions: !this.state.instructions,
                            })
                          }
                        >
                          Como usar
                          <FontAwesomeIcon icon={this.state.instructions ? faArrowUp : faArrowDown} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            <p>
                              <b> Conselho de Aplicação </b>
                            </p>
                            <p style={{whiteSpace: 'pre-line'}}> {this.state.produto.COMPLEMENTO.DESCRICAO2} </p>{' '}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                    {this.state.produto.COMPLEMENTO && this.state.produto.COMPLEMENTO.DESCRICAO3 && (
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="2"
                          className="product-details"
                          onClick={() =>
                            this.setState({
                              composition: !this.state.composition,
                            })
                          }
                        >
                          Composição
                          <FontAwesomeIcon icon={this.state.composition ? faArrowUp : faArrowDown} />
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <Card.Body>
                            {' '}
                            <p style={{whiteSpace: 'pre-line'}}> {this.state.produto.COMPLEMENTO.DESCRICAO3} </p>{' '}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    )}
                  </Accordion>
                </Col>
              </Row>
            </section>

            <section className="vitrine">
              <h2
                style={{
                  fontSize: '1.5rem',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  color: '#6950a2',
                }}
              >
                {' '}
                Clientes também compraram{' '}
              </h2>
              {!this.state.similarData ? (
                <CantLoadProducts />
              ) : this.state.similarIsLoading ? (
                <Row>
                  <Col xs={12} className="text-center p-0">
                    <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} className="my-5 carousel-products">
                    <Slider dots={false} arrows={true} autoplay={false} infinite={true} speed={500} slidesToShow={5} slidesToScroll={1} rows={1} responsive={productBreakpoints}>
                      {this.state.similarData.map(
                        (row, index) =>
                          row.ESTOQUE &&
                          row.ESTOQUE > 1 && (
                            <Product
                              imageNormal={
                                row.FOTOS && row.FOTOS.length > 0
                                  ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                              }
                              imageOver={
                                row.FOTOS && row.FOTOS.length > 0
                                  ? row.FOTOS.length > 1
                                    ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                                    : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                              }
                              item={row}
                            />
                          ),
                      )}
                    </Slider>
                  </Col>
                </Row>
              )}
            </section>
          </>
        ) : (
          <>
            <section className="product-section">
              <div className="row">
                <div className="col-12 text-center">
                  <i className="fas fa-spin fa-spinner"></i>
                </div>
              </div>
            </section>
          </>
        )}

        <Footer />

        <Modal show={this.state.freteModal} onHide={() => this.setState({freteModal: false})} dialogClassName="" className="px-0" size={'sm'} centered={true}>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={logo} style={{width: 60, height: 60, marginRight: 15}} alt="Logo Depimiel" />
              Preço e prazo de entrega
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.freteIsLoading ? (
              <>
                <p className="text-center">
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: Loading,
                    }}
                    height={300}
                    width={300}
                    isStopped={false}
                    isPaused={false}
                  />
                  <br />
                  Calculando frete, por favor aguarde...
                </p>
              </>
            ) : this.state.freteHasError || this.state.freteData === null ? (
              <>
                <div className="alert alert-danger">{this.state.freteHasErrorMsg}</div>
              </>
            ) : (
              <>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Serviço</th>
                      <th>Prazo</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.freteData.map((row, index) => (
                      <tr key={index}>
                        <td>{row.nome}</td>
                        <td>{row.prazo}</td>
                        <td>R$ {Diversos.number_format(row.preco, 2, ',', '')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.estoqueModal}
          onHide={() => this.setState({estoqueModal: false})}
          dialogClassName="sem-estoque-modal"
          className="p-0 sem-estoque-dialog"
          // size={'sm'}
          // centered={true}
        >
          <SemEstoque productName={this.state.produto.NOME} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  cart: state.cart.data,
  param: state.param.data,
  user: state.user.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...CartActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Produto);
