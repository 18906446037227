import React from 'react';
import './index.scss';

import Api from '../../services/api';
import {connect} from 'react-redux';
import {Param} from '../../store/ducks/param/types';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import * as ParamActions from '../../store/ducks/param/actions';

import logoBranca from '../../assets/Images/logo-branco.png';
import seguranca from '../../assets/Images/security.png';
import google from '../../assets/Images/google.png';
import Geo from '../../assets/Images/geo.png';
import rapidSSL from '../../assets/Images/rapidSSL.png';
import BandeirasAlt from '../../assets/Images/bandeiras.svg';
import tecworks from '../../assets/Images/tecworks-branca.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInstagramSquare, faFacebookSquare} from '@fortawesome/free-brands-svg-icons';

import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import {Diversos} from '../../services/diversos';

interface StateProps {
  param: Param;
}

interface DispatchProps {
  setParam(data: Param): void;
}

type Props = StateProps & DispatchProps;

class Footer extends React.Component<Props> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      isLoading: false,
      hasError: false,
      hasErrorTitle: null,
      hasErrorMsg: null,
      hasSuccess: false,
      hasSuccessTitle: null,
      hasSuccessMsg: null,
      nome: null,
      email: null,
    };
  }

  private async handleNewsletterSubmit() {
    const self = this;

    if (!self.state.email || !self.state.nome) {
      self.setMsg('error', 'Formulário incompleto', `Necessário preencher todos os campos para continuar.`);
      return;
    }

    if (!Diversos.validateEmail(self.state.email)) {
      self.setMsg('error', 'E-mail Inválido', 'Por favor, digite um E-mail válido para continuar');
      return;
    }

    let param = {
      email: self.state.email,
      nome: self.state.nome,
    };

    self.setState({isLoading: true, hasError: false, hasSuccess: false});

    try {
      const {data} = await self.api.post('/customer/newsletter', param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setState({hasSuccess: true, hasSuccessTitle: 'Sucesso', hasSuccessMsg: 'Inscrição feita com sucesso!'});
      }
    } catch (e) {
      console.error(e);
      if (e.message === 'E-mail já cadastrado para receber newsletter') self.setMsg('success', 'Atenção', `${e.message}`);
      else self.setMsg('error', 'Atenção', `${e.message}`);
    } finally {
      self.setState({isLoading: false});
    }
  }

  private async setMsg(type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if (type === 'error') {
      self.setState(
        {
          hasError: true,
          hasErrorTitle: title,
          hasErrorMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasError: false}), timeout);
        },
      );
    } else {
      self.setState(
        {
          hasSuccess: true,
          hasSuccessTitle: title,
          hasSuccessMsg: msg,
        },
        () => {
          setTimeout(() => self.setState({hasSuccess: false}), timeout);
        },
      );
    }
  }

  render() {
    return (
      <>
        {this.state.hasError && (
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.hasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.hasErrorMsg} </p>
          </div>
        )}

        {this.state.hasSuccess && (
          <div className="alert-box success-box">
            <p className="login-success-title"> {` - ${this.state.hasSuccessTitle} - `} </p>
            <p className="alert-msg"> {this.state.hasSuccessMsg} </p>
          </div>
        )}

        <footer>
          <Row className="mx-0 footer-links container-fluid">
            <article className="col-12 py-3" />

            <article className="social col-12 text-center d-block d-md-none">
              <div>
                <img src={logoBranca} alt="Logo Depimiel" />
              </div>
            </article>

            <article className="institucional col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left">
              <h3> Institucional </h3>
              <ul>
                <li>
                  {' '}
                  <a href="/institucional/sobre"> Sobre a Depimiel </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/blog"> Blog </a>{' '}
                </li>
              </ul>
            </article>

            <article className="institucional col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left seguranca">
              <h3> Central do Cliente </h3>
              <ul>
                <li>
                  {' '}
                  <a href="/meu-cadastro#meu-perfil"> Meu perfil </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/meu-cadastro#entrega"> Endereço de entrega </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/meu-cadastro#reset-senha"> Trocar senha </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/meus-pedidos"> Meus pedidos </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/institucional/atendimento"> Fale conosco </a>{' '}
                </li>
              </ul>
            </article>

            <article className="social col-12 col-md-4 text-center d-none d-md-block">
              <div>
                <img src={logoBranca} alt="Logo Depimiel" />
              </div>
            </article>

            <article className="institucional col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left">
              <h3> Ajuda e Suporte </h3>
              <ul>
                <li>
                  {' '}
                  <a href="/institucional/atendimento"> Atendimento </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/central-de-relacionamento"> Dúvidas Frequentes </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/central-de-relacionamento" onClick={() => this.props.setParam({...this.props.param, faqTab: 'trocas'})}>
                    {' '}
                    Trocas & Devoluções{' '}
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/central-de-relacionamento" onClick={() => this.props.setParam({...this.props.param, faqTab: 'entregas'})}>
                    {' '}
                    Frete e Prazo de Entrega{' '}
                  </a>{' '}
                </li>
                <li>
                  {' '}
                  <a href="/politica-de-privacidade"> Políticas do Site </a>{' '}
                </li>
              </ul>
            </article>

            <article className="social col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left bandeiras">
              <h3> Redes Sociais </h3>
              <span>
                <a className="social-icon" href="https://www.facebook.com/depimiel" target="_blank" rel="noopener noreferrer" title="Siga a Depimiel no Facebook!">
                  {' '}
                  <FontAwesomeIcon icon={faFacebookSquare} />{' '}
                </a>
                <a href="https://www.instagram.com/depimielbrasil/?hl=pt-br" target="_blank" rel="noopener noreferrer" title="Siga a Depimiel no Instagram!">
                  {' '}
                  <FontAwesomeIcon icon={faInstagramSquare} />{' '}
                </a>
              </span>
            </article>

            <hr className="my-5" />

            <article className="newsletter col-12 col-md-4 mt-4 mt-md-4 mt-lg-0 text-left text-md-left">
              <form onSubmit={this.handleNewsletterSubmit.bind(this)}>
                <h3>
                  {' '}
                  Receba Nossas <span> Novidades </span>{' '}
                </h3>
                <input type="email" name={'email'} placeholder="Qual o seu e-mail?" onChange={(event) => this.setState({email: event.target.value})} />
                <input type="text" name={'nome'} placeholder="Qual o seu Nome?" onChange={(event) => this.setState({nome: event.target.value})} />
                <small className={'align-self-start align-self-md-left'}>
                  Ao se cadastrar você concorda em receber comunicação publicitária, com ofertas e novidades, conforme a nossa{' '}
                  <a href="/politica-de-privacidade"> política de privacidade </a>
                </small>
                <Button type={'submit'} className="btn-primary-action btn-block align-self-start align-self-md-left" style={{width: '100%'}}>
                  {' '}
                  Cadastrar{' '}
                </Button>
              </form>
            </article>

            <article className="col-12 col-md-1" />

            <article className="pagamento col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left bandeiras">
              <h3> Pagamento </h3>
              <img src={BandeirasAlt} alt="Bandeiras Aceitas Como Pagamento" />
            </article>

            <article className="seguranca col-6 col-md-2 mt-4 mt-md-4 mt-lg-0 text-left text-md-left">
              <h3> Segurança </h3>
              <span className={'justify-content-center justify-content-md-start'}>
                <img src={seguranca} alt="Certificados de Segurança" />
                <img src={google} alt="Certificados de Segurança" />
              </span>
              <span className={'justify-content-center justify-content-md-start'}>
                <img src={Geo} alt="Certificados de Segurança" />
                <img src={rapidSSL} alt="Certificados de Segurança" />
              </span>
            </article>

            <article className="depimiel-info col-12 col-md-3">
              <div className="depimiel-info mt-4">
                <p>
                  <strong>CICLON COMÉRCIO ATACADISTA DE COSMÉTICOS LTDA</strong> <br />
                  CNPJ: 23.294.828/0001-03 <br />
                  (41) 3666-6400 <br />
                  Maria Lucia Gueno de Andrade, 135 <br />
                  Pq. Industrial Guaraituba – Colombo / PR
                </p>
              </div>
            </article>
          </Row>

          <hr className="my-5" />

          <div className="depimiel-info mt-4">
            <div className="d-flex align-items-center justify-content-center">
              desenvolvido por
              <a href="http://tecworks.com.br/" target="_blank" rel="noopener noreferrer">
                {' '}
                <img src={tecworks} alt="Logo Tecworks" style={{width: 75, marginLeft: 10}} />{' '}
              </a>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
