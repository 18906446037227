import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {ApplicationState} from '../../store';
import {User, UserTypes} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param, ParamTypes} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import { Redirect, Link } from "react-router-dom";
import Api from '../../services/api';
import PasswordStrengthBar from 'react-password-strength-bar';

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Toast from 'react-bootstrap/Toast'

import DivaFlower from '../../assets/Images/divaflower.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye, faArrowLeft, faArrowRight, faExclamationTriangle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
  

interface StateProps { 
  user: User;
  param: Param;
}

interface DispatchProps {
  setParam(data: Param): void;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;  

class PasswordReset extends React.Component<Props> {

  api: any = null;
  state: any = null;

  novoCepRef: any = null;
  novoRuaRef: any = null;
  novoNumeroRef: any = null;
  novoBairroRef: any = null;
  novoCidadeRef: any = null;
  novoUfRef: any = null;
  novoComplementoRef: any = null;

  constructor(props) {
    super(props);

    this.api = new Api();

    this.state = {
      redirect: null,
      pswHidden: true,
      newPswHidden: true,
      confHidden: true,
      toastOpen: false,

      isLoadingCustomer: true,
      customer: null,
      customerHasError: false,
      customerHasErrorTitle: null,
      customerHasErrorMsg: null,
      customerHasSuccess: false,
      customerHasSuccessTitle: null,
      customerHasSuccessMsg: null,
    }

    this.toggleShow = this.toggleShow.bind(this);

  }

  toggleShow (input: number) {
    if (input === 1) {
      this.setState({ pswHidden: !this.state.pswHidden });
      return;
    }

    else if (input === 2) {
      this.setState({ newPswHidden: !this.state.newPswHidden });
      return;
    }

    this.setState({ confHidden: !this.state.confHidden })
  }

  componentDidMount () {
    const self = this;

    if (( self.props.user ) && ( self.props.user.status !== false )) { 
      self.getCustomer();
    }

    self.setState({ toastOpen: true });
  }

  private async getCustomer () {
    const self = this;

   self.setState({isLoadingCustomer: true});

    try {
      const {data} = await self.api.get(`/customer/${self.props.user.codigo}`);

      if ( data.status == false ) {
        throw new Error('Cadastro de cliente não localizado.');
      } else {
        
        if ( parseInt(data.msg.facebookid) > 0 ) {
          self.setState({customerHasError: true, customerHasErrorTitle: "Atenção", customerHasErrorMsg: "Você está usando uma conta vinculada ao Facebook, não é necessário uso de senha."});
        } 
        else if ( parseInt(data.msg.googleid) > 0 ) {
          self.setState({customerHasError: true, customerHasErrorTitle: "Atenção", customerHasErrorMsg: "Você está usando uma conta vinculada ao Google, não é necessário uso de senha."});
        }
        
        self.setState({customer: data.msg});
      }

    } catch (e) {
      console.error(e);

      self.setState({
        customer: null,
        customerHasError: true,
        customerHasErrorTitle: 'Cadastro não localizado',
        customerHasErrorMsg: e.message
      });

    } finally {
      self.setState({isLoadingCustomer: false});
    }
  }

  private async handleSubmit (event) {
    event.preventDefault();
    
    const self = this;
    let link: string = `/customer/${self.props.user.codigo}`;
    let param: any = {};
    
    if (( !self.state.senhaAtual ) || ( !self.state.senhaNova )) {
      self.setMsg( 'error', "Formulário incompleto", "Necessário preencher todos os campos obrigatórios (*) do formulário para continuar.");
      return;
    }

    if ( self.state.senhaNova.length < 6 ) {
      self.setMsg( 'error', "Segurança de Senha", `A senha escolhida é muito curta, necessário conter no mínimo 6 (seis) dígitos`);
      return;
    }

    if ((self.state.senhaNova.search(/[a-z]/) == -1) || (self.state.senhaNova.search(/[A-Z]/) == -1)) {
      self.setMsg( 'error', "Segurança de Senha", `A senha escolhida deve conter letras maiúsculas e minúsculas`);
      return;
    }

    if (self.state.senhaNova.search (/[0-9]/) == -1) {
      self.setMsg( 'error', "Segurança de Senha", `A senha escolhida deve conter ao menos um número`);
      return;
    }

    param.senha = self.state.senhaAtual;
    param.senhaNova = self.state.senhaNova;
    link = `/customer/${self.props.user.codigo}/troca-senha`;

    self.setState({ isLoading: true });

    try {
      const {data} = await self.api.put(link, param);

      if (!data.status) {
        throw new Error(data.msg);
      } else {
        self.setMsg( 'success', "Sucesso", `Cadastro atualizado com sucesso.`);
      }

    } catch (e) {
      console.error(e);
      self.setMsg( 'error', "Atenção", `Não foi possível atualizar cadastro. ${e.message}`);
    } finally {
      self.setState({
        isLoading: false
      }, () => {
        setTimeout(() => window.location.pathname = 'meu-cadastro', 2500)
      });
    }
  }

  /**
   * FUNCAO PARA SETAR ALERT DE ERROR OU SUCESSO COM TEMPORIZADOR
   * @param type 
   * @param title 
   * @param msg 
   */
  private async setMsg ( type: string, title: string, msg: string) {
    const self = this;
    let timeout = 5000;

    if ( type === "error" ) {
      
      self.setState({
        customerHasError: true,
        customerHasErrorTitle: title,
        customerHasErrorMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasError: false }), timeout)
      });

    } else {

      self.setState({
        customerHasSuccess: true,
        customerHasSuccessTitle: title,
        customerHasSuccessMsg: msg,
      }, () => {
        setTimeout(() => self.setState({ customerHasSuccess: false }), timeout)
      });

    }
  }

  toggleToast() {
    this.setState({ toastOpen: !this.state.toastOpen });
  }

  render () {
    return (
      <>
        {

        this.state.isLoadingCustomer === true ?
        <span className="py-3 w-100 m-auto text-center d-flex justify-content-center">
          <FontAwesomeIcon icon={faCircleNotch} spin={true} className="text-center m-auto spinner" style={{fontSize: 24}}/>
        </span>
        :
        <>

          {
          this.state.customerHasError &&
          <div className="alert-box error-box">
            <p className="login-error-title"> {` - ${this.state.customerHasErrorTitle} - `} </p>
            <p className="alert-msg"> {this.state.customerHasErrorMsg} </p>
          </div>
          }

          {
          this.state.customerHasSuccess &&
          <div className="alert-box success-box">
            <p className="login-success-title"> {` - ${this.state.customerHasSuccessTitle} - `} </p>
            <p className="alert-msg"> {this.state.customerHasSuccessMsg} </p>
          </div>
          }
          
          <div className="data-input-wrapper">    
            <Form method="submit" action="#" onSubmit={this.handleSubmit.bind(this)}>
              <Form.Row className="mx-0">
                <Form.Group className="w-100" style={{position: 'relative'}}>
                  <Form.Label> Senha atual * </Form.Label>
                  <FontAwesomeIcon icon={(this.state.pswHidden) ? faEyeSlash : faEye} className="pswd-addon" onClick={this.toggleShow.bind(this, 1)}/>
                  <Form.Control
                  type={this.state.pswHidden ? 'password' : 'text'}
                  placeholder="Senha"
                  value={this.state.senhaAtual || ""}
                  aria-label="Senha"
                  aria-describedby="Mostrar senha"
                  onChange={(event) => this.setState({senhaAtual: event.target.value})} />
                </Form.Group>
              </Form.Row>

              <Form.Row className="mx-0">
                <Form.Group className="w-100" style={{position: 'relative'}}>
                  <Form.Label> Nova senha * </Form.Label>
                  <FontAwesomeIcon icon={(this.state.newPswHidden) ? faEyeSlash : faEye} className="pswd-addon" onClick={this.toggleShow.bind(this, 2)}/>
                  <Form.Control
                  type={this.state.newPswHidden ? 'password' : 'text'}
                  placeholder="Nova senha"
                  value={this.state.senhaNova || ""}
                  aria-label="Senha"
                  aria-describedby="Mostrar senha"
                  onChange={(event) => this.setState({senhaNova: event.target.value})}/>
                </Form.Group>
                <span onClick={this.toggleToast.bind(this)} style={{width: '100%'}}>
                <PasswordStrengthBar
                password={this.state.senhaNova ? this.state.senhaNova : ""}
                minLength={6}
                scoreWords={['fraca', 'média', 'forte', 'muito forte']}
                shortScoreWord="muito fraca"
                // onChangeScore={score => { this.setPasswordStrenght(score) }}
                style={{display: 'block', width: '100%', paddingLeft: '5px', paddingRight: '5px'}}/>
                </span>
              </Form.Row>
              <Form.Row className="pswd-requirements">
                <Form.Text muted
                className={
                  this.state.senhaNova &&
                  this.state.senhaNova.length >= 6 ? 'achieved' : ''
                }>
                  Mínimo 6 dígitos
                </Form.Text>
                <Form.Text muted
                className={
                  this.state.senhaNova &&
                  (!((this.state.senhaNova.search(/[a-z]/) == -1) || (this.state.senhaNova.search(/[A-Z]/) == -1))) ? 'achieved' : ''
                  }>
                    Letras maiúsculas e minúsculas
                  </Form.Text>
                <Form.Text muted
                className={
                  this.state.senhaNova &&
                  (!(this.state.senhaNova.search(/\d/) == -1)) ? 'achieved' : ''
                  }>
                    Números
                </Form.Text>
              </Form.Row>
              <div className="data-actions">
                <a href="/meu-cadastro" className="btn-link-action"> Voltar </a>
                <Button type="submit" className="btn-primary-action btn-register" disabled={this.state.isLoading || (parseInt(this.state.customer.facebookid) > 0) || (parseInt(this.state.customer.googleid) > 0)}>
                  { (this.state.isLoading) ? 'Salvando...' : 'Salvar' }
                </Button>
              </div>
            </Form>
          </div>
        </>
        }
      </>
    );
  }
}


const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PasswordReset);
