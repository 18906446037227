import React, {Component} from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import {Helmet} from 'react-helmet';
import Slider from 'react-slick';
import {ApplicationState} from '../../store';
import {User} from '../../store/ducks/user/types';
import * as UserActions from '../../store/ducks/user/actions';
import {Param} from '../../store/ducks/param/types';
import * as ParamActions from '../../store/ducks/param/actions';
import Api from '../../services/api';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Product from '../../components/Product';
import CantLoadProducts from '../../components/CantLoadProducts';
import loreal from '../../assets/Images/loreal.png';
import payot from '../../assets/Images/payot.png';
import agima from '../../assets/Images/Agima.png';
import alfaparf from '../../assets/Images/Alfaparf.png';
import altamoda from '../../assets/Images/Alta Moda.png';
import amend from '../../assets/Images/Amend.png';
import belliz from '../../assets/Images/Belliz.png';
// import SuperBanner from '../../assets/Images/na-compra-de-tintura-yellow-gratis-ox.png';
import SuperBanner from '../../assets/banner-home.jpg';
import Minibanner1 from '../../assets/Images/home_linha_classica_270.png';
import Minibanner4 from '../../assets/Images/home_linha_men_270.png';
import Minibanner6 from '../../assets/Images/home_linha_sensitive_270.png';
import Minibanner2 from '../../assets/Images/home_sistema_descartable_270.png';
import Minibanner5 from '../../assets/Images/home_sistema_espanol_270.png';
import Minibanner3 from '../../assets/Images/home_complementos_270.png';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard, faBarcode, faBoxOpen, faTruck} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
declare var window: any;

interface StateProps {
  user: User;
  param: Param;
}

interface DispatchProps {
  doLogin(user: User): void;
  setParam(param: Param): void;
}

interface OwnProps {}

class Home extends Component<StateProps & DispatchProps & OwnProps> {
  api: any = null;
  state: any = null;

  constructor(props) {
    super(props);
    this.api = new Api();
    this.state = {
      bannersIsLoading: true,
      banners: [],

      marcasIsLoading: true,
      marcas: [],

      promocoesIsLoading: true,
      promocoesProcuts: [],

      amadosIsLoading: true,
      amadosProcuts: [],

      blogueiraProcuts: [],
      blogueiraIsLoading: true,

      novidadesProcuts: [],
      novidadesIsLoading: true,

      profissionalProcuts: [],
      profissionalIsLoading: true,
    };
  }

  componentDidMount() {
    this.getBanner();
    setTimeout(() => this.getMarcas(), 25);
    setTimeout(() => this.getPromocao(), 50);
    setTimeout(() => this.getCreme(), 100);
    setTimeout(() => this.getNovidades(), 200);
  }

  private _henderSEOTags() {
    return (
      <Helmet>
        <title> {process.env.REACT_APP_TITLE} </title>
        <link rel="canonical" href={process.env.REACT_APP_URL} />
        <meta name="url" content={process.env.REACT_APP_URL} />
        <meta name="robots" content="index" />
        <meta name="description" content={`Promoções exclusivas em nossa loja online - ${process.env.REACT_APP_TITLE}`} />
        <meta
          name="keywords"
          content={`depilação, depilatórios, cera depilação, compre online, compre e receba, compre sem sair de casa, recebe em casa, loja online, ecommerce`}
        />
        <meta name="autor" content="TecWorks" />
        <meta name="company" content="TecWorks" />
        <meta name="revisit-after" content="2" />

        {/* LD+JSON  */}
        <script type="application/ld+json">
          {`
            [{
                "@context": "http://schema.org",
                "@type": "WebSite",
                "name": "${process.env.REACT_APP_TITLE}",
                "alternateName": "Depimiel",
                "url": "${process.env.REACT_APP_URL}",
                "potentialAction": {
                    "@type": "SearchAction",
                    "target": "${process.env.REACT_APP_URL}/busca/{search_term_string}",
                    "query-input": "required name=search_term_string"
                }
            },
            {
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "${process.env.REACT_APP_URL}",
                "logo": "${require(`../../assets/Images/logo.png`)}",
                "contactPoint": [{
                    "@type": "ContactPoint",
                    "telephone": "+55 (41) 0000-0000",
                    "contactType": "sales"
                }]
            },
            {
                "@context": "http://schema.org",
                "@type": "Person",
                "name": "${process.env.REACT_APP_TITLE}",
                "url": "${process.env.REACT_APP_URL}",
                "sameAs": [
                    "https://www.facebook.com/depimiel",
                    "https://www.instagram.com/depimielbrasil"
                ]
            }]
          `}
        </script>

        {/* TWITTER CARDS */}
        <meta name="twitter:card" content="app" />
        <meta name="twitter:site" content={`${process.env.REACT_APP_URL}`} />
        <meta name="twitter:title" content={`${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`} />
        <meta name="twitter:image" content={require(`../../assets/Images/logo.png`)} />

        {/* FACEBOOK CARDS */}
        <meta property="fb:app_id" content="123" />
        <meta property="og:site_name" content={`${process.env.REACT_APP_TITLE}`} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${process.env.REACT_APP_URL}`} />
        <meta property="og:title" content={`${process.env.REACT_APP_TITLE}`} />
        <meta property="og:image" content={require(`../../assets/Images/logo.png`)} />
        <meta property="og:description" content={`Promoções exclusivas para nossa loja virtual - ${process.env.REACT_APP_TITLE}`} />
      </Helmet>
    );
  }

  private async getBanner() {
    const self = this;

    self.setState({bannersIsLoading: true});

    try {
      const {data} = await self.api.get(`/banners?tipo=1`);

      if (data.status === false) {
        throw new Error(`Nenhum banner encontrado: ${data.msg}`);
      } else {
        self.setState({banners: data.msg});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({banners: []});
    } finally {
      self.setState({bannersIsLoading: false});
    }
  }

  private async getMarcas() {
    const self = this;

    self.setState({marcasIsLoading: true});

    try {
      const {data} = await self.api.get(`/marcas`);

      if (data.status === false) {
        throw new Error(`Nenhum banner encontrado: ${data.msg}`);
      } else {
        self.setState({marcas: data.msg});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({marcas: []});
    } finally {
      self.setState({marcasIsLoading: false});
    }
  }

  private async getPromocao() {
    const self = this;

    self.setState({promocoesIsLoading: true});

    let param = {
      page: 1,
      per_page: 50,
      termo: 'cera',
    };

    try {
      const {data} = await self.api.post(`/product/search/1?per_page=50&sort=rand`, param);

      if (data.status === false) {
        throw new Error(`Nenhum produto PROMOCOES: ${data.msg}`);
      } else {
        self.setState({promocoesProcuts: data.msg.data});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({promocoesProcuts: []});
    } finally {
      self.setState({promocoesIsLoading: false});
    }
  }

  private async getCreme() {
    const self = this;

    self.setState({amadosIsLoading: true});

    let param = {
      page: 1,
      per_page: 50,
      termo: 'CREME',
    };

    try {
      const {data} = await self.api.post(`/product/search/1?per_page=50&sort=rand`, param);

      if (data.status === false) {
        throw new Error(`Nenhum produto PROMOCOES: ${data.msg}`);
      } else {
        self.setState({amadosProcuts: data.msg.data});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({amadosProcuts: []});
    } finally {
      self.setState({amadosIsLoading: false});
    }
  }

  private async getNovidades() {
    const self = this;

    self.setState({novidadesIsLoading: true});

    let param = {
      page: 1,
      per_page: 50,
      termo: 'DEO',
    };

    try {
      const {data} = await self.api.post(`/product/search/1?per_page=50&sort=rand`, param);

      if (data.status === false) {
        throw new Error(`Nenhum produto NOVIDADES: ${data.msg}`);
      } else {
        self.setState({novidadesProcuts: data.msg.data});
      }
    } catch (e) {
      if (process.env.NODE_ENV === 'development') {
        console.error(e);
      }

      self.setState({novidadesProcuts: []});
    } finally {
      self.setState({novidadesIsLoading: false});
    }
  }

  render() {
    const smDevice = window.innerWidth <= 576;

    const brandsBreakpoints = [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 3,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 656,
        settings: {
          slidesToShow: 1,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
    ];

    const productBreakpoints = [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
    ];

    const bannersBreakpoints = [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          dots: false,
          infinite: false,
          loop: false,
          adaptiveHeight: true,
          adaptiveWidth: true,
        },
      },
    ];

    const brandsAll: any = [];

    if (!this.state.marcasIsLoading) {
      for (let i = 0; i < this.state.marcas.length; i++) {
        brandsAll.push({
          content: (
            <a href={`/marca/${this.state.marcas[i].marca}`} className="marca-wrapper">
              <img src={`${process.env.REACT_APP_BASE_URL_PICTURE}/marcas/${this.state.marcas[i].logo}`} alt={`Carrosel Marca ${this.state.marcas[i].marca}`} />
            </a>
          ),
        });
      }
    }

    const minibannersAll = [
      {
        content: (
          <article className="banner-container">
            <a href="/departamento/cremes-depilatorios" title="Promoções produtos linha cremes depilatórios">
              {' '}
              <img src={Minibanner1} alt="Promoções produtos linha cremes depilatórios" />{' '}
            </a>
          </article>
        ),
      },
      {
        content: (
          <article className="banner-container">
            <a href="/departamento/roll-on" title="Promoções em toda linha Roll-on">
              {' '}
              <img src={Minibanner2} alt="Promoções em toda linha Roll-on" />{' '}
            </a>
          </article>
        ),
      },
      {
        content: (
          <article className="banner-container">
            <a href="/departamento/complementos-depilatorios" title="Promoções em toda linha complementos depilatórios">
              {' '}
              <img src={Minibanner3} alt="Promoções em toda linha complementos depilatórios" />{' '}
            </a>
          </article>
        ),
      },
      // {
      //   content: (
      //     <article className="banner-container">
      //       <a href="/cera-depil-deo-prls-men-titanium-1000g" title="Promoções de linha Men">
      //         {' '}
      //         <img src={Minibanner4} alt="Promoções de linha Men" />{' '}
      //       </a>
      //     </article>
      //   ),
      // },
      {
        content: (
          <article className="banner-container">
            <a href="/departamento/ceras?marcas=DEPI-SIST-ESPANHOL&pag=1" title="Promoções de linha Profissional Sistema Espanhol">
              {' '}
              <img src={Minibanner5} alt="Promoções de linha Profissional Sistema Espanhol" />{' '}
            </a>
          </article>
        ),
      },
      {
        content: (
          <article className="banner-container">
            <a href="/busca/sensitive" title="Promoções de linha sensitive">
              {' '}
              <img src={Minibanner6} alt="Promoções de linha sensitive" />{' '}
            </a>
          </article>
        ),
      },
    ];

    const mdDevice = window.innerWidth <= 1024;

    return (
      <>
        {this._henderSEOTags()}
        <Header />

        {(this.state.bannersIsLoading || this.state.banners.length > 0) && (
          <section className="banner-slider">
            {this.state.bannersIsLoading ? (
              <span>
                <i className="fas fa-spin fa-spinner fa-3x m-auto"></i>
              </span>
            ) : (
              <Carousel indicators={this.state.bannerIsLoading || smDevice ? false : true} controls={this.state.bannerIsLoading ? false : true}>
                {this.state.banners.map((row, index) => (
                  <Carousel.Item key={index}>
                    {row.link ? (
                      <a href={row.link} target={'_self'} title={row.title}>
                        <img
                          className="d-block w-100"
                          src={
                            (mdDevice || smDevice) && row.path_mobile && row.path_mobile !== './'
                              ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.path_mobile}`
                              : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.path}`
                          }
                          alt={row.titulo || `Banner ${row.codigo}`}
                        />
                      </a>
                    ) : (
                      <img
                        className="d-block w-100"
                        src={
                          (mdDevice || smDevice) && row.path_mobile && row.path_mobile !== './'
                            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.path_mobile}`
                            : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.path}`
                        }
                        alt={row.titulo || `Banner ${row.codigo}`}
                      />
                    )}
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </section>
        )}

        <section className="d-flex d-lg-flex vantagens-container">
          <article className="mx-0 vantagens-wrapper">
            <div className="vantagem-col d-none d-md-flex d-lg-flex">
              <FontAwesomeIcon icon={faCreditCard} />
              <h4> Até 6x no Cartão* </h4>
            </div>
            <div className="vantagem-col d-none d-md-none d-lg-flex">
              <FontAwesomeIcon icon={faBarcode} />
              <h4>
                {' '}
                À vista <br /> no Boleto{' '}
              </h4>
            </div>
            {/* <div className="vantagem-col d-flex d-md-flex d-lg-flex">
              <FontAwesomeIcon icon={faWhatsapp}/>
              <h4> <a href="https://api.whatsapp.com/send?l=pt&phone=+5541987533683&text=Ol%C3%A1.%20Estou%20fazendo%20contato%20atrav%C3%A9s%20do%20site%20divacosmeticos.com" target="_blank" rel="noopener noreferrer" title="Compre pelo WhatsApp (41) 98753-3683"> Compre <br/> pelo WhatsApp </a> </h4>
            </div> */}
            {/* <div className="vantagem-col d-none d-md-flex d-lg-flex">
              <FontAwesomeIcon icon={faBoxOpen}/>
              <h4> Compre <br/> e retire </h4>
            </div> */}
            <div className="vantagem-col d-none d-md-none d-lg-flex">
              <FontAwesomeIcon icon={faTruck} />
              <h4>
                {' '}
                Compra 100% <br /> Segura e Rápida{' '}
              </h4>
            </div>
          </article>
        </section>

        <section className="container-fluid banner-group mb-3">
          <Slider dots={false} arrows={true} autoplay={false} infinite={false} speed={500} slidesToShow={4} slidesToScroll={1} responsive={bannersBreakpoints}>
            {minibannersAll.map((row) => row.content)}
          </Slider>
        </section>

        {/* {brandsAll.length > 0 || this.state.marcasIsLoading ? (
          <section className="marcas mb-3">
            <h2> Marcas </h2>
            <Slider dots={false} arrows={true} autoplay={false} infinite={false} loop={false} speed={500} slidesToShow={5} slidesToScroll={1} responsive={brandsBreakpoints}>
              {this.state.marcasIsLoading ? (
                <div className={'col-12 text-center p-3'}>
                  <i className={'fas fa-spin fa-spinner fa-2x'}></i>
                </div>
              ) : (
                brandsAll.map((row) => row.content)
              )}
            </Slider>
          </section>
        ) : null} */}

        {/* SUPER PROMOÇÕES  */}
        {this.state.promocoesIsLoading === true || this.state.promocoesProcuts.length > 0 ? (
          <section className="vitrine">
            <h2> Super Promoções </h2>
            {this.state.promocoesIsLoading ? (
              <Row>
                <Col xs={12} className="text-center p-0">
                  <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                </Col>
              </Row>
            ) : (
              <Row className={'my-0 py-0'}>
                <Col xs={12} className="my-0 py-0 carousel-products">
                  <Slider
                    dots={false}
                    arrows={true}
                    autoplay={false}
                    infinite={true}
                    speed={500}
                    slidesToShow={this.state.promocoesProcuts.length < 5 ? this.state.promocoesProcuts.length : 5}
                    slidesToScroll={1}
                    responsive={productBreakpoints}
                  >
                    {this.state.promocoesProcuts.map(
                      (row, index) =>
                        row.ESTOQUE &&
                        row.ESTOQUE > 1 && (
                          <Product
                            imageNormal={
                              row.FOTOS && row.FOTOS.length > 0
                                ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                            }
                            imageOver={
                              row.FOTOS && row.FOTOS.length > 0
                                ? row.FOTOS.length > 1
                                  ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                            }
                            item={row}
                          />
                        ),
                    )}
                  </Slider>
                </Col>
              </Row>
            )}
          </section>
        ) : (
          <CantLoadProducts />
        )}

        {/* <section className="large-banner mb-3">
          <a href="/busca/men-titanium?pag=1&sort=preco_asc" className="large-banner" title="Ver">
            <img src={SuperBanner} alt="Banner Promocional" />
          </a>
        </section> */}

        {/* OS MAIS AMADOS DO SITE */}
        {this.state.amadosIsLoading === true || this.state.amadosProcuts.length > 0 ? (
          <section className="vitrine">
            <h2> Cremes depilatórios </h2>
            {this.state.amadosIsLoading ? (
              <Row>
                <Col xs={12} className="text-center p-0">
                  <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} className="my-0 py-0 carousel-products">
                  <Slider dots={false} arrows={true} autoplay={false} infinite={true} speed={500} slidesToShow={5} slidesToScroll={1} responsive={productBreakpoints}>
                    {this.state.amadosProcuts.map((row, index) => (
                      <Product
                        imageNormal={
                          row.FOTOS && row.FOTOS.length > 0
                            ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                            : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        imageOver={
                          row.FOTOS && row.FOTOS.length > 0
                            ? row.FOTOS.length > 1
                              ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                              : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                            : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                        }
                        item={row}
                      />
                    ))}
                  </Slider>
                </Col>
              </Row>
            )}
          </section>
        ) : (
          <CantLoadProducts />
        )}

        {/* NOVIDADES DIVA */}
        {this.state.novidadesIsLoading === true || this.state.novidadesProcuts.length > 0 ? (
          <section className="vitrine">
            <h2> Novidades Depimiel </h2>
            {this.state.novidadesIsLoading ? (
              <Row>
                <Col xs={12} className="text-center p-0">
                  <i className="fas fa-spin fa-spinner fa-3x my-3 mx-auto"></i>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={12} className="my-0 py-0 carousel-products">
                  <Slider dots={false} arrows={true} autoplay={false} infinite={true} speed={500} slidesToShow={5} slidesToScroll={1} responsive={productBreakpoints}>
                    {this.state.novidadesProcuts.map(
                      (row, index) =>
                        row.ESTOQUE &&
                        row.ESTOQUE > 1 && (
                          <Product
                            imageNormal={
                              row.FOTOS && row.FOTOS.length > 0
                                ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                            }
                            imageOver={
                              row.FOTOS && row.FOTOS.length > 0
                                ? row.FOTOS.length > 1
                                  ? `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[1].link}`
                                  : `${process.env.REACT_APP_BASE_URL_PICTURE}/${row.FOTOS[0].link}`
                                : `${process.env.REACT_APP_BASE_URL_PICTURE}/produto-sem-imagem.png`
                            }
                            item={row}
                          />
                        ),
                    )}
                  </Slider>
                </Col>
              </Row>
            )}
          </section>
        ) : (
          <CantLoadProducts />
        )}

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.data,
  param: state.param.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({...UserActions, ...ParamActions}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
